import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { Appointment } from "../../models/Scheduling/Appointment";
import { Patient } from "../../models/Patient/Patient";
import { ObjectKeys } from "../../constants/object-keys";

@Injectable({
  providedIn: "root"
})
export class AppointmentOperationsService {
  constructor(private _http: HttpClient) {}

  //Book appointment
  public book(appointment: Appointment, selectedPatient: Patient[]) {
    return new Promise((resolve, reject) => {
      let body = appointment.toJSON();
      let patientsArray = [];
      selectedPatient.forEach(p => {
        let temp = {};
        temp["patientId"] = p.docvitaId;
        temp["patientName"] = p.getNameWithSalutation();
        if (p.gender) {
          temp["patientGender"] = p.gender;
        }
        if (p.age) {
          temp["patientAge"] = p.age;
        }
        if (p.primaryContactNumber &&  p.primaryContactNumber.countryCode &&  p.primaryContactNumber.number) {
          temp["patientPrimaryContactNumber"] = p.primaryContactNumber.countryCode +  p.primaryContactNumber.number;
        }
        if (p.primaryEmail) {
          temp["patientPrimaryEmail"] = p.primaryEmail;
        }
        patientsArray.push(temp);
      });
      body[ObjectKeys.patientsArray] = patientsArray;
      let val = this._http
        .post(urls.environment["book-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Complete appointment
  public complete(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["complete-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Cancel appointment
  public cancel(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["cancel-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Arrive appointment
  public arrive(appointmentObject: Appointment) {
    return new Promise((resolve, reject) => {
      let body = appointmentObject.toJSON();
      let val = this._http
        .post(urls.environment["arrive-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Reschedule appointment
  public reschedule(oldAppointment: Appointment, newAppointment: Appointment) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["oldAppointmentJSON"] = oldAppointment.toJSON();
      body["newAppointmentJSON"] = newAppointment.toJSON();
      let val = this._http
        .post(urls.environment["reschedule-appointment"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //Add to queue appointment
  public addToQueue(
    organisationId: string,
    organisationName: string,
    doctorId: string,
    doctorName: string,
    patient: Patient,
    date: string,
    locationId: string,
    locationName: string,
    locationGoogleMapsLink?: string,
    purposeOfVisitId?: string,
    purposeOfVisitTitle?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.organisationId] = organisationId;
      body[ObjectKeys.organisationName] = organisationName;
      body[ObjectKeys.doctorId] = doctorId;
      body[ObjectKeys.doctorName] = doctorName;
      body[ObjectKeys.date] = date;
      body[ObjectKeys.patientId] = patient.docvitaId;
      body[ObjectKeys.patientName] = patient.getNameWithSalutation();
      if (patient.age && patient.age > 0) {
        body[ObjectKeys.patientAge] = patient.age;
      }
      if (patient.gender) {
        body[ObjectKeys.patientGender] = patient.gender;
      }
      if (patient.primaryContactNumber) {
        body[ObjectKeys.patientPrimaryContactNumber] =
          patient.primaryContactNumber;
      }
      if (patient.primaryEmail) {
        body[ObjectKeys.patientPrimaryEmail] = patient.primaryEmail;
      }
      body[ObjectKeys.locationId] = locationId;
      body[ObjectKeys.locationName] = locationName;
      if (locationGoogleMapsLink) {
        body[ObjectKeys.locationGoogleMapsLink] = locationGoogleMapsLink;
      }
      if (purposeOfVisitId) {
        body[ObjectKeys.purposeOfVisitId] = purposeOfVisitId;
      }
      if (purposeOfVisitTitle) {
        body[ObjectKeys.purposeOfVisitTitle] = purposeOfVisitTitle;
      }

      let val = this._http
        .post(urls.environment["add-to-queue"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
