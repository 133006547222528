import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";

/**
 * Dose Trend Object - it contains all the information regarding a particular dose.
 */
export class DosageTrend implements BasicModelInterface {
  // bld
  private _timing1: number;
  private _timing2: number;
  private _timing3: number;

  // dose
  private _dosageVal: number;
  private _dosageUnit: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.timing1) {
      json[ObjectKeys.timing1] = this.timing1;
    }
    if (this.timing2) {
      json[ObjectKeys.timing2] = this.timing2;
    }
    if (this.timing3) {
      json[ObjectKeys.timing3] = this.timing3;
    }
    if (this.dosageVal) {
      json[ObjectKeys.dosageVal] = this.dosageVal;
    }
    if (this.dosageUnit) {
      json[ObjectKeys.dosageUnit] = this.dosageUnit;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.timing1]) {
      this.timing1 = json[ObjectKeys.timing1];
    }
    if (json[ObjectKeys.timing2]) {
      this.timing2 = json[ObjectKeys.timing2];
    }
    if (json[ObjectKeys.timing3]) {
      this.timing3 = json[ObjectKeys.timing3];
    }
    if (json[ObjectKeys.dosageUnit]) {
      this.dosageUnit = json[ObjectKeys.dosageUnit];
    }
    if (json[ObjectKeys.dosageVal]) {
      this.dosageVal = json[ObjectKeys.dosageVal];
    }
  }

  copyDoseTrend(to: DosageTrend) {
    if (this.timing1 != undefined) {
      to.timing1 = this.timing1;
    }
    if (this.timing2 != undefined) {
      to.timing2 = this.timing2;
    }
    if (this.timing3 != undefined) {
      to.timing3 = this.timing3;
    }
    if (this.dosageUnit != undefined) {
      to.dosageUnit = this.dosageUnit;
    }
    if (this.dosageVal != undefined) {
      to.dosageVal = this.dosageVal;
    }
  }

  /**
   * Getter timing1
   * @return {number}
   */
  public get timing1(): number {
    return this._timing1;
  }

  /**
   * Getter timing2
   * @return {number}
   */
  public get timing2(): number {
    return this._timing2;
  }

  /**
   * Getter timing3
   * @return {number}
   */
  public get timing3(): number {
    return this._timing3;
  }

  /**
   * Getter dosageVal
   * @return {number}
   */
  public get dosageVal(): number {
    return this._dosageVal;
  }

  /**
   * Getter dosageUnit
   * @return {string}
   */
  public get dosageUnit(): string {
    return this._dosageUnit;
  }

  /**
   * Setter timing1
   * @param {number} value
   */
  public set timing1(value: number) {
    this._timing1 = value;
  }

  /**
   * Setter timing2
   * @param {number} value
   */
  public set timing2(value: number) {
    this._timing2 = value;
  }

  /**
   * Setter timing3
   * @param {number} value
   */
  public set timing3(value: number) {
    this._timing3 = value;
  }

  /**
   * Setter dosageVal
   * @param {number} value
   */
  public set dosageVal(value: number) {
    this._dosageVal = value;
  }

  /**
   * Setter dosageUnit
   * @param {string} value
   */
  public set dosageUnit(value: string) {
    this._dosageUnit = value;
  }
}
