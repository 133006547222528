import { Pipe, PipeTransform } from "@angular/core";
import { Appointment } from "../models/Scheduling/Appointment";

@Pipe({
  name: "filterByDoctorId",
  pure: false
})
export class FilterByDoctorIdPipe implements PipeTransform {
  transform(items: Appointment[], args?: string): any {
    if (!items || !args) {
      return items;
    }
    return items.filter(item => {
      if (item.doctorId && item.doctorId === args) {
        return true;
      }
      return false;
    });
  }
}
