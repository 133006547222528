import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";
import { Prescription } from "./Prescription";

export class PrescriptionTemplate implements BasicModelInterface {
  private _prescriptionTemplateId: string;
  private _prescription: Prescription;
  private _createdOn: number;
  private _title: string;
  private _organisationId: string;
  private _whoHasAccess: string[]; //If null or empty then all members have access

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.prescription) {
      json[ObjectKeys.prescription] = this.prescription.toJSON();
    }
    if (this.prescriptionTemplateId) {
      json[ObjectKeys.prescriptionTemplateId] = this.prescriptionTemplateId;
    }
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.title) {
      json[ObjectKeys.title] = this.title;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.whoHasAccess) {
      json[ObjectKeys.whoHasAccess] = this.whoHasAccess;
    }

    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.prescriptionTemplateId]) {
      this.prescriptionTemplateId = json[ObjectKeys.prescriptionTemplateId];
    }
    if (json[ObjectKeys.title]) {
      this.title = json[ObjectKeys.title];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.whoHasAccess]) {
      this.whoHasAccess = json[ObjectKeys.whoHasAccess];
    }
    if (json[ObjectKeys.prescription]) {
      let p = new Prescription();
      p.initFromJSON(json[ObjectKeys.prescription]);
      this.prescription = p;
    }
  }

  /**
   * Getter prescriptionTemplateId
   * @return {string}
   */
  public get prescriptionTemplateId(): string {
    return this._prescriptionTemplateId;
  }

  /**
   * Getter prescription
   * @return {Prescription}
   */
  public get prescription(): Prescription {
    return this._prescription;
  }

  /**
   * Getter title
   * @return {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Getter whoHasAccess
   * @return {string[]}
   */
  public get whoHasAccess(): string[] {
    return this._whoHasAccess;
  }

  /**
   * Setter prescriptionTemplateId
   * @param {string} value
   */
  public set prescriptionTemplateId(value: string) {
    this._prescriptionTemplateId = value;
  }

  /**
   * Setter prescription
   * @param {Prescription} value
   */
  public set prescription(value: Prescription) {
    this._prescription = value;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Setter whoHasAccess
   * @param {string[]} value
   */
  public set whoHasAccess(value: string[]) {
    this._whoHasAccess = value;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }
}
