import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { Subject, Observable } from "rxjs";
import { MedicationConstants } from "../../models/Medication/Constants/Medication";
import {
  MedicationFrequencyConstants,
  MedicationFrequencyTextConstants
} from "../../models/Medication/Constants/Frequency";
import { Medication } from "../../models/Medication/Medication";
import { Patient } from "../../models/Patient/Patient";
import { VitalMeasurement } from "../../models/Vitals/Vital Measurement";
import { VitalMeasurementConstants } from "../../models/Vitals/Vital Measurement Constants";
import { HealthInformationConstants } from "../../constants/object-keys";

@Injectable({
  providedIn: "root"
})
export class VoiceService {
  private subjectAssistantState = new Subject<VoiceAssistantStates>();
  private subjectMedicationComments = new Subject<VoiceText>(); //This is for medication comment
  private subjectPrescriptionComment = new Subject<VoiceText>();
  private subjectPrescriptionTemplateTitle = new Subject<VoiceText>();
  private subjectDiagnosis = new Subject<VoiceText>();
  private subjectNewQuickNote = new Subject<VoiceText>();

  private subjectMedicationCellText = new Subject<VoiceText>();

  private subjectAppointment = new Subject<{}>();
  private subjectPrescriptionTemplate = new Subject<{}>();
  private subjectMedeication = new Subject<{}>();
  private subjectVital = new Subject<{}>();
  private subjectBloodGroup = new Subject<{}>();
  private subjectAllergy = new Subject<{}>();
  private subjectDiagnosisEntry = new Subject<{}>();
  private subjectPastHistory = new Subject<{}>();
  private subjectNewPrescription = new Subject<{}>();
  private subjectCurrentPatient = new Subject<Patient>();

  constructor(private _http: HttpClient) {}

  public stop() {
    this.subjectAssistantState.next(VoiceAssistantStates.stop);
  }

  // TODO: Change this to a more meaningful name.
  public startPlainText() {
    this.subjectAssistantState.next(VoiceAssistantStates.medicationComment);
  }

  public start() {
    this.subjectAssistantState.next(VoiceAssistantStates.start);
  }

  public startWithState(state: VoiceAssistantStates) {
    this.subjectAssistantState.next(state);
  }

  public observe(): Observable<VoiceAssistantStates> {
    return this.subjectAssistantState.asObservable();
  }

  public setCurrentPatient(patient: Patient) {
    this.subjectCurrentPatient.next(patient);
  }

  public getCurrentPatient(): Observable<Patient> {
    return this.subjectCurrentPatient.asObservable();
  }

  public setPrescriptionTemplateTitle(partialText: string, finaltext: string) {
    this.subjectPrescriptionTemplateTitle.next(
      new VoiceText(partialText, finaltext)
    );
  }

  public clearPrescriptionTemplateTitle() {
    this.subjectPrescriptionTemplateTitle.next();
  }

  public getPrescriptionTemplateTitle(): Observable<VoiceText> {
    return this.subjectPrescriptionTemplateTitle.asObservable();
  }

  public setPrescriptionComment(partialText: string, finaltext: string) {
    this.subjectPrescriptionComment.next(new VoiceText(partialText, finaltext));
  }

  public clearPrescriptionComment() {
    this.subjectPrescriptionComment.next();
  }

  public getPrescriptionComment(): Observable<VoiceText> {
    return this.subjectPrescriptionComment.asObservable();
  }

  // For MedicationCellText
  public getMedicationCellText(): Observable<VoiceText> {
    return this.subjectMedicationCellText.asObservable();
  }

  public setMedicationCellText(partialText: string, finalText: string) {
    this.subjectMedicationCellText.next(new VoiceText(partialText, finalText));
  }

  public setDiagnosis(partialText: string, finaltext: string) {
    this.subjectDiagnosis.next(new VoiceText(partialText, finaltext));
  }

  public clearDiagnosis() {
    this.subjectDiagnosis.next();
  }

  public getDiagnosis(): Observable<VoiceText> {
    return this.subjectDiagnosis.asObservable();
  }

  public setNewQuickNote(partialText: string, finaltext: string) {
    this.subjectNewQuickNote.next(new VoiceText(partialText, finaltext));
  }

  public clearNewQuickNote() {
    this.subjectNewQuickNote.next();
  }

  public getNewQuickNote(): Observable<VoiceText> {
    return this.subjectNewQuickNote.asObservable();
  }

  public setPrescriptionTemplate(json: {}) {
    this.subjectPrescriptionTemplate.next(json);
  }

  public clearPrescriptionTemplate() {
    this.subjectPrescriptionTemplate.next();
  }

  public getPrescriptionTemplate(): Observable<{}> {
    return this.subjectPrescriptionTemplate.asObservable();
  }

  public setMedicationComments(partialText: string, finalText: string) {
    this.subjectMedicationComments.next(new VoiceText(partialText, finalText));
  }

  public clearMedicationComments() {
    this.subjectMedeication.next();
  }

  public getMedicationComments(): Observable<VoiceText> {
    return this.subjectMedicationComments.asObservable();
  }

  public setMedicationJSON(json: {}) {
    if (json) {
      json = this.convertMedicationJSONtoObject(json).toJSON();
    }
    this.subjectMedeication.next(json);
  }

  public clearMedicationJSON() {
    this.subjectMedeication.next();
  }

  public getMedicationJSON(): Observable<any> {
    return this.subjectMedeication.asObservable();
  }

  public clearVitalJSON() {
    this.subjectVital.next();
  }

  public getVitalJSON(): Observable<{}> {
    return this.subjectVital.asObservable();
  }

  public setVitalJSON(json: {}) {
    if (json) {
      json = this.convertVitalJSONtoObject(json)
    }
    this.subjectVital.next(json);
  }

  public setAppointmentJSON(json: {}) {
    this.subjectAppointment.next(json);
  }

  public clearAppointmentJSON() {
    this.subjectAppointment.next();
  }

  public getAppointmentJSON(): Observable<any> {
    return this.subjectAppointment.asObservable();
  }

  public setAllergy(json){
    this.subjectAllergy.next(json)
  }

  public getAllergy():Observable<any>{
    return this.subjectAllergy.asObservable();
  }

  public setDiagnosisEntry(json){
    this.subjectDiagnosisEntry.next(json)
  }

  public getDiagnosisEntry():Observable<any>{
    return this.subjectDiagnosisEntry.asObservable();
  }

  public setPastHistory(json){
    this.subjectPastHistory.next(json)
  }

  public getPastHistory():Observable<any>{
    return this.subjectPastHistory.asObservable();
  }

  public setNewPrescription(json){
    this.subjectNewPrescription.next(json)
  }

  public getNewPrescription():Observable<any>{
    return this.subjectNewPrescription.asObservable();
  }


  public setBloodGroup(str){
    let numberVal = null;
    for(let a of HealthInformationConstants.BLOOD_GROUPS){
      if(a["label"].toLowerCase() == str){
        numberVal = a["value"]
      }
    }
    this.subjectBloodGroup.next(numberVal)
  }

  public getBloodGroup(): Observable<any>{
    return this.subjectBloodGroup.asObservable();
  }

  convertMedicationJSONtoObject(json: {}): Medication {
    let newMedication = new Medication();

    // Medication Name
    let drug_name = <string>json["drug_name"];
    newMedication.composition =
      drug_name && drug_name.length > 0 ? drug_name : "";

    // Medication route
    newMedication.route = json["administration_route"];
    // Medication Type
    let medicine_type = <string>json["medicine_type"];
    newMedication.dosageForm =
      medicine_type && medicine_type.length > 0 ? medicine_type : "";
    if(newMedication.route == null && newMedication.dosageForm != null){
      switch(newMedication.dosageForm){
        case "tablet":
        case "capsule":{
          newMedication.route = "orally";
          break;
        }
        case "ointment":{
          newMedication.route = "topically";
          break;
        }
      }
    }
    // Medication Dosage Val
    let dose = <number>json["dose"];
    newMedication.masterDosageTrend.dosageVal =
      dose && dose > 0 ? dose : undefined;

    // Medication Dosage Unit
    let dose_unit = <string>json["dose_units"];
    newMedication.masterDosageTrend.dosageUnit =
      dose_unit && dose_unit.length > 0 ? dose_unit : "";

    // Medication Duration
    let duration = json["duration"];
    if (duration) {
      let hoursDuration = <number>duration["hours"];
      let daysDuration = <number>duration["days"];
      let weeksDuration = <number>duration["weeks"];
      let monthsDuration = <number>duration["months"];
      let yearsDuration = <number>duration["years"];

      if (hoursDuration && hoursDuration > 0) {
        // Duration was spoken in days
        newMedication.durationValue = hoursDuration;
        newMedication.durationUnit = "hourly";
      }
      if (daysDuration && daysDuration > 0) {
        // Duration was spoken in days
        newMedication.durationValue = daysDuration;
        newMedication.durationUnit = "daily";
      }
      if (weeksDuration && weeksDuration > 0) {
        // Duration was spoken in weeks
        newMedication.durationValue = weeksDuration;
        newMedication.durationUnit = "weekly";
      }
      if (monthsDuration && monthsDuration > 0) {
        // Duration was spoken in months
        newMedication.durationValue = monthsDuration;
        newMedication.durationUnit = "monthly";
      }
      if (yearsDuration && yearsDuration > 0) {
        // Duration was spoken in years
        newMedication.durationValue = yearsDuration;
        newMedication.durationUnit = "yearly";
      }
    }

    // Medication Timing
    let timing = json["timings"];
    let anytime = <boolean>timing["anytime"];
    if (anytime) {
      newMedication.frequencyText = MedicationFrequencyTextConstants.Freely;
    }
    let asrequired = <string>timing["asrequired"];

    if (asrequired && asrequired == "sos") {
      newMedication.frequencyText = MedicationFrequencyTextConstants.SOS;
    }
    if (asrequired && asrequired == "stat") {
      newMedication.frequencyText = MedicationFrequencyTextConstants.STAT;
    }

    let every_hours = <number>timing["every_hours"];
    if (every_hours && every_hours > 0) {
      switch (every_hours) {
        case 1:
          newMedication.frequencyText =
            MedicationFrequencyTextConstants.PerHour;
          break;
        case 4:
          newMedication.frequencyText =
            MedicationFrequencyTextConstants.EveryFourHours;
          break;
        case 6:
          newMedication.frequencyText =
            MedicationFrequencyTextConstants.EverySixHours;
          break;
        case 8:
          newMedication.frequencyText =
            MedicationFrequencyTextConstants.EveryEightHours;
        case 12:
          newMedication.frequencyText =
            MedicationFrequencyTextConstants.EveryTwelveHours;
          break;
        default:
          // Not supported for now
          break;
      }
    }

    let timesperday = <number>timing["times_per_day"];

    if (timesperday && timesperday > 0) {
      if (timesperday == 1) {
        newMedication.frequencyText = MedicationFrequencyTextConstants.OnceADay;
      } else if (timesperday == 2) {
        newMedication.frequencyText =
          MedicationFrequencyTextConstants.TwiceADay;
      } else if (timesperday == 3) {
        newMedication.frequencyText =
          MedicationFrequencyTextConstants.ThriceADay;
      } else if (timesperday == 4) {
        newMedication.frequencyText =
          MedicationFrequencyTextConstants.FourTimesADay;
      }
      newMedication.isAfterMeals = <boolean>timing["after_meals"];
      newMedication.isBeforeMeals = <boolean>timing["before_meals"];
    }
    let wrtMeal = <string[]>json["wrt_meal"];
    console.log("wrtMeal", wrtMeal);
    if (wrtMeal && wrtMeal.length > 0) {
      let timing1 = 0;
      let timing2 = 0;
      let timing3 = 0;
      // let timing4 = 0;

      for (let element of wrtMeal) {
        // if (element == "after dinner") {
        //   timing3 = 1;
        // }
        switch (element) {
          case "after breakfast":
            timing1 = 1;
            break;
          case "before breakfast":
            timing1 = 1;
            break;
          case "with breakfast":
            timing1 = 1;
            break;
          case "after lunch":
            timing2 = 1;
            break;
          case "before lunch":
            timing2 = 1;
            break;
          case "with lunch":
            timing2 = 1;
            break;
          case "after dinner":
            timing3 = 1;
            break;
          case "before dinner":
            timing3 = 1;
            break;
          case "with dinner":
            timing3 = 1;
            break;
        }
      }
      newMedication.setTiming(timing1, timing2, timing3);
    }
    console.log("Converted medication object: ",newMedication);
    return newMedication;
  }

  convertVitalJSONtoObject(json: {}): VitalMeasurement[] {
    let array:VitalMeasurement[] = []
    // newVitalObject.vital_type = keys.VITAL_TYPE_PULSE; ???
    let pulse_val = <number>json["pulse_num"];
    if (pulse_val && pulse_val > 0) {
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = pulse_val;
      newVitalObject.vitalType = VitalMeasurementConstants.VITAL_TYPE_PULSE;
      array.push(newVitalObject)
    }
    let heightVal = <number>json["height_num"];
    let heightUnit = <string>json["height_unit"];

    if (heightVal && heightVal > 0 && heightUnit && heightUnit.length > 0) {
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = heightVal;
      newVitalObject.vitalUnit = heightUnit;
      newVitalObject.vitalType = VitalMeasurementConstants.VITAL_TYPE_HEIGHT;
      newVitalObject.vitalUnit = VitalMeasurementConstants.HEIGHT_UNIT;
      array.push(newVitalObject)
    }
    let weightVal = <number>json["weight_num"];
    let weightUnit = <string>json["weight_unit"];

    if (weightVal && weightVal > 0 && weightUnit && weightUnit.length > 0) {
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = weightVal;
      newVitalObject.vitalUnit = weightUnit;
      newVitalObject.vitalType = VitalMeasurementConstants.VITAL_TYPE_WEIGHT;
      newVitalObject.vitalUnit = VitalMeasurementConstants.WEIGHT_UNIT;
      array.push(newVitalObject)
    }

    let bmiVal = <number>json["bmi_num"];
    if(bmiVal && bmiVal > 0){
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = +bmiVal.toFixed(2);
      newVitalObject.vitalType = VitalMeasurementConstants.VITAL_TYPE_BMI;
      newVitalObject.vitalUnit = VitalMeasurementConstants.BMI_UNIT;
      array.push(newVitalObject)
    }

    let blood_pressure_sys = <number>json["blood_pressure_sys"]
    let blood_pressure_dia = <number>json["blood_pressure_dia"]
    let blood_pressure_type = json["blood_pressure_type"]

    if(blood_pressure_sys && blood_pressure_sys > 0 
      && blood_pressure_dia && blood_pressure_dia > 0
      && blood_pressure_type){
        let newVitalObject = new VitalMeasurement();
        newVitalObject.vitalValue1 = blood_pressure_sys
        newVitalObject.vitalValue2 = blood_pressure_dia
        newVitalObject.vitalType = VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE;
        newVitalObject.vitalUnit = VitalMeasurementConstants.BLOOD_PRESSURE_UNIT;
        newVitalObject.vitalInfo = blood_pressure_type
        array.push(newVitalObject)
    }
    
    if (json["spo2_num"] && json["spo2_unit"]) {
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = <number>json["spo2_num"];
      newVitalObject.vitalType = VitalMeasurementConstants.VITAL_TYPE_SPO2;
      newVitalObject.vitalUnit = VitalMeasurementConstants.SPO2_UNIT;
      array.push(newVitalObject)
    }
    if (json["temperature_num"] && json["temperature_unit"]) {
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = <number>json["temperature_num"];
      newVitalObject.vitalType =
        VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE;
      newVitalObject.vitalUnit = VitalMeasurementConstants.TEMPERATURE_UNIT;
      array.push(newVitalObject)
    }
    if (json["respiratory_rate_num"] && json["respiratory_rate_unit"]) {
      let newVitalObject = new VitalMeasurement();
      newVitalObject.vitalValue1 = <number>json["respiratory_rate_num"];
      newVitalObject.vitalType =
        VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE;
      newVitalObject.vitalUnit =
        VitalMeasurementConstants.RESPERATORY_RATE_UNIT;
      array.push(newVitalObject)
    }
    return array;
  }

  public dialog(text: string) {
    return new Promise((resolve, reject) => {
      let body = { text };
      let val = this._http
        .post(urls.environment["voice-command"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}

export class VoiceText {
  private _partial_text: string;
  private _final_text: string;

  constructor(partialText: string, finalText: string) {
    this.partial_text = partialText;
    this.final_text = finalText;
  }

  /**
   * Getter partial_text
   * @return {string}
   */
  public get partial_text(): string {
    return this._partial_text;
  }

  /**
   * Setter partial_text
   * @param {string} value
   */
  public set partial_text(value: string) {
    this._partial_text = value;
  }

  /**
   * Getter final_text
   * @return {string}
   */
  public get final_text(): string {
    return this._final_text;
  }

  /**
   * Setter final_text
   * @param {string} value
   */
  public set final_text(value: string) {
    this._final_text = value;
  }
}

export enum VoiceAssistantStates {
  "toggle",
  "start",
  "stop",
  "medicationComment",
  "prescriptionComment",
  "prescriptionTemplateTitle",
  "diagnosis",
  "newQuickNote",
  "prescriptionTemplate",
  "medicationCellText"
}
