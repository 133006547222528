import { MedicationDosageFormConstants } from "./Dosage Form";
import { MedicationStrengthUnitConstants } from "./Strength Unit";
import { MedicationDosageUnitConstants } from "./Dosage Unit";
import { MedicationRouteConstants } from "./Route";
import { MedicationFrequencyConstants } from "./Frequency";
import { MedicationDurationConstants } from "./Duration";

export class MedicationConstants {
  public static DOSAGE_FORM_TYPES = MedicationDosageFormConstants.ALL;
  public static STRENGTH_UNITS = MedicationStrengthUnitConstants.ALL;
  public static DOSAGE_UNITS = MedicationDosageUnitConstants.ALL;
  public static ROUTE_TYPES = MedicationRouteConstants.ALL;
  public static FREQUENCY_TEXT_VALUE_OPTIONS_MAP =
    MedicationFrequencyConstants.MASTER_MAP;
  public static DURATION_OPTIONS_MAP = MedicationDurationConstants.MASTER_MAP;
  public static DURATION_UNITS = MedicationDurationConstants.UNITS_SINGULAR;
}
