import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "timestampToDateFilter"
})
export class TimestampToDateFilterPipe extends DatePipe
  implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value && typeof value === "number") {
      let date = new Date(value);
      return super.transform(date, "dd MMM yyyy");
    } else {
      return null;
    }
  }
}
