import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "customTimeslotFilter"
})
export class CustomTimeslotFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      var time = value.split("-");
      var amorpm = parseInt(time[0].trim().split(":")[0]) >= 12 ? "PM" : "AM";
      var startTime =
        parseInt(time[0].trim().split(":")[0]) > 12
          ? parseInt(time[0].trim().split(":")[0]) -
            12 +
            ":" +
            time[0].trim().split(":")[1]
          : time[0];
      return startTime + " " + amorpm;
    }
    return value;
  }
}
