import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectizeModule } from "ng-selectize";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { environment } from "../environments/environment";

//Layout Modules
import { CommonLayoutComponent } from "./common/common-layout.component";

//Directives
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Sidebar_Directives } from "./shared/directives/side-nav.directive";
import { Cards_Directives } from "./shared/directives/cards.directive";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

// Routing Module
import { AppRoutes } from "./app.routing";

// Firebase
import * as firebase from "firebase";

// App Component
import { AppComponent } from "./app.component";
import { ToastyModule } from "ng2-toasty";
import { PatientRegistrationComponent } from "./patient-registration/patient-registration.component";
import { SharedModule } from "./shared/shared.module";
import { NewAppointmentComponent } from "./new-appointment/new-appointment.component";
import { HttpTokenInterceptorService } from "./shared/services/auth/http-token-interceptor.service";
import { DragulaModule } from "ng2-dragula";
import { LottieAnimationViewModule } from "ng-lottie";

declare global {
  interface Window {
    analytics: any;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    NgbModule.forRoot(),
    ToastyModule.forRoot(),
    FormsModule,
    NgSelectizeModule,
    HttpClientModule,
    SharedModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    LottieAnimationViewModule.forRoot()
  ],
  declarations: [
    AppComponent,
    PatientRegistrationComponent,
    CommonLayoutComponent,
    Sidebar_Directives,
    Cards_Directives,
    NewAppointmentComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    this.initializeApp();
  }
  initializeApp(): void {
    firebase.initializeApp(environment.firebaseConfig);
  }
}
