import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "diagnosisType",
  pure: false
})
export class DiagnosisTypePipe implements PipeTransform {
  transform(items: any[], args?: string): any {
    if (!items || !args) {
      return items;
    }

    return items.filter(item => {
      if (item.diagnosisType && item.diagnosisType === args) {
        return true;
      }
      return false;
    });
  }
}
