import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from './shared/services/notify/notify.service';
import * as firebase from 'firebase';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    
  constructor(private router: Router, private notifyService: NotifyService) {
  }
}
