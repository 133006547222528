export class MedicationRouteConstants {
  public static ORALLY = {
    value: "orally",
    label: "Orally"
  };
  public static TOPICALLY = {
    value: "topically",
    label: "Topically"
  };
  public static SUBLINGUALLY = {
    value: "sublingually",
    label: "Sublingually"
  };
  public static BUCCALLY = {
    value: "buccally",
    label: "Buccally"
  };
  public static RECTALLY = {
    value: "rectally",
    label: "Rectally"
  };
  public static VAGINALLY = {
    value: "vaginally",
    label: "Vaginally"
  };
  public static TRANSDERMALLY = {
    value: "transdermally",
    label: "Transdermally"
  };
  public static NEBULIZER = {
    value: "nebulizer",
    label: "Nebulizer"
  };
  public static INHALED = {
    value: "orally inhaled",
    label: "Orally Inhaled"
  };
  public static NOSTRILS = {
    value: "both nostrils",
    label: "Both Nostrils"
  };
  public static IN_LEFT_EYE = {
    value: "in left eye",
    label: "In left eye"
  };
  public static IN_RIGHT_EYE = {
    value: "in right eye",
    label: "In right eye"
  };
  public static IN_BOTH_EYES = {
    value: "in both eyes",
    label: "In both eyes"
  };
  public static IN_LEFT_EAR = {
    value: "in left ear",
    label: "In left ear"
  };
  public static IN_RIGHT_EAR = {
    value: "in right ear",
    label: "In right ear"
  };
  public static IN_BOTH_EARS = {
    value: "in both ears",
    label: "In both ears"
  };
  public static INTRAVENOUSLY = {
    value: "intravenously",
    label: "Intravenously"
  };
  public static INTRAMUSCULARLY = {
    value: "intramuscularly",
    label: "Intramuscularly"
  };
  public static SUBCUTANEOUSLY = {
    value: "subcutaneously",
    label: "Subcutaneously"
  };
  public static INTRADERMALLY = {
    value: "intradermally",
    label: "Intradermally"
  };
  public static INTRATHECALLY = {
    value: "intrathecally",
    label: "Intrathecally"
  };
  public static LOCAL = {
    value: "local",
    label: "Local"
  };
  public static GARGLE = {
    value: "gargle",
    label: "Gargle"
  };

  public static LANGUAGE_LABEL = {
    "orally":{
      "hi":"मुख द्वारा"
    },
    "topically":{
      "hi":"topically"
    },
    "sublingually":{
      "hi":"जीभ के नीचे"
    },
    "buccally":{
      "hi":"buccally"
    },
    "rectally":{
      "hi":"गुदा द्वारा"
    },
    "vaginally":{
      "hi":"योनि द्वारा"
    },
    "transdermally":{
      "hi":"transdermally"
    },
    "nebulizer":{
      "hi":"नेब्यूलिजेर"
    },
    "orally inhaled":{
      "hi":"मुख द्वारा साँस के साथ"
    },
    "both nostrils":{
      "hi":"दोनों नथुने से"
    },
    "in left eye":{
      "hi":"बाईं आंख में"
    },
    "in right eye":{
      "hi":"दाहिनी आंख में"
    },
    "in both eyes":{
      "hi":"दोनों आंख में"
    },
    "in left ear":{
      "hi":"बाएं कान में"
    },
    "in right ear":{
      "hi":"दाहिने कान में"
    },
    "in both ears":{
      "hi":"दोनों कान में"
    },
    "intravenously":{
      "hi":"intravenously"
    },
    "intramuscularly":{
      "hi":"intramuscularly"
    },
    "subcutaneously":{
      "hi":"subcutaneously"
    },
    "intradermally":{
      "hi":"त्वचा के अंदर"
    },   
    "intrathecally":{
      "hi":"intrathecally"
    },
    "local":{
      "hi":"लोकल"
    },
    "gargle":{
      "hi":"कुल्ला"
    }
  }

  public static ALL = [
    MedicationRouteConstants.ORALLY,
    MedicationRouteConstants.TOPICALLY,
    MedicationRouteConstants.SUBLINGUALLY,
    MedicationRouteConstants.BUCCALLY,
    MedicationRouteConstants.RECTALLY,
    MedicationRouteConstants.VAGINALLY,
    MedicationRouteConstants.TRANSDERMALLY,
    MedicationRouteConstants.NEBULIZER,
    MedicationRouteConstants.INHALED,
    MedicationRouteConstants.NOSTRILS,
    MedicationRouteConstants.IN_LEFT_EYE,
    MedicationRouteConstants.IN_RIGHT_EYE,
    MedicationRouteConstants.IN_BOTH_EYES,
    MedicationRouteConstants.IN_LEFT_EAR,
    MedicationRouteConstants.IN_RIGHT_EAR,
    MedicationRouteConstants.IN_BOTH_EARS,
    MedicationRouteConstants.INTRAVENOUSLY,
    MedicationRouteConstants.INTRAMUSCULARLY,
    MedicationRouteConstants.SUBCUTANEOUSLY,
    MedicationRouteConstants.INTRADERMALLY,
    MedicationRouteConstants.INTRATHECALLY,
    MedicationRouteConstants.LOCAL,
    MedicationRouteConstants.GARGLE
  ];
}
