import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Medication } from "../../models/Medication/Medication";

@Component({
  selector: "app-med-search-list",
  templateUrl: "./med-search-list.component.html",
  styleUrls: ["./med-search-list.component.css"]
})
export class MedSearchListComponent implements OnInit {
  @Input() showNameOrComposition: boolean = true; // true - name, false - composition
  @Input() nameSearchResults: Array<Medication> = [];
  @Input() compositionSearchResults: Array<Medication> = [];

  @Output() selectedMedication = new EventEmitter<{
    nameOrComposition: boolean;
    selectedMedication: Medication;
  }>();

  constructor() {}

  ngOnInit() {}

  cellClickAction(medication: Medication) {
    this.selectedMedication.emit({
      nameOrComposition: this.showNameOrComposition,
      selectedMedication: medication
    });
  }
}
