import { OnInit, Component, Input, Output, EventEmitter } from "@angular/core";
import { SelectizeConfigs } from "../shared/constants/object-keys";
import { PersonConstants } from "../shared/models/Person";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import {
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../shared/services/datePicker/NgbDateFRParserFormatter";
import { Patient } from "../shared/models/Patient/Patient";
import { ContactNumber, DateActions } from "../shared/models/Demographics";
import { PatientProfileService } from "../shared/services/patient/patient-profile.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { NotifyService } from "../shared/services/notify/notify.service";
import { PatientRegistrationModalService } from "../shared/services/modalStateManagement/patient-registration-modal.service";

@Component({
  selector: "app-patient-registration",
  templateUrl: "./patient-registration.component.html",
  styleUrls: ["./patient-registration.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]
})
export class PatientRegistrationComponent implements OnInit {
  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  salutationOptions = PersonConstants.SALUTATIONS;
  countryCodeOptions = PersonConstants.COUNTRY_CODES;
  genderOptions = PersonConstants.GENDER;
  languageOptions = PersonConstants.LANGUAGE;

  inEditingMode: boolean = false;

  // Dynamically building the form
  salutationFC = new FormControl("mr");
  patient: Patient = new Patient();
  @Input() initialText: string;
  @Output() onPatientSaveorUpdate = new EventEmitter<Patient>();

  // for editing an existing patient
  @Input() userForEditing: Patient;

  isSavingPatient = false; // to control saving of the whole card

  searchResultsConnectedPatients: Array<Patient> = [];
  searchResultsNotConnectedPatients: Array<Patient> = [];

  constructor(
    private _patientProfileService: PatientProfileService,
    private _patientRegistrationModalService: PatientRegistrationModalService,
    private _notifyService: NotifyService
  ) {}

  ngOnInit(): void {
    var self = this;

    this._patientRegistrationModalService.modalOpenedAgain().subscribe(() => {
      //This will be called everytime this modal is opened
      if (this.userForEditing && this.userForEditing.docvitaId != null) {
        // in Editing Mode
        this.inEditingMode = true;
        this.patient = this.userForEditing;
      } else {
        this.initializePatient();
        this.inEditingMode = false;
        // in New Registration Mode
        // if initial text contains a number, populate user id
        const regexp = new RegExp("[a-z]*[0-9][a-z]*");
        const initialTextContainsNumericValue = regexp.test(this.initialText);
        if (!this.initialText) {
        } else if (initialTextContainsNumericValue) {
          // populate user id
          this.patient.primaryContactNumber.number = this.initialText;
        } else {
          // populate user name
          this.patient.name = this.initialText;
        }
      }
    });
    // this.patientRegistrationForm.valueChanges.subscribe(value => {
    //   // console.log(value);
    // });
  }

  initializePatient() {
    this.patient = new Patient();
    this.patient.gender = "male";
    this.patient.primaryContactNumber.countryCode = "91";
    this.patient.salutation = "mr";
    this.patient.language = "english";
  }

  isValidForSaving(): boolean {
    // Edit this function for checking the patient object validity
    const doesPatientHaveName =
      this.patient.name != null && this.patient.name.length > 0;

    const doesPatientHaveGender =
      this.patient.gender != null && this.patient.gender.length > 0;

    // TODO: Implement this for multiple country codes later on
    let doesPatientHaveValidContact = false;
    if (
      this.patient.primaryContactNumber.number == null ||
      (this.patient.primaryContactNumber.number != null &&
        this.patient.primaryContactNumber.number.length == 10)
    ) {
      doesPatientHaveValidContact = true;
    }

    return (
      doesPatientHaveName &&
      doesPatientHaveGender &&
      doesPatientHaveValidContact
    );
  }

  closeThisModal() {
    this._patientRegistrationModalService.close();
  }

  onSubmit() {
    var self = this;
    self.isSavingPatient = true;
    if (this.patient.docvitaId) {
      //Update
      this._patientProfileService
        .update(this.patient, GlobalVariables.getOrganisationId())
        .then(resp => {
          if (resp) {
            self.isSavingPatient = false;
            if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
              //handle it here
              this.onPatientSaveorUpdate.emit(this.patient);
              this._notifyService.showSuccessMessage("Patient profile saved!");
              this._patientRegistrationModalService.close();
            } else {
              //handle it here
              this._notifyService.showErrorMessage("Please try again!");
            }
          }
        })
        .catch(err => {
          self.isSavingPatient = false;
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    } else {
      this._patientProfileService
        .create(
          this.patient,
          GlobalVariables.getMemberId(),
          GlobalVariables.getMemberName(),
          GlobalVariables.getOrganisationId(),
          GlobalVariables.getOrganisationName()
        )
        .then(resp => {
          self.isSavingPatient = false;
          if (resp) {
            //handle it here
            // this.patient.docvitaId = resp["body"]["user_id"];
            this.onPatientSaveorUpdate.emit(this.patient);
            this._notifyService.showSuccessMessage("Patient profile saved!");
            this._patientRegistrationModalService.close();
          }
        })
        .catch(err => {
          self.isSavingPatient = false;
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    }
  }

  searchPatientsByMobile() {
    if (
      this.patient.primaryContactNumber.number &&
      this.patient.primaryContactNumber.number.length > 5
    ) {
      this._patientProfileService
        .patientTextSearchWithoutConnectionFilter(
          this.patient.primaryContactNumber.number,
          GlobalVariables.getOrganisationId()
        )
        .then(body => {
          console.log("body", body);
          this.searchResultsConnectedPatients = body["connected"];
          this.searchResultsNotConnectedPatients = body["not-connected"];
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.searchResultsConnectedPatients = [];
      this.searchResultsNotConnectedPatients = [];
    }
  }

  searchPatientsByName() {
    if (this.patient.name && this.patient.name.length > 0) {
      this._patientProfileService
        .patientTextSearchWithoutConnectionFilter(
          this.patient.name,
          GlobalVariables.getOrganisationId()
        )
        .then(body => {
          console.log("body", body);
          this.searchResultsConnectedPatients = body["connected"];
          // this.searchResultsNotConnectedPatients = body["not-connected"];
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      this.searchResultsConnectedPatients = [];
      // this.searchResultsNotConnectedPatients = [];
    }
  }

  dummyFunc() {}
}
