import { Injectable } from "@angular/core";
import { Patient } from "../../models/Patient/Patient";
import { Subject, Observable } from "rxjs";

declare var $: any;
/**
 * This service is to open, close and manage state of patient registration modal
 */
@Injectable({
  providedIn: "root"
})
export class PatientRegistrationModalService {
  private modalStateTracker = new Subject<boolean>(); // open - true; close - false;

  private subjectInitialText = new Subject<string>();
  private modalInitPatientProfileEditing = new Subject<any>();
  private subjectPatientProfileEditing = new Subject<Patient>();
  private subjectModalOpenedAgain = new Subject<any>();
  private subjectNewAddedPatient = new Subject<Patient>();

  constructor() {}

  public setNewAddedPatient(user: Patient): void {
    this.subjectNewAddedPatient.next(user);
  }

  public clearNewAddedPatient() {
    this.subjectNewAddedPatient.next();
  }

  public getNewAddedPatient(): Observable<Patient> {
    return this.subjectNewAddedPatient.asObservable();
  }

  public getModalStateTracker(): Observable<boolean> {
    return this.modalStateTracker.asObservable();
  }

  public setInitialTextFornewUser(initialText: string) {
    this.subjectInitialText.next(initialText);
  }

  public selectPatientProfileForEditing(user: Patient) {
    this.subjectPatientProfileEditing.next(user);
  }

  public clearInitialText() {
    this.subjectInitialText.next("");
  }

  /**
   * Open appointment side panel modal
   */
  public openPatientProfileEditing() {
    $("#patient-reg-modal-fs").modal("show");
    this.subjectModalOpenedAgain.next();
    this.modalStateTracker.next(true);
  }

  public close(): void {
    $("#patient-reg-modal-fs").modal("hide");
    this.modalStateTracker.next(false);
  }

  /**
   * Listen for reset patient form
   */
  public resetModalPatientProfileEditingObservable(): Observable<any> {
    return this.modalInitPatientProfileEditing.asObservable();
  }

  public getSelectedInProfileEditing(): Observable<any> {
    return this.subjectPatientProfileEditing.asObservable();
  }

  public getInitialText(): Observable<any> {
    return this.subjectInitialText.asObservable();
  }

  public modalOpenedAgain(): Observable<any> {
    return this.subjectModalOpenedAgain.asObservable();
  }
}
