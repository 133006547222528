import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "customDateFilter"
})
export class CustomDateFilterPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value && typeof value === "object") {
      let date = new Date(value.year, value.month - 1, value.day);
      return super.transform(date, "dd MMM yyyy");
    }
    if (value && typeof value === "number") {
      let date = new Date(value);
      let dateOnlyString = super.transform(date, "dd MMM yyyy");
      let timeOnlyString = super.transform(date, "hh:mm a");
      return dateOnlyString + "\n " + timeOnlyString;
      // return super.transform(date, "dd MMM yyyy,\n hh:mm a");
    } else if (value && typeof value === "string") {
      var day = value.split("-");
      let date = new Date(
        parseInt(day[0]),
        parseInt(day[1]) - 1,
        parseInt(day[2])
      );
      return super.transform(date, "dd MMM yyyy");
    } else {
      return null;
    }
  }
}
