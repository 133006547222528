import { Injectable } from "@angular/core";
import {
  ToastyService,
  ToastyConfig,
  ToastOptions,
  ToastData
} from "ng2-toasty";

@Injectable({
  providedIn: "root"
})
export class NotifyService {
  constructor(
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
  ) {
    this.toastyConfig.theme = "default";
  }
  types = [
    { name: "Default", code: "default" },
    { name: "Info", code: "info" },
    { name: "Success", code: "success" },
    { name: "Wait", code: "wait" },
    { name: "Error", code: "error" },
    { name: "Warning", code: "warning" }
  ];

  defaultToastOptions: ToastOptions = {
    title: "",
    msg: "",
    showClose: true,
    timeout: 2000,
    theme: "default",
    onAdd: (toast: ToastData) => {
      // console.log('Toast ' + toast.id + ' has been added!');
    },
    onRemove: function(toast: ToastData) {
      // console.log('Toast ' + toast.id + ' has been removed!');
    }
  };

  public showSuccessMessage(message: string, title?: string) {
    this.defaultToastOptions.title = title;
    this.defaultToastOptions.msg = message;
    this.toastyService.success(this.defaultToastOptions);
  }

  public showWarningMessage(message: string, title?: string) {
    this.defaultToastOptions.title = title;
    this.defaultToastOptions.msg = message;
    this.toastyService.warning(this.defaultToastOptions);
  }

  public showErrorMessage(message: string, title?: string) {
    this.defaultToastOptions.title = title;
    this.defaultToastOptions.msg = message;
    this.toastyService.error(this.defaultToastOptions);
    // console.log("aaddddaa");
    // this.toastr.error(message, title);
  }
}
