import { BasicModelInterface } from "./BasicModelInterface";
import { ObjectKeys } from "../constants/object-keys";

export class DateActions {
  static getDateString(date: Date): string {
    return (
      date.getFullYear() +
      "-" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2)
    );
  }
  static getDateJSONToString(json: {}): string {
    let date = new Date(+json["year"], +json["month"], +json["day"]);
    return DateActions.getDateString(date);
  }
  static getDateJSONToStringWithMonthDiff(json: {}): string {
    let date = new Date(+json["year"], +json["month"] - 1, +json["day"]);
    return DateActions.getDateString(date);
  }
}

export class OrganisationLocationObject implements BasicModelInterface {
  private _organisationLocationId: string;
  private _logoUrl: string;
  private _title: string;
  private _address: AddressObject;

  toJSON(): {} {
    let json = {};
    if (this.title) {
      json[ObjectKeys.title] = this.title;
    }
    if (this.logoUrl) {
      json[ObjectKeys.logoUrl] = this.logoUrl;
    }
    if (this.organisationLocationId) {
      json[ObjectKeys.organisationLocationId] = this.organisationLocationId;
    }
    if (this.address) {
      json[ObjectKeys.address] = this.address.toJSON();
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.organisationLocationId]) {
      this.organisationLocationId = json[ObjectKeys.organisationLocationId];
    }
    if (json[ObjectKeys.title]) {
      this.title = json[ObjectKeys.title];
    }
    if (json[ObjectKeys.logoUrl]) {
      this.logoUrl = json[ObjectKeys.logoUrl];
    }
    if (json[ObjectKeys.address]) {
      let addressObj = new AddressObject();
      addressObj.initFromJSON(json[ObjectKeys.address]);
      this.address = addressObj;
    }
  }

  /**
   * Getter title
   * @return {string}
   */
  public get title(): string {
    return this._title;
  }

  /**
   * Setter title
   * @param {string} value
   */
  public set title(value: string) {
    this._title = value;
  }

  /**
   * Getter address
   * @return {AddressObject}
   */
  public get address(): AddressObject {
    return this._address;
  }

  /**
   * Setter address
   * @param {AddressObject} value
   */
  public set address(value: AddressObject) {
    this._address = value;
  }

  /**
   * Getter organisationLocationId
   * @return {string}
   */
  public get organisationLocationId(): string {
    return this._organisationLocationId;
  }

  /**
   * Setter organisationLocationId
   * @param {string} value
   */
  public set organisationLocationId(value: string) {
    this._organisationLocationId = value;
  }

  /**
   * Getter logoUrl
   * @return {string}
   */
  public get logoUrl(): string {
    return this._logoUrl;
  }

  /**
   * Setter logoUrl
   * @param {string} value
   */
  public set logoUrl(value: string) {
    this._logoUrl = value;
  }
}

export class AddressObject implements BasicModelInterface {
  private _addressId: string;
  private _line1: string;
  private _line2: string;
  private _locality: string;
  private _city: string;
  private _state: string;
  private _zipCode: string;
  private _country: string;
  private _primaryContactPhone: string;
  private _secondaryContactPhones: string[];
  private _googleMapsLocationUrl: string;

  toJSON(): {} {
    let json = {};
    if (this.addressId) {
      json[ObjectKeys.addressId] = this.addressId;
    }
    if (this.line1) {
      json[ObjectKeys.line1] = this.line1;
    }
    if (this.line2) {
      json[ObjectKeys.line2] = this.line2;
    }
    if (this.locality) {
      json[ObjectKeys.locality] = this.locality;
    }
    if (this.city) {
      json[ObjectKeys.city] = this.city;
    }
    if (this.state) {
      json[ObjectKeys.state] = this.state;
    }
    if (this.zipCode) {
      json[ObjectKeys.zipCode] = this.zipCode;
    }
    if (this.country) {
      json[ObjectKeys.country] = this.country;
    }
    if (this.primaryContactPhone) {
      json[ObjectKeys.primaryContactPhone] = this.primaryContactPhone;
    }
    if (this.secondaryContactPhones) {
      json[ObjectKeys.secondaryContactPhones] = this.secondaryContactPhones;
    }
    if (this.googleMapsLocationUrl) {
      json[ObjectKeys.googleMapsLocationUrl] = this.googleMapsLocationUrl;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.addressId]) {
      this.addressId = json[ObjectKeys.addressId];
    }
    if (json[ObjectKeys.line1]) {
      this.line1 = json[ObjectKeys.line1];
    }
    if (json[ObjectKeys.line2]) {
      this.line2 = json[ObjectKeys.line2];
    }
    if (json[ObjectKeys.locality]) {
      this.locality = json[ObjectKeys.locality];
    }
    if (json[ObjectKeys.city]) {
      this.city = json[ObjectKeys.city];
    }
    if (json[ObjectKeys.state]) {
      this.state = json[ObjectKeys.state];
    }
    if (json[ObjectKeys.zipCode]) {
      this.zipCode = json[ObjectKeys.zipCode];
    }
    if (json[ObjectKeys.country]) {
      this.country = json[ObjectKeys.country];
    }
    if (json[ObjectKeys.primaryContactPhone]) {
      this.primaryContactPhone = json[ObjectKeys.primaryContactPhone];
    }
    if (json[ObjectKeys.secondaryContactPhones]) {
      this.secondaryContactPhones = json[ObjectKeys.secondaryContactPhones];
    }
    if (json[ObjectKeys.googleMapsLocationUrl]) {
      this.googleMapsLocationUrl = json[ObjectKeys.googleMapsLocationUrl];
    }
  }

  /**
   * Getter addressId
   * @return {string}
   */
  public get addressId(): string {
    return this._addressId;
  }

  /**
   * Setter addressId
   * @param {string} value
   */
  public set addressId(value: string) {
    this._addressId = value;
  }

  /**
   * Getter line1
   * @return {string}
   */
  public get line1(): string {
    return this._line1;
  }

  /**
   * Setter line1
   * @param {string} value
   */
  public set line1(value: string) {
    this._line1 = value;
  }

  /**
   * Getter line2
   * @return {string}
   */
  public get line2(): string {
    return this._line2;
  }

  /**
   * Setter line2
   * @param {string} value
   */
  public set line2(value: string) {
    this._line2 = value;
  }

  /**
   * Getter locality
   * @return {string}
   */
  public get locality(): string {
    return this._locality;
  }

  /**
   * Setter locality
   * @param {string} value
   */
  public set locality(value: string) {
    this._locality = value;
  }

  /**
   * Getter city
   * @return {string}
   */
  public get city(): string {
    return this._city;
  }

  /**
   * Setter city
   * @param {string} value
   */
  public set city(value: string) {
    this._city = value;
  }

  /**
   * Getter state
   * @return {string}
   */
  public get state(): string {
    return this._state;
  }

  /**
   * Setter state
   * @param {string} value
   */
  public set state(value: string) {
    this._state = value;
  }

  /**
   * Getter zipCode
   * @return {string}
   */
  public get zipCode(): string {
    return this._zipCode;
  }

  /**
   * Setter zipCode
   * @param {string} value
   */
  public set zipCode(value: string) {
    this._zipCode = value;
  }

  /**
   * Getter country
   * @return {string}
   */
  public get country(): string {
    return this._country;
  }

  /**
   * Setter country
   * @param {string} value
   */
  public set country(value: string) {
    this._country = value;
  }

  /**
   * Getter primaryContactPhone
   * @return {string}
   */
  public get primaryContactPhone(): string {
    return this._primaryContactPhone;
  }

  /**
   * Setter primaryContactPhone
   * @param {string} value
   */
  public set primaryContactPhone(value: string) {
    this._primaryContactPhone = value;
  }

  /**
   * Getter secondaryContactPhones
   * @return {string[]}
   */
  public get secondaryContactPhones(): string[] {
    return this._secondaryContactPhones;
  }

  /**
   * Setter secondaryContactPhones
   * @param {string[]} value
   */
  public set secondaryContactPhones(value: string[]) {
    this._secondaryContactPhones = value;
  }

  /**
   * Getter googleMapsLocationUrl
   * @return {string}
   */
  public get googleMapsLocationUrl(): string {
    return this._googleMapsLocationUrl;
  }

  /**
   * Setter googleMapsLocationUrl
   * @param {string} value
   */
  public set googleMapsLocationUrl(value: string) {
    this._googleMapsLocationUrl = value;
  }
}

export class ContactNumber implements BasicModelInterface {
  private _contactNumberId: string;
  private _type: "mobile" | "contact";
  private _countryCode: string;
  private _isVerified: string;
  private _number: string;
  toJSON(): {} {
    let json = {};
    if (this.number) {
      json[ObjectKeys.numberVal] = this.number;
    }
    if (this.countryCode) {
      json[ObjectKeys.countryCode] = this.countryCode;
    }
    if (this.contactNumberId) {
      json[ObjectKeys.contactNumberId] = this.contactNumberId;
    }
    if (this.type) {
      json[ObjectKeys.type] = this.type;
    }
    if (this.isVerified) {
      json[ObjectKeys.isVerified] = this.isVerified;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.countryCode]) {
      this.countryCode = json[ObjectKeys.countryCode];
    }
    if (json[ObjectKeys.numberVal]) {
      this.number = json[ObjectKeys.numberVal];
    }
    if (json[ObjectKeys.isVerified]) {
      this.isVerified = json[ObjectKeys.isVerified];
    }
    if (json[ObjectKeys.type]) {
      this.type = json[ObjectKeys.type];
    }
    if (json[ObjectKeys.contactNumberId]) {
      this.contactNumberId = json[ObjectKeys.contactNumberId];
    }
  }

  /**
   * Getter type
   * @return { "mobile" | "contact"}
   */
  public get type(): "mobile" | "contact" {
    return this._type;
  }

  /**
   * Setter type
   * @param { "mobile" | "contact"} value
   */
  public set type(value: "mobile" | "contact") {
    this._type = value;
  }

  /**
   * Getter number
   * @return {string}
   */
  public get number(): string {
    return this._number;
  }

  /**
   * Setter number
   * @param {string} value
   */
  public set number(value: string) {
    this._number = value;
  }

  /**
   * Getter isVerified
   * @return {string}
   */
  public get isVerified(): string {
    return this._isVerified;
  }

  /**
   * Setter isVerified
   * @param {string} value
   */
  public set isVerified(value: string) {
    this._isVerified = value;
  }

  /**
   * Getter countryCode
   * @return {string}
   */
  public get countryCode(): string {
    return this._countryCode;
  }

  /**
   * Setter countryCode
   * @param {string} value
   */
  public set countryCode(value: string) {
    this._countryCode = value;
  }

  /**
   * Getter contactNumberId
   * @return {string}
   */
  public get contactNumberId(): string {
    return this._contactNumberId;
  }

  /**
   * Setter contactNumberId
   * @param {string} value
   */
  public set contactNumberId(value: string) {
    this._contactNumberId = value;
  }
}
