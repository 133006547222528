import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";

export class Habit implements BasicModelInterface{
  private _id: string;
  private _createdOn: number;
  private _createdBy: string;
  private _text: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.id) {
      json[ObjectKeys.id] = this.id;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.createdBy) {
      json[ObjectKeys.createdBy] = this.createdBy;
    }
    if (this.text) {
      json[ObjectKeys.text] = this.text;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if(json[ObjectKeys.id]){
      this.id = json[ObjectKeys.id]
    }
    if(json[ObjectKeys.createdOn]){
      this.createdOn = json[ObjectKeys.createdOn]
    }
    if(json[ObjectKeys.createdBy]){
      this.createdBy = json[ObjectKeys.createdBy]
    }
    if(json[ObjectKeys.text]){
      this.text = json[ObjectKeys.text]
    }
  }

  /**
   * Getter id
   * @return {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Getter createdBy
   * @return {string}
   */
  public get createdBy(): string {
    return this._createdBy;
  }

  /**
   * Getter text
   * @return {string}
   */
  public get text(): string {
    return this._text;
  }

  /**
   * Setter allergyId
   * @param {string} value
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }

  /**
   * Setter createdBy
   * @param {string} value
   */
  public set createdBy(value: string) {
    this._createdBy = value;
  }

  /**
   * Setter text
   * @param {string} value
   */
  public set text(value: string) {
    this._text = value;
  }
}
