import { VitalMeasurement } from "./Vital Measurement";
import { VitalMeasurementConstants } from "./Vital Measurement Constants";

export class LatestVitalSnapshot {
  private _height: VitalMeasurement;
  private _weight: VitalMeasurement;
  private _bmi: VitalMeasurement;
  private _spo2: VitalMeasurement;
  private _pulse: VitalMeasurement;
  private _temperature: VitalMeasurement;
  private _respiratoryRate: VitalMeasurement;
  private _pain: VitalMeasurement;
  private _bloodGlucose: VitalMeasurement;

  private _bloodPressure: VitalMeasurement;

  // Call this function at an appropriate time.
  public checkIfWeCanCalculcateBMI() {
    let heightVal = this.height.vitalValue1;
    let weightVal = this.weight.vitalValue1;
    let bmiVal = this.bmi.vitalValue1;

    if (
      heightVal != undefined &&
      weightVal != undefined &&
      bmiVal == undefined
    ) {
      var bmiValue = weightVal * 10000;
      bmiValue = bmiValue / (heightVal * heightVal);
      this.bmi.vitalValue1 = +bmiValue.toFixed(2);
    } else {
      // TODO: Remove this value?
      //   this.bmi.vitalValue1 = undefined;
    }
  }

  setCommonDetails(memberId, patientId, measuredOn) {
    this.height.createdBy = memberId;
    this.height.docvitaPatientId = patientId;
    this.height.createdOn = new Date().getTime();
    if (!this.height.measuredOn) {
      this.height.measuredOn = measuredOn;
    }

    this.weight.createdBy = memberId;
    this.weight.docvitaPatientId = patientId;
    this.weight.createdOn = new Date().getTime();
    if (!this.weight.measuredOn) {
      this.weight.measuredOn = measuredOn;
    }

    this.bmi.createdBy = memberId;
    this.bmi.docvitaPatientId = patientId;
    this.bmi.createdOn = new Date().getTime();
    if (!this.bmi.measuredOn) {
      this.bmi.measuredOn = measuredOn;
    }

    this.spo2.createdBy = memberId;
    this.spo2.docvitaPatientId = patientId;
    this.spo2.createdOn = new Date().getTime();
    if (!this.spo2.measuredOn) {
      this.spo2.measuredOn = measuredOn;
    }

    this.pulse.createdBy = memberId;
    this.pulse.docvitaPatientId = patientId;
    this.pulse.createdOn = new Date().getTime();
    if (!this.pulse.measuredOn) {
      this.pulse.measuredOn = measuredOn;
    }

    this.temperature.createdBy = memberId;
    this.temperature.docvitaPatientId = patientId;
    this.temperature.createdOn = new Date().getTime();
    if (!this.temperature.measuredOn) {
      this.temperature.measuredOn = measuredOn;
    }

    this.respiratoryRate.createdBy = memberId;
    this.respiratoryRate.docvitaPatientId = patientId;
    this.respiratoryRate.createdOn = new Date().getTime();
    if (!this.respiratoryRate.measuredOn) {
      this.respiratoryRate.measuredOn = measuredOn;
    }

    this.pain.createdBy = memberId;
    this.pain.docvitaPatientId = patientId;
    this.pain.createdOn = new Date().getTime();
    if (!this.pain.measuredOn) {
      this.pain.measuredOn = measuredOn;
    }

    this.bloodGlucose.createdBy = memberId;
    this.bloodGlucose.docvitaPatientId = patientId;
    this.bloodGlucose.createdOn = new Date().getTime();
    if (!this.bloodGlucose.measuredOn) {
      this.bloodGlucose.measuredOn = measuredOn;
    }

    this.bloodPressure.createdBy = memberId;
    this.bloodPressure.docvitaPatientId = patientId;
    this.bloodPressure.createdOn = new Date().getTime();
    if (!this.bloodPressure.measuredOn) {
      this.bloodPressure.measuredOn = measuredOn;
    }
  }

  isValidForPush(): boolean {
    let validity = false;
    if (this.height.isValidForPush) {
      validity = true;
    }
    if (this.weight.isValidForPush) {
      validity = true;
    }
    if (this.bmi.isValidForPush) {
      validity = true;
    }
    if (this.spo2.isValidForPush) {
      validity = true;
    }
    if (this.pulse.isValidForPush) {
      validity = true;
    }
    if (this.temperature.isValidForPush) {
      validity = true;
    }
    if (this.respiratoryRate.isValidForPush) {
      validity = true;
    }
    if (this.pain.isValidForPush) {
      validity = true;
    }
    if (this.bloodGlucose.isValidForPush) {
      validity = true;
    }

    if (this.bloodPressure.isValidForPush) {
      validity = true;
    }

    return validity;
  }

  pushVitalArray(): {}[] {
    let vitalArray = [];
    if (this.height.isValidForPush) {
      vitalArray.push(this.height.toJSON());
    }
    if (this.weight.isValidForPush) {
      vitalArray.push(this.weight.toJSON());
    }
    if (this.bmi.isValidForPush) {
      vitalArray.push(this.bmi.toJSON());
    }
    if (this.spo2.isValidForPush) {
      vitalArray.push(this.spo2.toJSON());
    }
    if (this.pulse.isValidForPush) {
      vitalArray.push(this.pulse.toJSON());
    }
    if (this.temperature.isValidForPush) {
      vitalArray.push(this.temperature.toJSON());
    }
    if (this.respiratoryRate.isValidForPush) {
      vitalArray.push(this.respiratoryRate.toJSON());
    }
    if (this.pain.isValidForPush) {
      vitalArray.push(this.pain.toJSON());
    }
    if (this.bloodGlucose.isValidForPush) {
      vitalArray.push(this.bloodGlucose.toJSON());
    }
    if (this.bloodPressure.isValidForPush) {
      vitalArray.push(this.bloodPressure.toJSON());
    }
    return vitalArray;
  }

  constructor() {
    this.height = new VitalMeasurement();
    this.height.vitalType = VitalMeasurementConstants.VITAL_TYPE_HEIGHT;

    this.weight = new VitalMeasurement();
    this.weight.vitalType = VitalMeasurementConstants.VITAL_TYPE_WEIGHT;

    this.bmi = new VitalMeasurement();
    this.bmi.vitalType = VitalMeasurementConstants.VITAL_TYPE_BMI;

    this.spo2 = new VitalMeasurement();
    this.spo2.vitalType = VitalMeasurementConstants.VITAL_TYPE_SPO2;

    this.pulse = new VitalMeasurement();
    this.pulse.vitalType = VitalMeasurementConstants.VITAL_TYPE_PULSE;

    this.temperature = new VitalMeasurement();
    this.temperature.vitalType =
      VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE;

    this.respiratoryRate = new VitalMeasurement();
    this.respiratoryRate.vitalType =
      VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE;

    this.pain = new VitalMeasurement();
    this.pain.vitalType = VitalMeasurementConstants.VITAL_TYPE_PAIN;

    this.bloodGlucose = new VitalMeasurement();
    this.bloodGlucose.vitalType =
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE;

    this.bloodPressure = new VitalMeasurement();
    this.bloodPressure.vitalType =
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE;
  }

  //This is for displaying vitals in a single line
  public singleLineValue():string{
    let str = "";
    // alert("Yo")
    if (this.height.isValidForPush) {
      str += "H- "+this.height.getVitalValue()+"  ";
    }
    if (this.weight.isValidForPush) {
      str += "W- "+this.weight.getVitalValue()+"  ";
    }
    if (this.bmi.isValidForPush) {
      str += "BMI- "+this.bmi.getVitalValue()+"  ";
    }
    if (this.spo2.isValidForPush) {
      str += "SPO2- "+this.spo2.getVitalValue()+"  ";
    }
    if (this.pulse.isValidForPush) {
      str += "Pulse- "+this.pulse.getVitalValue()+"  ";
    }
    if (this.temperature.isValidForPush) {
      str += "Temperature- "+this.temperature.getVitalValue()+"  ";
    }
    if (this.respiratoryRate.isValidForPush) {
      str += "RR- "+this.respiratoryRate.getVitalValue()+"  ";
    }
    if (this.bloodGlucose.isValidForPush) {
      str += "<br/>BG- "+this.bloodGlucose.getVitalValue()+"  ";
    }
    if (this.bloodPressure.isValidForPush) {
      str += "<br/>BP- "+this.bloodPressure.getVitalValue()+"  ";
    }
    return str
  }

  setVitalFromJSON(json: {}) {
    let v = new VitalMeasurement();
    v.initFromJSON(json);
    switch (v.vitalType) {
      case VitalMeasurementConstants.VITAL_TYPE_HEIGHT:{
        this.height = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_WEIGHT:{
        this.weight = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_BMI:{
        this.bmi = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_PULSE:{
        this.pulse = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE:{
        this.temperature = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE:{
        this.respiratoryRate = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_SPO2:{
        this.spo2 = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE:{
        this.bloodGlucose = v;
        break;
      }
      case VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE:{
        this.bloodPressure = v;
        break;
      }
    }
  }

  /**
   * Getter height
   * @return {VitalMeasurement}
   */
  public get height(): VitalMeasurement {
    return this._height;
  }

  /**
   * Setter height
   * @param {VitalMeasurement} value
   */
  public set height(value: VitalMeasurement) {
    this._height = value;
  }

  /**
   * Getter weight
   * @return {VitalMeasurement}
   */
  public get weight(): VitalMeasurement {
    return this._weight;
  }

  /**
   * Getter bmi
   * @return {VitalMeasurement}
   */
  public get bmi(): VitalMeasurement {
    return this._bmi;
  }

  /**
   * Getter spo2
   * @return {VitalMeasurement}
   */
  public get spo2(): VitalMeasurement {
    return this._spo2;
  }

  /**
   * Getter pulse
   * @return {VitalMeasurement}
   */
  public get pulse(): VitalMeasurement {
    return this._pulse;
  }

  /**
   * Getter temperature
   * @return {VitalMeasurement}
   */
  public get temperature(): VitalMeasurement {
    return this._temperature;
  }

  /**
   * Getter respiratoryRate
   * @return {VitalMeasurement}
   */
  public get respiratoryRate(): VitalMeasurement {
    return this._respiratoryRate;
  }

  /**
   * Getter pain
   * @return {VitalMeasurement}
   */
  public get pain(): VitalMeasurement {
    return this._pain;
  }

  /**
   * Getter bloodGlucose
   * @return {VitalMeasurement}
   */
  public get bloodGlucose(): VitalMeasurement {
    return this._bloodGlucose;
  }

  /**
   * Getter bloodPressure
   * @return {VitalMeasurement}
   */
  public get bloodPressure(): VitalMeasurement {
    return this._bloodPressure;
  }

  /**
   * Setter weight
   * @param {VitalMeasurement} value
   */
  public set weight(value: VitalMeasurement) {
    this._weight = value;
  }

  /**
   * Setter bmi
   * @param {VitalMeasurement} value
   */
  public set bmi(value: VitalMeasurement) {
    this._bmi = value;
  }

  /**
   * Setter spo2
   * @param {VitalMeasurement} value
   */
  public set spo2(value: VitalMeasurement) {
    this._spo2 = value;
  }

  /**
   * Setter pulse
   * @param {VitalMeasurement} value
   */
  public set pulse(value: VitalMeasurement) {
    this._pulse = value;
  }

  /**
   * Setter temperature
   * @param {VitalMeasurement} value
   */
  public set temperature(value: VitalMeasurement) {
    this._temperature = value;
  }

  /**
   * Setter respiratoryRate
   * @param {VitalMeasurement} value
   */
  public set respiratoryRate(value: VitalMeasurement) {
    this._respiratoryRate = value;
  }

  /**
   * Setter pain
   * @param {VitalMeasurement} value
   */
  public set pain(value: VitalMeasurement) {
    this._pain = value;
  }

  /**
   * Setter bloodGlucose
   * @param {VitalMeasurement} value
   */
  public set bloodGlucose(value: VitalMeasurement) {
    this._bloodGlucose = value;
  }

  /**
   * Setter bloodPressure
   * @param {VitalMeasurement} value
   */
  public set bloodPressure(value: VitalMeasurement) {
    this._bloodPressure = value;
  }
}
