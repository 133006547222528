export class MedicationSpecificTimingConstants {
  public static EMPTY_STOMACH = {
    label: "empty stomach",
    label_hi: "खाली पेट",
    value: "empty_stomach"
  };
  public static AT_BEDTIME = {
    label: "at bedtime",
    label_hi: "सोने से पहले",
    value: "at_bedtime"
  };
  public static BEFORE_MEALS = {
    label: "before meals",
    label_hi:"भोजन से पहले",
    value: "before_meals",
  };
  public static AFTER_MEALS = {
    label: "after meals",
    label_hi:"भोजन के बाद",
    value: "after_meals",
  };
}
