import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";

export class CurrentMedication implements BasicModelInterface {
  private _id: string;
  private _createdOn: number;
  private _createdBy: string;
  private _text: string;
  private _dosageSummary: string;
  private _expiryDate: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.id) {
      json[ObjectKeys.id] = this.id;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.createdBy) {
      json[ObjectKeys.createdBy] = this.createdBy;
    }
    if (this.text) {
      json[ObjectKeys.text] = this.text;
    }
    if (this.dosageSummary) {
      json[ObjectKeys.dosageSummary] = this.dosageSummary;
    }
    if (this.expiryDate) {
      json[ObjectKeys.expiryDate] = this.expiryDate;
    }

    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.id]) {
      this.id = json[ObjectKeys.id];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.createdBy]) {
      this.createdBy = json[ObjectKeys.createdBy];
    }
    if (json[ObjectKeys.text]) {
      this.text = json[ObjectKeys.text];
    }
    if (json[ObjectKeys.dosageSummary]) {
      this.dosageSummary = json[ObjectKeys.dosageSummary];
    }
    if (json[ObjectKeys.expiryDate]) {
      this.expiryDate = json[ObjectKeys.expiryDate];
    }
  }

  /**
   * Getter expiryDate
   * @return {string}
   */
  public get expiryDate(): string {
    return this._expiryDate;
  }

  /**
   * Setter expiryDate
   * @param {string} value
   */
  public set expiryDate(value: string) {
    this._expiryDate = value;
  }

  /**
   * Getter dosageSummary
   * @return {string}
   */
  public get dosageSummary(): string {
    return this._dosageSummary;
  }

  /**
   * Setter dosageSummary
   * @param {string} value
   */
  public set dosageSummary(value: string) {
    this._dosageSummary = value;
  }
  /**
   * Getter id
   * @return {string}
   */
  public get id(): string {
    return this._id;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Getter createdBy
   * @return {string}
   */
  public get createdBy(): string {
    return this._createdBy;
  }

  /**
   * Getter text
   * @return {string}
   */
  public get text(): string {
    return this._text;
  }

  /**
   * Setter id
   * @param {string} value
   */
  public set id(value: string) {
    this._id = value;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }

  /**
   * Setter createdBy
   * @param {string} value
   */
  public set createdBy(value: string) {
    this._createdBy = value;
  }

  /**
   * Setter text
   * @param {string} value
   */
  public set text(value: string) {
    this._text = value;
  }
}
