export class MedicationDosageUnitConstants {
  public static CC = {
    value: "cc",
    label: "cc",
    shortlabel: "cc"
  };
  public static AMPULE = {
    value: "ampule",
    label: "ampule",
    shortlabel: "ampule"
  };
  public static TABLET = {
    value: "tablet",
    label: "tablet",
    shortlabel: "tab"
  };
  public static CAPSULE = {
    value: "capsule",
    label: "capsule",
    shortlabel: "cap"
  };
  public static CAP = {
    value: "cap",
    label: "cap",
    shortlabel: "cap"
  };
  public static DROPS = {
    value: "drop",
    label: "drop",
    shortlabel: "drop"
  };
  public static ML = {
    value: "mL",
    label: "mL",
    shortlabel: "mL"
  };
  public static TEA_SPOON = {
    value: "tea spoon",
    label: "tea spoon",
    shortlabel: "tsp."
  };
  public static TABLE_SPOON = {
    value: "table spoon",
    label: "table spoon",
    shortlabel: "tbsp."
  };
  public static DROPPER = {
    value: "dropper",
    label: "dropper",
    shortlabel: "dropper"
  };
  public static BANDAGE = {
    value: "bandage",
    label: "bandage",
    shortlabel: "bandage"
  };
  public static MG = {
    value: "mg",
    label: "mg",
    shortlabel: "mg"
  };
  public static G = {
    value: "g",
    label: "g",
    shortlabel: "g"
  };
  public static UNIT = {
    value: "unit",
    label: "unit",
    shortlabel: "unit"
  };
  public static SACHET = {
    value: "sachet",
    label: "sachet",
    shortlabel: "sachet"
  };
  public static PACK = {
    value: "pack",
    label: "pack",
    shortlabel: "pack"
  };
  public static PUFF = {
    value: "puff",
    label: "puff",
    shortlabel: "puff"
  };
  public static ACTUATION = {
    value: "actuation",
    label: "actuation",
    shortlabel: "actuation"
  };

  public static LANGUAGE_LABEL = {
    "cc":{
      hi:"cc"
    },
    "ampule":{
      hi:"ampule"
    },
    "tablet":{
      hi:"गोली"
    },
    "capsule":{
      hi:"कैप्सूल"
    },
    "cap":{
      hi:"ढक्कन"
    },
    "drop":{
      hi:"ड्रॉप"
    },
    "mL":{
      hi:"mL"
    },
    "tea spoon":{
      hi:"छोटी चम्मच"
    },
    "table spoon":{
      hi:"बडी चम्मच"
    },
    "dropper":{
      "hi":"ड्रॉपर"
    },
    "bandage":{
      "hi":"पट्टी"
    },
    "mg":{
      "hi":"mg"
    },
    "g":{
      "hi":"ग्राम"
    },
    "unit":{
      hi:"यूनिट"
    },
    "sachet":{
      "hi":"पाउच"
    },
    "pack":{
      "hi":"पैक"
    },
    "puff":{
      hi:"कश"
    },
    "actuation":{
      hi:"actuation"
    }
  }

  public static ALL = [
    MedicationDosageUnitConstants.TABLET,
    MedicationDosageUnitConstants.CAPSULE,
    MedicationDosageUnitConstants.CAP,
    MedicationDosageUnitConstants.DROPS,
    MedicationDosageUnitConstants.ML,
    MedicationDosageUnitConstants.TEA_SPOON,
    MedicationDosageUnitConstants.TABLE_SPOON,
    MedicationDosageUnitConstants.DROPPER,
    MedicationDosageUnitConstants.BANDAGE,
    MedicationDosageUnitConstants.MG,
    MedicationDosageUnitConstants.G,
    MedicationDosageUnitConstants.UNIT,
    MedicationDosageUnitConstants.SACHET,
    MedicationDosageUnitConstants.PACK,
    MedicationDosageUnitConstants.AMPULE,
    MedicationDosageUnitConstants.CC,
    MedicationDosageUnitConstants.PUFF,
    MedicationDosageUnitConstants.ACTUATION
  ];
}
