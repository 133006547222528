import { Component, OnInit, OnDestroy } from "@angular/core";
import { SpeechRecognizerService } from "../shared/web-speech/services/speech-recognizer.service";
import { SpeechNotification } from "../shared/web-speech/model/speech-notification";
import { SpeechError } from "../shared/web-speech/model/speech-error";
import { NotifyService } from "../shared/services/notify/notify.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrganisationProfileOperationsService } from "../shared/services/organisation/organisation-profile-operations.service";
import {
  OrganisationMember,
  Organisation,
  PlanInfo
} from "../shared/models/MD - Member";
import { Patient } from "../shared/models/Patient/Patient";
import { PatientRegistrationModalService } from "../shared/services/modalStateManagement/patient-registration-modal.service";
import { Subscription } from "rxjs";
import { ScheduleAppointmentModalService } from "../shared/services/modalStateManagement/schedule-appointment-modal.service";
import {
  VoiceService,
  VoiceAssistantStates
} from "../shared/services/voice/voice.service";
import * as firebase from "firebase";
import {
  FirestoreCollections,
  ObjectKeys
} from "../shared/constants/object-keys";
import { PrescriptionTemplate } from "../shared/models/Prescription/PrescriptionTemplate";
import { ActivatedRoute, Router, Event, NavigationEnd } from "@angular/router";
import { DateActions } from "../shared/models/Demographics";

declare var jQuery: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "./common-layout.component.html",
  styleUrls: ["./common-layout.component.css"],
  providers: [SpeechRecognizerService]
})
export class CommonLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  defaultImagePath: string = "assets/images/img-2.jpg";

  public app: any;
  public headerThemes: any;
  public changeHeader: any;
  public sidenavThemes: any;
  public changeSidenav: any;
  public headerSelected: any;
  public sidenavSelected: any;
  public searchActived: any;
  public searchModel: any;

  public assistantState: VoiceAssistantStates;

  // Variables for Voice Assistant
  spokenVoiceTextOnDisplay: string; // text in transit > final version
  voiceAssistantActive: boolean = false; // While we're listening to the user
  voiceAssistantProcessing: boolean = false; // While we're talking to our servers

  //Voice recognizer variables
  currentLanguage: string;
  languages: string[] = ["en-IN", "es-US"];
  finalTranscript: string = "";

  public selectedPatientForEditing: Patient = new Patient();
  public selectedPatientsForAppointment: Patient[] = [];
  initialText: string = "";
  private subscriptionAppointmentSchedulingService: Subscription;
  private subscriptionPatientRegModalService: Subscription;
  private subscriptionPatientRegModalStateTracker: Subscription;
  private subscriptionPatientInitialTextSidePanelService: Subscription;
  private subscriptionSpeechStart: Subscription;
  private subscriptionSpeechEnd: Subscription;
  private subscriptionSpeechResult: Subscription;
  private subscriptionSpeechError: Subscription;
  memberName: string = "";
  memberEmail: string = "";
  memberPhotoUrl: string;
  currentActivePlan: PlanInfo = new PlanInfo();

  // Variable to control scheduled modal hiding for patient registration
  hideSchedulingDialog: boolean = false;
  enterFullScreenMode: boolean = false; // toggle full screen mode based on this

  private canAccessConsentFormModule: boolean = false;
  private canAccessDentistryModule: boolean = false;
  private canAccessInstructionsModule: boolean = false;

  constructor(
    private _organisationProfileOperationsService: OrganisationProfileOperationsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private _speechRecognizerService: SpeechRecognizerService,
    private _notifyService: NotifyService,
    private _patientRegistrationModalService: PatientRegistrationModalService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _voiceService: VoiceService
  ) {
    this.app = {
      layout: {
        sidePanelOpen: false,
        isMenuOpened: true,
        isMenuCollapsed: false,
        themeConfigOpen: false,
        rtlActived: false,
        searchActived: false
      }
    };

    this.headerThemes = [
      "header-default",
      "header-primary",
      "header-info",
      "header-success",
      "header-danger",
      "header-dark"
    ];
    this.changeHeader = changeHeader;

    function changeHeader(headerTheme) {
      this.headerSelected = headerTheme;
    }

    this.sidenavThemes = ["sidenav-default", "side-nav-dark"];
    this.changeSidenav = changeSidenav;

    function changeSidenav(sidenavTheme) {
      this.sidenavSelected = sidenavTheme;
    }

    this.currentLanguage = this.languages[0];
    this._speechRecognizerService.initialize(this.currentLanguage);
    this.initRecognition();
    this.observeVoiceAssistant();
    this.memberName = GlobalVariables.getMemberName();
    this.memberEmail = GlobalVariables.getMember().primaryEmail;
    this.memberPhotoUrl =
      GlobalVariables.getMember().photoUrl || this.defaultImagePath;
  }

  private fetchLoggedInMemberProfile() {
    if (GlobalVariables.getMemberId()) {
      this._organisationProfileOperationsService
        .getMemberInfo(GlobalVariables.getMemberId())
        .then(response => {
          if (response) {
            let statusCode = response["statusCode"];
            if (statusCode && statusCode == 200) {
              let body = response["body"];
              let dataJSON = body["data"];
              let member: OrganisationMember = new OrganisationMember();
              member.initFromJSON(dataJSON);
              GlobalVariables.setMember(member);
              this.memberPhotoUrl = member.photoUrl || this.defaultImagePath;
              let organisationJSON = body["organisation"];
              let organisation: Organisation = new Organisation();
              organisation.initFromJSON(organisationJSON);
              GlobalVariables.setOrganisation(organisation);

              let membersJSONArray = body["members"];
              for (let memJSON of membersJSONArray) {
                let member: OrganisationMember = new OrganisationMember();
                member.initFromJSON(memJSON);
                GlobalVariables.setMemberInMap(member);
              }
              //TODO: check if the plan is not selected then redirect it to plan selection page.
              this.checkForPlans();
              this.currentPlanActive();
              // console.log(
              //   "MEMBER PROFILE RESPONSE",
              //   GlobalVariables.member,
              //   GlobalVariables.organisation,
              //   GlobalVariables.membersMap
              // );
            } else {
              //handle error code
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  private currentPlanActive() {
    this.currentActivePlan = GlobalVariables.getOrganisation().currentActivePlan(
      DateActions.getDateString(new Date())
    );
  }

  private checkForPlans() {
    console.log(
      "PLAN INFO",
      GlobalVariables.getOrganisation().planInfo == null,
      GlobalVariables.getOrganisation().planInfo.size == 0,
      !GlobalVariables.getOrganisation().hasActivePlan(
        DateActions.getDateString(new Date())
      )
    );
    if (
      GlobalVariables.getOrganisation().planInfo == null ||
      GlobalVariables.getOrganisation().planInfo.size == 0 ||
      !GlobalVariables.getOrganisation().hasActivePlan(
        DateActions.getDateString(new Date())
      )
    ) {
      this._router.navigateByUrl("plan-and-billing/choose-plan");
    }
  }

  private resetVoice() {
    this.spokenVoiceTextOnDisplay = null;
  }

  private initRecognition() {
    this.unsubscribeSpeech();
    this.subscriptionSpeechStart = this._speechRecognizerService
      .onStart()
      .subscribe(data => {
        this.voiceAssistantActive = true;
        // this._notifyService.showSuccessMessage("I'm listening...");
        this.spokenVoiceTextOnDisplay = null;
      });

    this.subscriptionSpeechEnd = this._speechRecognizerService
      .onEnd()
      .subscribe(data => {
        this.voiceAssistantActive = false;
        this._voiceService.startWithState(VoiceAssistantStates.stop)
        console.log("onEnd: VoiceAssistant");
      });

    this.subscriptionSpeechResult = this._speechRecognizerService
      .onResult()
      .subscribe((data: SpeechNotification) => {
        // console.log("Got results:")
        if (data && data.content) {
          var message = data.content.trim();
          if (data.info === "final_transcript" && message.length > 0) {
            this.finalTranscript = `${this.finalTranscript}\n${message}`;
            switch (this.assistantState) {
              case VoiceAssistantStates.diagnosis: {
                this._voiceService.setDiagnosis(null, message);
                break;
              }
              case VoiceAssistantStates.newQuickNote: {
                this._voiceService.setNewQuickNote(null, message);
                break;
              }
              case VoiceAssistantStates.medicationComment: {
                this._voiceService.setMedicationComments(null, message);
                break;
              }
              case VoiceAssistantStates.prescriptionComment: {
                this._voiceService.setPrescriptionComment(null, message);
                break;
              }
              case VoiceAssistantStates.prescriptionTemplateTitle: {
                this._voiceService.setPrescriptionTemplateTitle(null, message);
                break;
              }
              case VoiceAssistantStates.medicationCellText: {
                this._voiceService.setMedicationCellText(null, message);
                break;
              }
              case VoiceAssistantStates.prescriptionTemplate:
              default: {
                //TODO: Show final results in top bar

                console.log("Spoken Message:", message);
                // Replacer comes here
                message = message.replace(/set up/gi, "syrup");

                this.spokenVoiceTextOnDisplay = message;
                //Contacting brain
                this._voiceService
                  .dialog(message)
                  .then(resp => {
                    if (
                      resp &&
                      resp["statusCode"] &&
                      resp["statusCode"] == 200
                    ) {
                      let body = resp["body"];
                      let intent = body["intent"];
                      let json = body["json"];
                      switch (intent) {
                        case "APPOINTMENT": {
                          this.onClickScheduleNewVisit();
                          setTimeout(time => {
                            this._voiceService.setAppointmentJSON(json);
                          }, 110);
                          this._voiceService.stop();
                          this.voiceAssistantActive = false;
                          break;
                        }
                        case "MEDICINE": {
                          this._voiceService.setMedicationJSON(json);
                          setTimeout(time => {
                            this._voiceService.clearMedicationJSON();
                          }, 1000); //Clear values so that when you open a new medicine form it will not come pre populated with old voice command json
                          break;
                        }
                        case "VITAL": {
                          this._voiceService.setVitalJSON(json);
                          setTimeout(time => {
                            this._voiceService.clearVitalJSON();
                          }, 1000); //Clear values so that when you open a new medicine form it will not come pre populated with old voice command json
                          break;
                        }
                        case "BLOOD_GROUP": {
                          this._voiceService.setBloodGroup(
                            json["blood_group_type"]
                          );
                          setTimeout(time => {
                            this._voiceService.setBloodGroup(null);
                          }, 1000); //Clear values
                          break;
                        }
                        case "ALLERGY": {
                          this._voiceService.setAllergy(json);
                          setTimeout(time => {
                            this._voiceService.setAllergy(null);
                          }, 1000);
                          break;
                        }
                        case "DIAGNOSIS": {
                          this._voiceService.setDiagnosisEntry(json);
                          setTimeout(time => {
                            this._voiceService.setDiagnosisEntry(null);
                          }, 1000);
                          break;
                        }
                        case "PATIENT_HISTORY": {
                          this._voiceService.setPastHistory(json);
                          setTimeout(time => {
                            this._voiceService.setPastHistory(null);
                          }, 1000);
                          break;
                        }
                        case "NEW_PRESCRIPTION": {
                          this._voiceService.setNewPrescription(json);
                          setTimeout(time => {
                            this._voiceService.setNewPrescription(null);
                          }, 1000);
                          break;
                        }
                        case "PRESCRIPTION_TEMPLATE": {
                          this._voiceService.setPrescriptionTemplate(json);
                          setTimeout(time => {
                            this._voiceService.clearPrescriptionTemplate();
                          }, 1000);
                          break;
                        }
                      }
                    } else {
                      //handle it later
                    }
                  })
                  .catch(err => {
                    console.error(err);
                  });
              }
            }
          } else if (data.info === "interim_transcript" && message.length > 0) {
            switch (this.assistantState) {
              case VoiceAssistantStates.newQuickNote: {
                this._voiceService.setNewQuickNote(message, null);
                break;
              }
              case VoiceAssistantStates.diagnosis: {
                this._voiceService.setDiagnosis(message, null);
                break;
              }
              case VoiceAssistantStates.medicationComment: {
                this._voiceService.setMedicationComments(message, null);
                break;
              }
              case VoiceAssistantStates.prescriptionComment: {
                this._voiceService.setPrescriptionComment(message, null);
                break;
              }
              case VoiceAssistantStates.medicationCellText: {
                this._voiceService.setMedicationCellText(message, null);
                break;
              }
              case VoiceAssistantStates.prescriptionTemplateTitle: {
                this._voiceService.setPrescriptionTemplateTitle(message, null);
                break;
              }
              case VoiceAssistantStates.prescriptionTemplate:
              default: {
                //Populate partial results
                this.spokenVoiceTextOnDisplay = message;
              }
            }
          }
        }
      });

    this.subscriptionSpeechError = this._speechRecognizerService
      .onError()
      .subscribe(data => {
        switch (data.error) {
          case SpeechError.BLOCKED:
          case SpeechError.NOT_ALLOWED:
            this._notifyService.showErrorMessage(
              "Your browser is not authorized to access your microphone. Verify that your browser has access to your microphone and try again."
            );
            break;
          case SpeechError.NO_SPEECH:
            this._notifyService.showErrorMessage(
              "No speech has been detected. Please try again."
            );
            break;
          case SpeechError.NO_MICROPHONE:
            this._notifyService.showErrorMessage(
              "Microphone is not available. Plese verify the connection of your microphone and try again."
            );
            break;
          default:
            break;
        }
        this.voiceAssistantActive = false;
        this._voiceService.startWithState(VoiceAssistantStates.stop)
      });
  }

  private unsubscribeSpeech() {
    if (this.subscriptionSpeechStart) {
      this.subscriptionSpeechStart.unsubscribe();
    }
    if (this.subscriptionSpeechEnd) {
      this.subscriptionSpeechEnd.unsubscribe();
    }
    if (this.subscriptionSpeechError) {
      this.subscriptionSpeechError.unsubscribe();
    }
    if (this.subscriptionSpeechResult) {
      this.subscriptionSpeechResult.unsubscribe();
    }
  }

  ngOnInit() {
    this.fetchLoggedInMemberProfile();
    this.subscribeAppointmentSchedulingService();
    this.subscribePatientRegistrationModalService();
    this.subscribePatientInitialTextSidePanelService();
    this.fetchPrescriptionTemplates();
    this.subsribeToActivatedRouteForFullScreen();

    if (this.isPathEligibleForFullScreen(this._router.url)) {
      this.enterFullScreenMode = true;
    } else {
      this.enterFullScreenMode = false;
    }

    this.canAccessConsentFormModule = GlobalVariables.getMember().canAccessConsentForm(GlobalVariables.getOrganisationId())
    this.canAccessDentistryModule = GlobalVariables.getMember().canAccessDentistry(GlobalVariables.getOrganisationId())
    this.canAccessInstructionsModule = GlobalVariables.getMember().canAccessInstructions(GlobalVariables.getOrganisationId())
  }

  isPathEligibleForFullScreen(urlString: string): boolean {
    return (
      urlString.indexOf("patient-detail") != -1 ||
      urlString.indexOf("patient-chart-dental") != -1
    );
  }

  subsribeToActivatedRouteForFullScreen() {
    this._router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (this.isPathEligibleForFullScreen(event.url)) {
          this.enterFullScreenMode = true;
        } else {
          this.enterFullScreenMode = false;
        }
      }
      // console.log("Common Layout Route Events: ", event);
    });
    this._activatedRoute.url.subscribe(observer => {
      console.log("Common Layout URl: ", observer.values);
    });
    // this._activatedRoute.url.subscribe(
    //   observer:
    // )
  }

  /**
   * Listen to calls for opening and closing of appointment side panel
   */
  subscribeAppointmentSchedulingService() {
    this.subscriptionAppointmentSchedulingService = this._scheduleAppointmentModalService
      .getSelectedPatient()
      .subscribe(user => {
        this.selectedPatientsForAppointment = [];
        let u = new Patient();
        u.initFromJSON(user.toJSON());
        this.selectedPatientsForAppointment.push(u);
        this._scheduleAppointmentModalService.open();
      });
  }

  /**
   * Listen to calls for opening and closing of patient side panel
   */
  subscribePatientRegistrationModalService() {
    this.subscriptionPatientRegModalService = this._patientRegistrationModalService
      .getSelectedInProfileEditing()
      .subscribe(user => {
        this.hideSchedulingDialog = true;
        this.selectedPatientForEditing = new Patient();
        this.selectedPatientForEditing.initFromJSON(user.toJSON());
        setTimeout(time => {
          this._patientRegistrationModalService.openPatientProfileEditing();
        }, 110);
      });

    this.subscriptionPatientRegModalStateTracker = this._patientRegistrationModalService
      .getModalStateTracker()
      .subscribe((currentState: boolean) => {
        this.hideSchedulingDialog = currentState;
      });
  }

  /**
   * For initial text passed in patient form
   */
  subscribePatientInitialTextSidePanelService() {
    this.subscriptionPatientInitialTextSidePanelService = this._patientRegistrationModalService
      .getInitialText()
      .subscribe(str => {
        if (str != null && str.length > 0) {
          this.initialText = str;
        }
      });
  }

  /**
   * Open patient registration modal
   * @param modal - patient registration modal
   */
  openPatientRegistrationModal(modal) {
    this.initialText = "";
    this._patientRegistrationModalService.clearInitialText();
    this.selectedPatientForEditing = new Patient();
    this._patientRegistrationModalService.openPatientProfileEditing();
    this.hideSchedulingDialog = true;
    // this.sidePanelService.openPatientProfileEditing();
  }

  observeVoiceAssistant() {
    this._voiceService.observe().subscribe(state => {
      switch (state) {
        case VoiceAssistantStates.toggle: {
          this.invokeVoiceAssistant();
          break;
        }
        case VoiceAssistantStates.start: {
          this.startVoiceAssistant();
          break;
        }
        case VoiceAssistantStates.stop: {
          this.stopVoiceAssistant();
          this.assistantState = VoiceAssistantStates.stop;
          this.voiceAssistantActive = false;
          break;
        }
        case VoiceAssistantStates.medicationComment: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.medicationComment;
          break;
        }
        case VoiceAssistantStates.prescriptionComment: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.prescriptionComment;
          break;
        }
        case VoiceAssistantStates.diagnosis: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.diagnosis;
          break;
        }
        case VoiceAssistantStates.newQuickNote: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.newQuickNote;
          break;
        }
        case VoiceAssistantStates.prescriptionTemplate: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.prescriptionTemplate;
          break;
        }
        case VoiceAssistantStates.prescriptionTemplateTitle: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.prescriptionTemplateTitle;
          break;
        }
        case VoiceAssistantStates.medicationCellText: {
          this._voiceService.startWithState(VoiceAssistantStates.stop);
          this.voiceAssistantActive = false;
          setTimeout(time => {
            this.startVoiceAssistant();
            console.log("startVoiceAssistant");
          }, 200);
          this.assistantState = VoiceAssistantStates.medicationCellText;
          break;
        }
      }
    });
  }

  stopVoiceAssistant() {
    this._speechRecognizerService.stop();
    this.assistantState = VoiceAssistantStates.stop;
  }

  startVoiceAssistant(): boolean {
    if (this._speechRecognizerService.isBrowserSupported()) {
      this._speechRecognizerService.start(new Date().getTime());
      return true;
    } else {
      this._notifyService.showErrorMessage(
        "Voice functionality is not supported by this browser. We recommend using Google Chrome version 25 or later on PC / Android devices."
      );
      return false;
    }
  }

  invokeVoiceAssistant() {
    if (this._speechRecognizerService.isBrowserSupported()) {
      if (this.voiceAssistantActive) {
        this._voiceService.startWithState(VoiceAssistantStates.stop)
        this.voiceAssistantActive = false;
        return;
      }
      setTimeout(time => {
        this._speechRecognizerService.start(new Date().getTime());
      }, 200);
    } else {
      this._notifyService.showErrorMessage(
        "Voice functionality is not supported by this browser. Upgrade to Google Chrome version 25 or later."
      );
    }
  }

  ngAfterViewInit() {
    // this.modalService.open(this.patientRegModal);
    // this.open(this.patientRegModal);
    // this.onClickScheduleNewVisit();
  }

  onSaveCalled(patient: Patient) {
    this._patientRegistrationModalService.setNewAddedPatient(patient);
  }

  // onSaveCalled(userJSON: {}) {
  //   //Add it to subject which is being listened by many components
  //   if (userJSON && userJSON["patient"]) {
  //     let u: Patient = new Patient();
  //     u.initFromJSON(userJSON["patient"]);
  //     this._patientRegistrationModalService.setNewAddedPatient(u);
  //   }
  // }

  onClickScheduleNewVisit() {
    this._scheduleAppointmentModalService.open();
  }

  ngOnDestroy() {
    if (this.subscriptionAppointmentSchedulingService) {
      this.subscriptionAppointmentSchedulingService.unsubscribe();
    }
    this.unsubscribeSpeech();

    if (this.subscriptionPatientRegModalStateTracker) {
      this.subscriptionPatientRegModalStateTracker.unsubscribe();
    }
  }

  fetchPrescriptionTemplates() {
    firebase
      .firestore()
      .collection(FirestoreCollections.PRESCRIPTION_TEMPLATE)
      .where(
        ObjectKeys.organisationId,
        "==",
        GlobalVariables.getOrganisationId()
      )
      .get()
      .then(snapshot => {
        if (!snapshot.empty) {
          snapshot.forEach(val => {
            let p: PrescriptionTemplate = new PrescriptionTemplate();
            p.initFromJSON(val.data());
            GlobalVariables.prescriptionTampletes.set(
              p.prescriptionTemplateId,
              p
            );
          });
          // console.log("Prescription Templates:",GlobalVariables.prescriptionTampletes)
        }
      });
  }

  goToMyAccount() {
    this._router.navigateByUrl(
      "settings/user-accounts-list/" + GlobalVariables.getMemberId()
    );
  }

  goToPlanAndBillingHistory() {
    this._router.navigateByUrl("plan-and-billing/history");
  }

  logout() {
    firebase.auth().signOut();
    GlobalVariables.clearValues();
    this._notifyService.showSuccessMessage("Signing out...");
  }
  
  featureComingSoon() {
    this._notifyService.showSuccessMessage("We're working on it. :)","Coming Soon");
  }
}
