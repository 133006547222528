import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";

export class Diagnosis implements BasicModelInterface {
  private _text: string;
  private _diagnosisType: string;
  // private _diagnosisType: "none" | "primary" | "secondary" | "provisional";
  private _createdOn: number;
  private _createdBy: string;

  constructor() {}

  toJSON(): {} {
    let json = {};
    if (this.diagnosisType) {
      json[ObjectKeys.diagnosisType] = this.diagnosisType;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.createdBy) {
      json[ObjectKeys.createdBy] = this.createdBy;
    }
    if (this.text) {
      json[ObjectKeys.text] = this.text;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.diagnosisType]) {
      this.diagnosisType = json[ObjectKeys.diagnosisType];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.createdBy]) {
      this.createdBy = json[ObjectKeys.createdBy];
    }
    if (json[ObjectKeys.text]) {
      this.text = json[ObjectKeys.text];
    }
  }

  /**
   * Getter text
   * @return {string}
   */
  public get text(): string {
    return this._text;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Getter createdBy
   * @return {string}
   */
  public get createdBy(): string {
    return this._createdBy;
  }

  /**
   * Setter text
   * @param {string} value
   */
  public set text(value: string) {
    this._text = value;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }

  /**
   * Setter createdBy
   * @param {string} value
   */
  public set createdBy(value: string) {
    this._createdBy = value;
  }

  /**
   * Getter diagnosisType
   * @return {string}
   */
  public get diagnosisType(): string {
    return this._diagnosisType;
  }

  /**
   * Setter diagnosisType
   * @param {string} value
   */
  public set diagnosisType(value: string) {
    this._diagnosisType = value;
  }
}
