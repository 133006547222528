import { Pipe, PipeTransform } from "@angular/core";
import { AppointmentStatus } from "../constants/object-keys";
// import * as keys from '../../keys';

@Pipe({
  name: "filterByStatus"
})
export class FilterByStatusPipe implements PipeTransform {
  transform(items: any[], args?: boolean): any {
    if (!items) {
      return items;
    }

    return items.filter(item => {
      if (args) {
        return true;
      } else if (!args && item.status === AppointmentStatus.APPOINTMENT_ARRIVED) {
        return true;
      }
      return false;
    });
  }
}
