import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectizeModule } from "ng-selectize";

// Components
import { PatientReusableCellComponent } from "./components/patient-reusable-cell/patient-reusable-cell.component";

// Pipes
import { CustomDateFilterPipe } from "./pipes/custom-date-filter.pipe";
import { CustomTimeslotFilterPipe } from "./pipes/custom-timeslot-filter.pipe";
import { CustomTimestampFilterPipe } from "./pipes/custom-timestamp-filter.pipe";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TimestampToDateFilterPipe } from "./pipes/timestamp-to-date-filter.pipe";
import { DiagnosisTypePipe } from "./pipes/diagnosis-type.pipe";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ReusableDateNavigatorComponent } from "./components/reusable-date-navigator/reusable-date-navigator.component";
import { ExpandedDateFilterPipe } from "./pipes/expanded-date-filter.pipe";
import { FilterByDoctorIdPipe } from "./pipes/filter-by-doctor-id.pipe";
import { FilterByStatusPipe } from "./pipes/filter-by-status.pipe";
import { PatientSearchComponent } from "./components/patient-search/patient-search.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { MedSearchListComponent } from "./components/med-search-list/med-search-list.component";
import { SendPatientCommunicationComponent } from "./components/send-patient-communication/send-patient-communication.component";

@NgModule({
  declarations: [
    PatientReusableCellComponent,
    ReusableDateNavigatorComponent,
    PatientSearchComponent,
    CustomTimeslotFilterPipe,
    CustomDateFilterPipe,
    CustomTimestampFilterPipe,
    TimestampToDateFilterPipe,
    DiagnosisTypePipe,
    ExpandedDateFilterPipe,
    FilterByDoctorIdPipe,
    FilterByStatusPipe,
    ClickOutsideDirective,
    MedSearchListComponent,
    SendPatientCommunicationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectizeModule,
    PerfectScrollbarModule
  ],
  exports: [
    PatientReusableCellComponent,
    ReusableDateNavigatorComponent,
    PatientSearchComponent,
    CustomTimeslotFilterPipe,
    CustomDateFilterPipe,
    CustomTimestampFilterPipe,
    TimestampToDateFilterPipe,
    DiagnosisTypePipe,
    ExpandedDateFilterPipe,
    FilterByDoctorIdPipe,
    FilterByStatusPipe,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectizeModule,
    CommonModule,
    ClickOutsideDirective,
    MedSearchListComponent,
    SendPatientCommunicationComponent
  ]
})
export class SharedModule {}
