import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener
} from "@angular/core";

@Directive({
  selector: "[clickOutside]"
})
export class ClickOutsideDirective {
  constructor(private elementRef: ElementRef) {}
  @Output() clickOutsideEvent = new EventEmitter();

  @HostListener("document:click", ["$event"]) clickout(event: Event) {
    // console.log("Click Detected");
    // console.log(event);
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.clickOutsideEvent.emit();
    }
  }
}
