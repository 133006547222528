import { Routes } from '@angular/router';

// Layouts
import { CommonLayoutComponent } from './common/common-layout.component';
import { AuthGuard } from './auth/guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'plan-and-billing',
    loadChildren: './plan-and-billing/plan-and-billing.module#PlanAndBillingModule',
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: CommonLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'outpatients',
        loadChildren: './outpatients/outpatients.module#OutpatientsModule'
      },
      {
        path: 'patients',
        loadChildren: './patients/patients.module#PatientsModule'
      },
      {
        path: 'patient-detail',
        loadChildren:
          './patient-detail/patient-detail.module#PatientDetailModule'
      },
      {
        path: 'patient-chart-dental',
        loadChildren:
          './patient-chart-dental/patient-chart-dental.module#PatientChartDentalModule'
      },
      {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule'
      },
      {
        path: '**',
        redirectTo: 'outpatients'
      }
    ]
  }
];
