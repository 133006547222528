import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { DateActions } from "../../models/Demographics";
// import { NgbDateFRParserFormatter } from "../../services/datePicker/NgbDateFRParserFormatter";

@Component({
  selector: "app-reusable-date-navigator",
  templateUrl: "./reusable-date-navigator.component.html",
  styleUrls: ["./reusable-date-navigator.component.css"]
  // providers: [
  //   { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  // ]
})
export class ReusableDateNavigatorComponent implements OnInit {
  @Input() currentDate: any;
  @Output() nextDateClicked = new EventEmitter<boolean>();
  @Output() prevDateClicked = new EventEmitter<boolean>();
  @Output() todayClicked = new EventEmitter<boolean>();
  @Output() calendarDateSelected = new EventEmitter<string>();

  constructor() {}

  loadTodayDate() {
    this.todayClicked.emit(true);
  }
  loadPreviousDate() {
    this.prevDateClicked.emit(true);
  }
  loadNextDate() {
    this.nextDateClicked.emit(true);
  }
  hiddenDatePickerChanged() {
    this.currentDate["month"] = this.currentDate["month"] - 1;
    // emit the date picker date here
    this.calendarDateSelected.emit(DateActions.getDateJSONToString(this.currentDate))
  }
  ngOnInit() {}
}
