import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Patient } from "../../models/Patient/Patient";
import { ObjectKeys } from "../../constants/object-keys";
import * as urls from "../../../../environments/environment";
import * as elasticsearch from "elasticsearch-browser";

@Injectable({
  providedIn: "root"
})
export class PatientProfileService {
  private client: elasticsearch.Client;
  private PATIENT_INDEX = "india_md_patient_text_index";
  private TYPE = "_doc";
  constructor(private _http: HttpClient) {
    if (!this.client) {
      this.createClient();
    }
  }

  public createClient() {
    // console.log("creating");
    this.client = new elasticsearch.Client({
      host: urls.environment.elasticSearch
      // log: "trace"
    });
  }

  /**
   * Create new patient
   *
   * @param {Patient} patient
   * @param {string} connectedById
   * @param {string} connectedByName
   * @param {string} organisationId
   * @param {string} organisationName
   */
  public create(
    patient: Patient,
    connectedById: string,
    connectedByName: string,
    organisationId: string,
    organisationName: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["patientProfileBody"] = patient.toJSON();
      body[ObjectKeys.connectedById] = connectedById;
      body[ObjectKeys.connectedByName] = connectedByName;
      body[ObjectKeys.organisationId] = organisationId;
      body[ObjectKeys.organisationName] = organisationName;
      let val = this._http
        .post(urls.environment["patient-profile"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //2. Search patient by ...
  //Will have to deploy elastic search before completing this function.

  /**
   * Update patient profile
   *
   * @param {Patient} patient
   * @param {string} organisationId
   */
  public update(patient: Patient, organisationId: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["patientProfileBody"] = patient.toJSON();
      body[ObjectKeys.organisationId] = organisationId;
      let val = this._http
        .put(urls.environment["patient-profile"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Make connection between patient and organisation
   *
   * @param {string} userId
   * @param {string} organisationId
   * @param {string} connectedById
   * @param {string} connectedByName
   */
  public makeConnection(
    userId: string,
    organisationId: string,
    connectedById: string,
    connectedByName: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["user_id"] = userId;
      body[ObjectKeys.connectedById] = connectedById;
      body[ObjectKeys.connectedByName] = connectedByName;
      body[ObjectKeys.organisationId] = organisationId;
      let val = this._http
        .post(urls.environment["make-connection"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Remove connection between patient and organisation
   *
   * @param {string} userId
   * @param {string} organisationId
   */
  public removeConnection(
    userId: string,
    organisationId: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["user_id"] = userId;
      body[ObjectKeys.organisationId] = organisationId;
      let val = this._http
        .post(urls.environment["remove-from-network"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch patient profile
   * @param {string} userId
   */
  public fetch(userId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["user_id"] = userId;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["patient-profile"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  //5. Validate for id uniqueness
  //Will have to formalize the usecase before completing this function
  public validateUniqueness() {}

  //5. Remove connection
  //Will have to formalize the usecase before completing this function

  //Elastic search for patients
  public patientTextSearch(_queryText, organisationId) {
    return new Promise((resolve, reject) => {
      let patients: Patient[] = [];
      let orgVal = ObjectKeys.organisationConnections + "." + organisationId;
      this.client
        .search({
          index: this.PATIENT_INDEX,
          type: this.TYPE,
          filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
          body: {
            query: {
              bool: {
                must: [
                  {
                    multi_match: {
                      query: _queryText,
                      type: "phrase_prefix",
                      fields: [
                        ObjectKeys.nameKey,
                        ObjectKeys.primaryContactNumber +".number"
                      ]
                    }
                  }
                ],
                filter: {
                  exists: {
                    field: orgVal
                  }
                }
              }
            }
          }
        })
        .then(response => {
          if (response.hits.hits) {
            patients = response.hits.hits.map(u => {
              let thisPatient: Patient = new Patient();
              thisPatient.initFromJSON(u._source);
              return thisPatient;
            });
          }
          resolve(patients);
        })
        .catch(err => {
          console.error(err);
          resolve(patients);
        });
    });
  }

  public patientTextSearchWithoutConnectionFilter(_queryText, organisationId) {
    return new Promise((resolve, reject) => {
      let patients: Patient[] = [];
      let orgVal = ObjectKeys.organisationConnections + "." + organisationId;
      this.client
        .search({
          index: this.PATIENT_INDEX,
          type: this.TYPE,
          filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
          body: {
            query: {
              bool: {
                must: [
                  {
                    multi_match: {
                      query: _queryText,
                      type: "phrase_prefix",
                      fields: [
                        ObjectKeys.nameKey,
                        ObjectKeys.primaryContactNumber + ".number"
                      ]
                    }
                  }
                ]
              }
            }
          }
        })
        .then(response => {
          let connectedMap: Patient[] = [];
          let notConnectedMap: Patient[] = [];
          if (response.hits.hits) {
            patients = response.hits.hits.map(u => {
              let thisPatient: Patient = new Patient();
              thisPatient.initFromJSON(u._source);
              if (thisPatient.organisationConnections.has(organisationId)) {
                connectedMap.push(thisPatient);
              } else {
                notConnectedMap.push(thisPatient);
              }
              return thisPatient;
            });
          }
          let body = {};
          body["all"] = patients;
          body["connected"] = connectedMap;
          body["not-connected"] = notConnectedMap;
          resolve(body);
        })
        .catch(err => {
          let body = {};
          body["all"] = [];
          body["connected"] = [];
          body["not-connected"] = [];
          console.error(err);
          resolve(body);
        });
    });
  }

  /**
   * Create/Update blood group
   * @param patientId
   * @param bloodGroup
   */
  public createOrUpdateBloodGroup(patientId: string, bloodGroup: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.bloodGroup] = bloodGroup;
      let val = this._http
        .post(urls.environment["blood-group"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Delete blood group
   * @param patientId
   */
  public deleteBloodGroup(patientId: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment["blood-group"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create
   * @param patientId
   * @param text
   * @param created_by_id
   * @param drug_allergy_id (optional)
   */
  public createOrUpdateAllergy(
    patientId: string,
    text: string,
    createdById: string,
    allergyId?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      if (text != null) {
        body[ObjectKeys.text] = text;
      }
      if (allergyId != null) {
        body["allergyId"] = allergyId;
      }
      body["createdById"] = createdById;
      let val = this._http.post(urls.environment.allergies, body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  /**
   * Delete
   * @param patientId
   * @param allergyId
   */
  public deleteAllergy(patientId: string, allergyId: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.allergyId] = allergyId;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment["allergies"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public allergyTextSearch(_queryText): any {
    return new Promise((resolve, reject) => {
      if (_queryText === "") {
        resolve([]);
      } else {
        this.client
          .search({
            index: "allergy_text_index",
            type: this.TYPE,
            filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
            body: {
              query: {
                match_phrase_prefix: {
                  name: _queryText
                }
              }
            }
          })
          .then(response => {
            if (response.hits.hits)
              resolve(response.hits.hits.map(a => a._source.name));
            else resolve([]);
          });
      }
    });
  }

  /**
   * Create
   * @param patientId
   * @param text
   * @param created_by_id
   * @param medicalHistoryId (optional)
   */
  public createOrUpdatePastHistory(
    patientId: string,
    text: string,
    createdById: string,
    medicalHistoryId?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      if (text != null) {
        body[ObjectKeys.text] = text;
      }
      if (medicalHistoryId != null) {
        body["medicalHistoryId"] = medicalHistoryId;
      }
      body["createdById"] = createdById;
      let val = this._http.post(urls.environment["past-history"], body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  /**
   * Delete
   * @param patientId
   * @param medicalHistoryId
   */
  public deletePastHistory(patientId: string, medicalHistoryId: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.medicalHistoryId] = medicalHistoryId;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment["past-history"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public pastHistoryTextSearch(_queryText): any {
    return new Promise((resolve, reject) => {
      if (_queryText === "") {
        resolve([]);
      } else {
        this.client
          .search({
            index: "past_history_text_index",
            type: this.TYPE,
            filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
            body: {
              query: {
                match_phrase_prefix: {
                  name: _queryText
                }
              }
            }
          })
          .then(response => {
            if (response.hits.hits)
              resolve(response.hits.hits.map(a => a._source.name));
            else resolve([]);
          });
      }
    });
  }

  /**
   * Create
   * @param patientId
   * @param text
   * @param created_by_id
   * @param id (optional)
   */
  public createOrUpdateHabit(
    patientId: string,
    text: string,
    createdById: string,
    id?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      if (text != null) {
        body[ObjectKeys.text] = text;
      }
      if (id != null) {
        body["id"] = id;
      }
      body["createdById"] = createdById;
      let val = this._http.post(urls.environment.habits, body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  /**
   * Delete
   * @param patientId
   * @param id
   */
  public deleteHabit(patientId: string, id: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.id] = id;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment.habits, options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create
   * @param patientId
   * @param text
   * @param created_by_id
   * @param id (optional)
   */
  public createOrUpdateDentalHistory(
    patientId: string,
    text: string,
    createdById: string,
    id?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      if (text != null) {
        body[ObjectKeys.text] = text;
      }
      if (id != null) {
        body["id"] = id;
      }
      body["createdById"] = createdById;
      let val = this._http.post(urls.environment["dental-history"], body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  /**
   * Delete
   * @param patientId
   * @param id
   */
  public deleteDentalHistory(patientId: string, id: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.id] = id;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment["dental-history"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create
   * @param patientId
   * @param text
   * @param created_by_id
   * @param id (optional)
   */
  public createOrUpdateMedicalHistory(
    patientId: string,
    text: string,
    createdById: string,
    id?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      if (text != null) {
        body[ObjectKeys.text] = text;
      }
      if (id != null) {
        body["id"] = id;
      }
      body["createdById"] = createdById;
      let val = this._http.post(urls.environment["medical-history"], body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  /**
   * Delete
   * @param patientId
   * @param id
   */
  public deleteMedicalHistory(patientId: string, id: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.id] = id;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment["medical-history"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

/**
   * Create
   * @param patientId
   * @param text
   * @param created_by_id
   * @param id (optional)
   */
  public createOrUpdateCurrentMedication(
    patientId: string,
    text: string,
    createdById: string,
    id?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      if (text != null) {
        body[ObjectKeys.text] = text;
      }
      if (id != null) {
        body["id"] = id;
      }
      body["createdById"] = createdById;
      let val = this._http.post(urls.environment["current-medications"], body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  /**
   * Delete
   * @param patientId
   * @param id
   */
  public deleteCurrentMedication(patientId: string, id: string) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.id] = id;
      let options = {}
      options["body"] = body
      let val = this._http
        .delete(urls.environment["current-medications"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }


}
