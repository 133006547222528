import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { Patient } from "../../models/Patient/Patient";
import { PatientProfileService } from "../../services/patient/patient-profile.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { PatientRegistrationModalService } from "../../services/modalStateManagement/patient-registration-modal.service";
// import { SearchService } from "src/app/services/elasticSearch/search.service";
// import { CurrentUserService } from "src/app/services/currentUser/current-user.service";

@Component({
  selector: "app-patient-search",
  templateUrl: "./patient-search.component.html",
  styleUrls: ["./patient-search.component.css"]
})
export class PatientSearchComponent implements OnInit, OnChanges {
  @Input() searchText: string = "";
  @Input() placeHolder: string = "Search...";
  @Input() searchCellType: string; // Mandatory Input - docQueueSearch

  @Output() addToQueue = new EventEmitter<Patient>();
  @Output() addToNetwork = new EventEmitter<Patient>();
  @Output() scheduleVisit = new EventEmitter<Patient>();
  @Output() cellClicked = new EventEmitter<Patient>();

  searchingPatient: boolean;
  patients: Array<Patient> = [];

  @ViewChild("searchTextInputElement") searchTextInputElement: ElementRef;
  constructor(
    private _patientProfileService: PatientProfileService,
    private _patientRegistrationModalService: PatientRegistrationModalService
  ) {}

  ngOnInit() {
    this._patientRegistrationModalService
      .getNewAddedPatient()
      .subscribe((user: Patient) => {
        if (user) {
          if (user.docvitaId) {
            this.onCellClickAction(true, user);
            // console.log("detectign new patient in search component");
            // this.searchText = "";
            // this.patients.slice(0, this.patients.length);
            // this.patients.push(user);
            this._patientRegistrationModalService.clearNewAddedPatient();
          } else {
            setTimeout(()=>{
              this.searchPatient();
            },1000)
          }
        } else {
          setTimeout(()=>{
            this.searchPatient();
          },1000)
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchPatient();
  }
  searchPatient() {
    this.searchingPatient = true;
    this._patientProfileService
      .patientTextSearch(this.searchText, GlobalVariables.getOrganisationId())
      .then(array => {
        this.searchingPatient = false;
        this.patients = [];
        this.patients = <Patient[]>array;
      })
      .catch(err => {
        console.error(err);
      });
  }

  onNewRegisterClicked() {
    this._patientRegistrationModalService.setInitialTextFornewUser(
      this.searchText
    );
    this._patientRegistrationModalService.selectPatientProfileForEditing(
      new Patient()
    );
  }
  requestToConnect(success: boolean, patient: Patient) {
    this.addToNetwork.emit(patient);
  }
  onCellClickAction(success: boolean, patient: Patient) {
    this.searchText = "";
    this.cellClicked.emit(patient);
  }
  requestToAddToQueue(success: boolean, patient: Patient) {
    this.searchText = "";
    this.addToQueue.emit(patient);
  }
  requestToScheduleVisit(success: boolean, patient: Patient) {
    this.searchText = "";
    this.scheduleVisit.emit(patient);
  }
}
