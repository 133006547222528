import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import { isNumber, toInteger, padNumber } from "./date-fr-parser";
@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split("/");
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null, day: null };
      } else if (
        dateParts.length === 2 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1])
      ) {
        return {
          year: toInteger(dateParts[1]),
          month: toInteger(dateParts[0]),
          day: null
        };
      } else if (
        dateParts.length === 3 &&
        isNumber(dateParts[0]) &&
        isNumber(dateParts[1]) &&
        isNumber(dateParts[2])
      ) {
        return {
          year: toInteger(dateParts[2]),
          month: toInteger(dateParts[1]),
          day: toInteger(dateParts[0])
        };
      }
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    let stringDate: string = "";
    if (date) {
      stringDate += isNumber(date.day) ? padNumber(date.day) + "/" : "";
      stringDate += isNumber(date.month) ? padNumber(date.month) + "/" : "";
      stringDate += date.year;
    }
    return stringDate;
  }
}
