import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  AfterViewInit,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { SelectizeConfigs } from "../shared/constants/object-keys";
import { PersonConstants } from "../shared/models/Person";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as moment from "moment";

import {
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../shared/services/datePicker/NgbDateFRParserFormatter";
import { Patient } from "../shared/models/Patient/Patient";
import {
  ContactNumber,
  DateActions,
  OrganisationLocationObject
} from "../shared/models/Demographics";
import { PatientProfileService } from "../shared/services/patient/patient-profile.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { NotifyService } from "../shared/services/notify/notify.service";
import {
  OrganisationMember,
  OPDTypeAndFee
} from "../shared/models/MD - Member";
import { OpdScheduleOperationsService } from "../shared/services/opd/opd-schedule-operations.service";
import { ScheduleAppointmentModalService } from "../shared/services/modalStateManagement/schedule-appointment-modal.service";
import { AppointmentOperationsService } from "../shared/services/opd/appointment-operations.service";
import { Appointment } from "../shared/models/Scheduling/Appointment";
import { VoiceService } from "../shared/services/voice/voice.service";
import { app } from "firebase";
import { Subscription } from "rxjs";
import { PatientRegistrationModalService } from "../shared/services/modalStateManagement/patient-registration-modal.service";

@Component({
  selector: "app-new-appointment",
  templateUrl: "./new-appointment.component.html",
  styleUrls: ["./new-appointment.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ],
  encapsulation: ViewEncapsulation.None
})
export class NewAppointmentComponent
  implements OnInit, AfterViewChecked, AfterViewInit {
  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  datePickerDate: string;
  start_date: any;
  isRefreshingTimeSlots = false;
  isSavingAppointment = false;

  // Variables for Time Picker
  isEnteringCustomTime: boolean = false; // display the time slots

  time = {};
  meridian = true;
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  selectedDoctorId = "";
  location: string = ""; //This is for saving selected location id
  selectedPurposeOfVisitId: string;
  // selectedLocation: OrganisationLocationObject;

  // appointmentSchedulingForm: FormGroup;

  @Input() selectedPatients: Array<Patient> = [];

  voiceAppointmentTime: string;
  voiceAppointmentDate: string;

  // Form Controls
  // patientSearchTextFC = new FormControl();
  // doctorFC = new FormControl(null, [Validators.required]);
  // locationFC = new FormControl(null, [Validators.required]);
  // purposeOfVisitFC = new FormControl();
  // durationOfVisitFC = new FormControl();

  locationObject: OrganisationLocationObject;

  doctors: Map<string, OrganisationMember> = new Map();
  loadingDoctorsList: boolean = false;
  availableDoctorOptions = [];

  selectedSlotLength = 30;

  dateSelected: string;
  timeSlotSelected = {};

  locationOptions = [];

  availableVisitTypes = [];
  opdChargesMap: Map<string, OPDTypeAndFee> = new Map();

  availableDurations = [
    {
      label: "15 min.",
      value: 15
    },
    {
      label: "30 min.",
      value: 30
    },
    {
      label: "1 hour",
      value: 60
    },
    {
      label: "1.5 hour",
      value: 90
    },
    {
      label: "2 hour",
      value: 120
    },
    {
      label: "2.5 hour",
      value: 150
    },
    {
      label: "3 hour",
      value: 180
    }
  ];

  isRescheduling = true;
  oldAppointment: Appointment;

  patientSearchString: string = "";

  pageTitle = "New Appointment";

  timeSlotsToDisplay = [];
  constructor(
    private _appointmentOperationsService: AppointmentOperationsService,
    private _opdScheduleOperationsService: OpdScheduleOperationsService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _notifyService: NotifyService,
    private _voiceService: VoiceService,
    private _patientProfileService: PatientProfileService
  ) {}

  ngOnInit() {
    var self = this;
    // self.appointmentSchedulingForm = new FormGroup({
    //   patientSearchText: self.patientSearchTextFC,
    //   doctor: self.doctorFC,
    //   location: self.locationFC,
    //   purposeOfVisit: self.purposeOfVisitFC,
    //   durationOfVisit: self.durationOfVisitFC
    // });

    // self.appointmentSchedulingForm.valueChanges.subscribe(value => {
    //   // console.log(value);
    //   // console.log(value.patientSearchText); // this is where we'll fire the search
    // });
    this.initializeDate();
    this.loadAvailableLocations();
    this.getDoctorsList();
    this._scheduleAppointmentModalService
      .getSelectedAppointment()
      .subscribe(appt => {
        if (appt && appt.appointmentId) {
          this._scheduleAppointmentModalService.selectedAppointment(null);
          this.oldAppointment = appt;
          this.isRescheduling = true;
          this.pageTitle = "Reschedule Appointment";
        }
      });
    this._scheduleAppointmentModalService.getSelectedDoctor().subscribe(val => {
      if (val != null) {
        this._scheduleAppointmentModalService.selectDoctor(null);
        this.selectDoctor(val.docvitaId);
        this.onDoctorChanged();
      }
    });
    this._scheduleAppointmentModalService.modalOpenedAgain().subscribe(val => {
      if (!val) {
        this.isRescheduling = false;
        this.pageTitle = "New Appointment";
        this.oldAppointment = null;
        // this.selectedPatients = [];
        this.voiceAppointmentDate = null;
        this.voiceAppointmentTime = null;
        this.timeSlotSelected = {};
      } else if (
        val != null &&
        val["type"] == "patientId" &&
        val["patientId"]
      ) {
        this.isRescheduling = false;
        this.pageTitle = "New Appointment";
        this.oldAppointment = null;
        this.selectedPatients = [];
        this.voiceAppointmentDate = null;
        this.voiceAppointmentTime = null;
        this.timeSlotSelected = {};
        this.fetchPatientAndPopulateList(val["patientId"]);
      }
      // console.log("Y2")
      this.initializeDate();
      this.loadAvailableLocations();
      this.getDoctorsList();
      this.timeSlotSelected = {};
      if (this.isRescheduling) {
        this.selectedPatients = [];
        this.selectionsForRescheduling();
      }
    });

    this._voiceService.getAppointmentJSON().subscribe(json => {
      if (json) {
        // console.log("Y1")
        let appointmentDate = json["appointment_date"];
        let appointmentTime = json["appointment_time"];
        let patientName = json["patient_name"];
        if (!patientName || patientName.length == 0) {
          patientName = "";
        }
        setTimeout(time => {
          this.patientSearchString = patientName;
        }, 110);
        if (appointmentDate) {
          let dates = appointmentDate.split("-");
          this.start_date = {
            year: +dates[0],
            month: +dates[1],
            day: +dates[2]
          };
          this.selectThisDate();
          this.voiceAppointmentDate = appointmentDate;
        }
        if (appointmentTime) {
          this.voiceAppointmentTime = appointmentTime;
          this.onTimeSlotClicked({ start_time: appointmentTime });
        }
        this._notifyService.showSuccessMessage(
          "appointment_date: " +
            appointmentDate +
            "\n" +
            "appointmentTime: " +
            appointmentTime
        );
        this._voiceService.clearAppointmentJSON();
      }
    });
    this.currentSelectedPatientObserver();
  }

  private currentSelectedPatientObserver() {
    this._voiceService.getCurrentPatient().subscribe(patient => {
      this.selectedPatients.splice(0, this.selectedPatients.length);
      this.selectedPatients.push(patient);
    });
  }

  private selectionsForRescheduling() {
    if (this.oldAppointment) {
      let dates = this.oldAppointment.date.split("-");
      this.start_date = {
        year: +dates[0],
        month: +dates[1],
        day: +dates[2]
      };
      this.selectThisDate();
      if (this.oldAppointment.timeslot) {
        this.voiceAppointmentDate = this.oldAppointment.date;
        this.voiceAppointmentTime = this.oldAppointment.timeslot.substring(
          0,
          5
        );
      }

      this.selectDoctor(this.oldAppointment.doctorId);
      this.selectLocation(this.oldAppointment.locationId);
      this.fetchPatientAndPopulateList(this.oldAppointment.patientId);
      this.selectPurposeOfVisit(this.oldAppointment.purposeOfVisitId);
      this.onDoctorChanged();
    }
  }

  selectPurposeOfVisit(purposeOfVisitId: string) {
    if (purposeOfVisitId && this.opdChargesMap.has(purposeOfVisitId)) {
      this.selectedPurposeOfVisitId = purposeOfVisitId;
    }
  }

  fetchPatientAndPopulateList(patientId: string) {
    this._patientProfileService
      .fetch(patientId)
      .then(response => {
        let clonedResponse: any = JSON.parse(JSON.stringify(response));
        let patient = new Patient();
        if (clonedResponse.body.success) {
          patient.initFromJSON(clonedResponse.body.user_profile);
          this.selectedPatients.push(patient);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  selectLocation(locationId: string) {
    this.location = locationId;
    this.locationObject = GlobalVariables.getOrganisation().locations.get(
      this.location
    );
  }

  setDefaultCustomTime() {
    this.time = {
      hour: 10,
      minute: 0
    };
    // let hourStringFromTimePicker = this.time["hour"] + "";
    // let minuteStringFromTimePicker = this.time["minute"] + "";

    // if (
    //   hourStringFromTimePicker == null ||
    //   hourStringFromTimePicker.length == 0
    // ) {
    //   this.time["hour"] = 10;
    // }
    // if (
    //   minuteStringFromTimePicker == null ||
    //   minuteStringFromTimePicker.length == 0
    // ) {
    //   this.time["minute"] = 0;
    // }
    this.timeChangedInPicker();
  }

  clearSelectedTime() {
    this.time = {};
    this.timeSlotSelected = {};
    if (!this.timeSlotsToDisplay || this.timeSlotsToDisplay.length == 0) {
      this.onDoctorChanged();
    }
  }

  getSelectedTimeSlotAppointments() {
    if (this.timeSlotSelected && this.timeSlotSelected["appointments"]) {
      return this.timeSlotSelected["appointments"];
    } else {
      return [];
    }
  }

  timeChangedInPicker() {
    // this._notifyService.showSuccessMessage(
    //   "Time Changed",
    //   this.time["hour"] + ":" + this.time["minute"]
    // );
    let hourStringFromTimePicker = this.time["hour"] + "";
    let minuteStringFromTimePicker = this.time["minute"] + "";

    if (
      hourStringFromTimePicker &&
      hourStringFromTimePicker.length > 0 &&
      minuteStringFromTimePicker &&
      minuteStringFromTimePicker.length > 0
    ) {
      if (hourStringFromTimePicker.length == 1) {
        hourStringFromTimePicker = "0" + hourStringFromTimePicker;
      }
      if (minuteStringFromTimePicker.length == 1) {
        minuteStringFromTimePicker = "0" + minuteStringFromTimePicker;
      }
      let startTimeString =
        hourStringFromTimePicker + ":" + minuteStringFromTimePicker;

      let foundIndex = -1;
      let potentialMatch = this.timeSlotsToDisplay.find((element, index) => {
        if (element["start_time"] == startTimeString) {
          foundIndex = index;
          return true;
        }
      });

      if (potentialMatch && foundIndex > -1) {
        this.timeSlotSelected = potentialMatch;
      } else {
        this.timeSlotSelected = {
          start_time:
            hourStringFromTimePicker + ":" + minuteStringFromTimePicker
        };
      }
    } else {
      this.timeSlotSelected = {};
    }
    this.dateSelected = this.datePickerDate;
  }

  onSubmit() {
    var self = this;
    if (this.validateForm()) {
      let appointment = new Appointment();
      if (this.isRescheduling) {
        appointment.appointmentId = this.oldAppointment.appointmentId;
        appointment.patientId = this.oldAppointment.patientId;
        appointment.patientAge = this.oldAppointment.patientAge;
        appointment.patientName = this.oldAppointment.patientName;
        appointment.patientGender = this.oldAppointment.patientGender;
        appointment.patientPrimaryEmail = this.oldAppointment.patientPrimaryEmail;
        appointment.patientPrimaryContactNumber = this.oldAppointment.patientPrimaryContactNumber;
      }
      self.isSavingAppointment = true;
      appointment.organisationId = GlobalVariables.getOrganisationId();
      appointment.organisationName = GlobalVariables.getOrganisationName();
      appointment.doctorId = this.selectedDoctorId;
      appointment.doctorName = this.doctors.get(this.selectedDoctorId).name;
      appointment.date = this.datePickerDate;
      if (this.timeSlotSelected) {
        if (this.selectedSlotLength) {
          let startTime = this.timeSlotSelected["start_time"];
          let startTimeObj = moment(
            this.datePickerDate + " " + startTime,
            "YYYY-MM-DD HH:mm"
          );
          startTimeObj.add(this.selectedSlotLength, "minute");
          let endTime = startTimeObj.format("HH:mm");
          this.timeSlotSelected["end_time"] = endTime;
        }
        appointment.timeslot =
          this.timeSlotSelected["start_time"] +
          "-" +
          this.timeSlotSelected["end_time"];
      }
      appointment.locationId = this.location;
      appointment.locationName = this.locationObject.title;
      if (this.selectedPurposeOfVisitId) {
        appointment.purposeOfVisitId = this.selectedPurposeOfVisitId;
        if (this.opdChargesMap.has(this.selectedPurposeOfVisitId)) {
          appointment.purposeOfVisitTitle = this.opdChargesMap.get(
            this.selectedPurposeOfVisitId
          ).serviceName;
        }
      }
      if (this.isRescheduling) {
        self.isSavingAppointment = true;
        this._appointmentOperationsService
          .reschedule(this.oldAppointment, appointment)
          .then(resp => {
            self.isSavingAppointment = false;
            if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
              this._notifyService.showSuccessMessage(
                "Appointment rescheduled :)"
              );
              this._scheduleAppointmentModalService.close();
            } else {
              this._notifyService.showErrorMessage("Please try again");
            }
          })
          .catch(err => {
            self.isSavingAppointment = false;
            console.error(err);
          });
      } else {
        self.isSavingAppointment = true;
        this._appointmentOperationsService
          .book(appointment, this.selectedPatients)
          .then(resp => {
            self.isSavingAppointment = false;
            if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
              this._notifyService.showSuccessMessage(
                "Appointment confirmed :)"
              );
              this._scheduleAppointmentModalService.close();
            } else {
              this._notifyService.showErrorMessage("Please try again");
            }
          })
          .catch(err => {
            self.isSavingAppointment = false;
            console.error(err);
            this._notifyService.showErrorMessage("Please try again");
          });
      }
    }
  }

  validateForm() {
    //Will implement more conditions later
    if (this.timeSlotSelected && this.timeSlotSelected["start_time"]) {
      return true;
    } else {
      this._notifyService.showWarningMessage("Select a timeslot");
      return false;
    }
  }

  patientSearchTextChanged() {
    console.log("Patient Search Text Changed");
  }

  initializeDate() {
    let today = new Date(Date.now());
    this.datePickerDate = DateActions.getDateString(today);
    this.start_date = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    };
  }

  selectPatient(patient: Patient) {
    this.selectedPatients.push(patient);
  }

  onTimeSlotClicked(timeSlot: any) {
    console.log("Clicked on Time Slot: ", timeSlot);
    this.timeSlotSelected = timeSlot;
    let json = {};
    json["hour"] = +this.timeSlotSelected["start_time"].substring(0, 2);
    json["minute"] = +this.timeSlotSelected["start_time"].substring(3, 5);
    this.time = json;
    this.dateSelected = this.datePickerDate;
  }

  isSelectedTimeSlot(time) {
    return (
      this.timeSlotSelected["start_time"] == time["start_time"] &&
      this.dateSelected == this.datePickerDate
    );
  }

  isTimeSlotSelected() {
    return this.timeSlotSelected["start_time"] != null;
  }
  /**
   * Load locations from organisation obejct
   */
  loadAvailableLocations() {
    console.log(GlobalVariables.organisation);
    GlobalVariables.getOrganisation().locations.forEach((val, key) => {
      this.location = key;
      let json = {};
      json["label"] = val.title;
      json["value"] = key;
      this.locationOptions.push(json);
    });
    if (this.locationOptions.length > 0) {
      this.location = this.locationOptions[0]["value"];
    }
  }

  onLocationChange() {
    this.locationObject = GlobalVariables.getOrganisation().locations.get(
      this.location
    );
    this.onDoctorChanged();
  }

  loadTodayDate() {
    let m = moment(new Date(), "YYYY-MM-DD");
    // m.add("days", -1);
    this.datePickerDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }

  loadPreviousDate() {
    let m = moment(this.datePickerDate, "YYYY-MM-DD");
    m.add("days", -1);
    this.datePickerDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }
  loadNextDate() {
    let m = moment(this.datePickerDate, "YYYY-MM-DD");
    m.add("days", 1);
    this.datePickerDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }

  selectThisDate() {
    this.datePickerDate = DateActions.getDateString(
      new Date(
        this.start_date.year,
        this.start_date.month - 1,
        this.start_date.day
      )
    );
    this.onDoctorChanged();
  }

  /**
   * Available doctor list for selected date and location
   * @param date
   * @param locationId
   */
  getDoctorsList() {
    console.log(
      GlobalVariables.getOpdScheduleAvailableMembersMap(
        GlobalVariables.getOrganisationId()
      ),
      GlobalVariables.getMembersMap()
    );
    this.doctors.clear();
    this.availableDoctorOptions.splice(0, this.doctors.size);
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      let temp = {};
      temp["label"] = val.name;
      temp["value"] = key;
      this.availableDoctorOptions.push(temp);
      this.doctors.set(key, val);
    });
    this.availableDoctorOptions.sort((a, b) => {
      return (a.value + "").localeCompare(b.value + "");
    });
    this.selectDoctor(GlobalVariables.getMemberId());
    this.onDoctorChanged();
  }

  onDoctorChanged() {
    var self = this;
    if (
      this.selectedDoctorId &&
      this.selectedDoctorId.length > 0 &&
      this.datePickerDate &&
      this.location &&
      this.datePickerDate.length > 0 &&
      this.location.length > 0
    ) {
      //Fetch schedule for this doctor
      self.isRefreshingTimeSlots = true;
      this._opdScheduleOperationsService
        .fetchDoctorScheduleSlotsForDateAndLocation(
          this.datePickerDate,
          GlobalVariables.getOrganisationId(),
          this.location,
          this.selectedDoctorId
        )
        .then(resp => {
          if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
            let body = resp["body"];
            if (
              body["fullItem"] &&
              body["fullItem"]["date"] == this.datePickerDate &&
              body["fullItem"]["memberId"] == this.selectedDoctorId &&
              body["fullItem"]["locationId"] == this.location
            ) {
              if (body["fullItem"] && body["fullItem"]["slot_length"]) {
                this.selectedSlotLength = body["fullItem"]["slot_length"];
              }
              if (body["fullItem"] && body["fullItem"]["opdCharges"]) {
                let json = body["fullItem"]["opdCharges"];
                for (let k in json) {
                  let o = new OPDTypeAndFee();
                  o.initFromJSON(json[k]);
                  let temp = {};
                  temp["label"] = o.serviceName;
                  temp["value"] = o.serviceId;
                  this.opdChargesMap.set(o.serviceId, o);
                  this.availableVisitTypes.push(temp);
                  if (
                    this.availableVisitTypes &&
                    this.availableVisitTypes.length > 0 &&
                    this.selectedPurposeOfVisitId
                  ) {
                    this.selectedPurposeOfVisitId = this.availableVisitTypes[0][
                      "value"
                    ];
                  }
                }
              }
              // console.log("bbb", body);
              if (body["fullItem"] && body["fullItem"]["available_slots"]) {
                this.timeSlotsToDisplay = [];
                for (let t of body["fullItem"]["available_slots"]) {
                  this.timeSlotsToDisplay.push(t);
                }
                //Sort time slots here
                this.timeSlotsToDisplay.sort((s1, s2) => {
                  if (s1 && s2 && s1.start_time && s2.start_time) {
                    return s1.start_time.localeCompare(s2.start_time);
                  } else {
                    return 0;
                  }
                });
              } else {
                this.timeSlotsToDisplay = [];
              }
            }
          } else {
            //handle error in fetching
          }
          if (
            this.voiceAppointmentTime &&
            this.voiceAppointmentDate &&
            this.voiceAppointmentDate == this.datePickerDate
          ) {
            this.timeSlotsToDisplay.forEach(val => {
              if (val["start_time"] == this.voiceAppointmentTime) {
                this.onTimeSlotClicked(val);
              }
            });
            // this.voiceAppointmentTime = null;
          }
          self.isRefreshingTimeSlots = false;
        })
        .catch(err => {
          console.error(err);
          self.isRefreshingTimeSlots = false;
        });
    }
  }

  selectDoctor(memberId: string) {
    if (this.availableDoctorOptions.length > 0) {
      for (let a of this.availableDoctorOptions) {
        if (a["value"] == memberId) {
          this.selectedDoctorId = memberId;
        }
      }
    }
  }

  addToNetwork(patient: Patient) {
    this._patientProfileService
      .makeConnection(
        patient.docvitaId,
        GlobalVariables.getOrganisationId(),
        GlobalVariables.getMemberId(),
        GlobalVariables.getMemberName()
      )
      .then(resp => {
        if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
          this._notifyService.showSuccessMessage("Added to the network");
        } else {
          this._notifyService.showErrorMessage("Please try again.");
        }
      })
      .catch(err => {
        console.error(err);
        this._notifyService.showErrorMessage("Please try again.");
      });
  }

  removePatientFromList(status: boolean, patientDocvitaId: string) {
    let foundIndex = -1;
    let potentialMatch = this.selectedPatients.find((element, index) => {
      if (element.docvitaId == patientDocvitaId) {
        foundIndex = index;
        return true;
      }
    });
    if (potentialMatch && foundIndex > -1) {
      this.selectedPatients.splice(foundIndex, 1);
    }
  }

  ngAfterViewChecked() {}

  ngAfterViewInit() {}
}
