// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let baseUrl = "https://india-md-api.docvita.app";
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBjE4gIBfHZB0B5hQbcgY2uMP11DkuvWvo",
    authDomain: "md-docvita-2.firebaseapp.com",
    databaseURL: "https://md-docvita-2.firebaseio.com",
    projectId: "md-docvita-2",
    storageBucket: "md-docvita-2.appspot.com",
    messagingSenderId: "310493134186",
    appId: "1:310493134186:web:3e4987b3dbddd659"
  },
  segmentKey: "g7N4R2jdDpVRXJ3A4XSfHntRyeMF0mAh",
  "elasticSearch":"https://india-md-elasticsearch.docvita.app",
  "patient-profile": baseUrl + "/v1/patient/profile",
  "patient-details": baseUrl + "/v1/patient/details",
  "validate-unique-identifier":
    baseUrl + "/v1/patient/profile/validate-unique-identifier",
  "make-connection": baseUrl + "/v1/patient/profile/make-connection",
  "book-appointment": baseUrl + "/v1/appointment/book",
  "cancel-appointment": baseUrl + "/v1/appointment/cancel",
  "complete-appointment": baseUrl + "/v1/appointment/complete",
  "arrive-appointment": baseUrl + "/v1/appointment/arrive",
  "add-to-queue": baseUrl + "/v1/appointment/add-to-queue",
  "reschedule-appointment": baseUrl + "/v1/appointment/reschedule",
  "member-profile": baseUrl + "/v1/member/profile",
  "list-of-available-doctors":
    baseUrl + "/v1/opd-schedule/list-of-available-doctors",
  "fetch-member-schedule": baseUrl + "/v1/opd-schedule/fetch-member-schedule",
  "fetch-member-slots": baseUrl + "/v1/opd-schedule/fetch-member-slots",
  "weekly-slot": baseUrl + "/v1/opd-schedule/weekly-slot",
  "specific-availability-slot":
    baseUrl + "/v1/opd-schedule/specific-availability-slot",
  "specific-unavailability-slot":
    baseUrl + "/v1/opd-schedule/specific-unavailability-slot",
  prescription: baseUrl + "/v1/prescription",
  "prescription-template": baseUrl + "/v1/prescription-template",
  "patient-prescription": baseUrl + "/v1/prescription/get-all-for-patient",
  "voice-command": baseUrl + "/v1/voice/assistant/endpoint",
  "blood-group": baseUrl + "/v1/patient/profile/blood-group",
  allergies: baseUrl + "/v1/patient/profile/allergies",
  habits: baseUrl + "/v1/patient/profile/habits",
  "dental-history": baseUrl + "/v1/patient/profile/dental-history",
  "medical-history": baseUrl + "/v1/patient/profile/medical-history",
  "current-medications": baseUrl + "/v1/patient/profile/current-medications",
  "past-history": baseUrl + "/v1/patient/profile/past-history",
  "generate-otp": baseUrl + "/v1/auth/generate-otp",
  "resend-otp": baseUrl + "/v1/auth/resend-otp",
  "verify-otp": baseUrl + "/v1/auth/verify-otp",
  vitals: baseUrl + "/v1/vitals",
  "patient-count": baseUrl + "/v1/dashboard/patient-count",
  "follow-up-list": baseUrl + "/v1/dashboard/follow-up-list",
  "date-of-birth-list": baseUrl + "/v1/dashboard/date-of-birth-list",
  "custom-sms": baseUrl + "/v1/patient-communication/custom-sms",
  "medication-auto-search": baseUrl + "/v1/medication-auto-search",
  "generate-otp-signup": baseUrl + "/v1/auth/generate-otp/signup",
  "verify-otp-signup": baseUrl + "/v1/auth/verify-otp/signup",
  "activate-free-plan": baseUrl + "/v1/organisation/activate-free-plan",
  "quick-note": baseUrl + "/v1/patient/profile/quick-note",
  "remove-from-network":baseUrl + "/v1/patient/profile/remove-connection",
  "consent-form-template":baseUrl + "/v1/consent-form/template",
  "fetch-all-consent-form-template":baseUrl + "/v1/consent-form/template/all",
  "consent-form":baseUrl + "/v1/consent-form",
  "instruction":baseUrl + "/v1/instruction",
  "instruction-template":baseUrl + "/v1/instruction/template",
  "fetch-all-instruction-template":baseUrl + "/v1/instruction/template/all",
  "dental-case-history": baseUrl + "/v1/dental/case-history",
  "dental-chart-entry": baseUrl + "/v1/dental/dental-chart-entry",
  "dental-treatment":baseUrl+"/v1/dental/treatment"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
