export class MedicationStrengthUnitConstants {
  public static MG = {
    value: "mg",
    label: "mg"
  };
  public static IU = {
    value: "IU",
    label: "IU"
  };
  public static WV = {
    value: "w/v",
    label: "w/v"
  };
  public static WW = {
    value: "w/w",
    label: "w/w"
  };
  public static MG_ML = {
    value: "mg/mL",
    label: "mg/mL"
  };
  public static MG_5_ML = {
    value: "mg/5mL",
    label: "mg/5mL"
  };
  public static MG_10 = {
    value: "mg/10mL",
    label: "mg/10mL"
  };
  public static MG_15_ML = {
    value: "mg/15mL",
    label: "mg/15mL"
  };
  public static MG_20_ML = {
    value: "mg/20mL",
    label: "mg/20mL"
  };
  public static MG_25_ML = {
    value: "mg/25mL",
    label: "mg/25mL"
  };
  public static MG_30_ML = {
    value: "mg/30mL",
    label: "mg/30mL"
  };
  public static PERCENT = {
    value: "percent",
    label: "%"
  };

  public static ALL = [
    MedicationStrengthUnitConstants.MG,
    MedicationStrengthUnitConstants.IU,
    MedicationStrengthUnitConstants.WV,
    MedicationStrengthUnitConstants.WW,
    MedicationStrengthUnitConstants.MG_ML,
    MedicationStrengthUnitConstants.MG_5_ML,
    MedicationStrengthUnitConstants.MG_10,
    MedicationStrengthUnitConstants.MG_15_ML,
    MedicationStrengthUnitConstants.MG_20_ML,
    MedicationStrengthUnitConstants.MG_25_ML,
    MedicationStrengthUnitConstants.MG_30_ML,
    MedicationStrengthUnitConstants.PERCENT
  ];
}
