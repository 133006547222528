import { DosageTrend } from "./Dosage Trend";
import { DateActions } from "../Demographics";
import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";
import { MedicationConstants } from "./Constants/Medication";
import { MedicationDosageFormConstants } from "./Constants/Dosage Form";
import { MedicationRouteConstants } from "./Constants/Route";
import { MedicationSpecificTimingConstants } from "./Constants/Specific Timing";
import { MedicationDosageUnitConstants } from "./Constants/Dosage Unit";
import { MedicationFrequencyTextConstants } from "./Constants/Frequency";
import { MedicationDurationConstants } from "./Constants/Duration";

export class Medication implements BasicModelInterface {
  private _medicationId: string;

  private _spokenCommands: Array<string> = [];
  private _orderingNumber: number; //  to maintain order in a list of medications

  private _dosageForm: string;
  private _route: string;

  private _composition: string; // includes strength in the string
  private _name: string;
  private _isGeneric: string;
  private _strengthValue: number;
  private _strengthUnit: string;

  private _masterDosageTrend: DosageTrend = new DosageTrend();

  private _isAfterMeals: boolean;
  private _isBeforeMeals: boolean;
  private _isEmptyStomach: boolean;
  private _isBedtime: boolean;

  private _startDate: string;
  private _durationText: string;
  private _durationValue: number;
  private _durationUnit: string;

  private _frequencyText: string;
  private _frequencyValue: string;
  private _frequencyUnit: string;

  private _intakeTimeGapFromFoodValue: number; // millis
  private _intakeTimeGapFromFoodUnit: string;

  private _status: number; // "draft" / "approved"

  private _instructions: string;

  // Variables to control the Edit Medication Form, Frequency Dependent
  private _displayTiming: boolean = true;
  private _displaySpecificTiming: boolean = true;
  private _possibleTimingOptions: {}[] = [];

  // From Auto Search - Elastic
  private _databaseMedicationId: string;
  private _databaseOwner: string;

  constructor() {
    var today = new Date(Date.now());
    this._startDate = DateActions.getDateString(today);
  }

  toJSON(): {} {
    let json = {};
    if (this.medicationId) {
      json[ObjectKeys.medicationId] = this.medicationId;
    }
    if (this.orderingNumber) {
      json[ObjectKeys.orderingNumber] = this.orderingNumber;
    }
    if (this.dosageForm) {
      json[ObjectKeys.dosageForm] = this.dosageForm;
    }
    if (this.route) {
      json[ObjectKeys.route] = this.route;
    }
    if (this.composition) {
      json[ObjectKeys.composition] = this.composition;
    }
    if (this.name) {
      json[ObjectKeys.nameKey] = this.name;
    }
    if (this.isGeneric) {
      json[ObjectKeys.isGeneric] = this.isGeneric;
    }
    if (this.strengthUnit) {
      json[ObjectKeys.strengthUnit] = this.strengthUnit;
    }
    if (this.strengthValue) {
      json[ObjectKeys.strengthValue] = this.strengthValue;
    }
    if (this.masterDosageTrend) {
      json[ObjectKeys.masterDosageTrend] = this.masterDosageTrend.toJSON();
    }
    if (this.isAfterMeals) {
      json[ObjectKeys.isAfterMeals] = this.isAfterMeals;
    }
    if (this.isBeforeMeals) {
      json[ObjectKeys.isBeforeMeals] = this.isBeforeMeals;
    }
    if (this.isBedtime) {
      json[ObjectKeys.isBedtime] = this.isBedtime;
    }
    if (this.isEmptyStomach) {
      json[ObjectKeys.isEmptyStomach] = this.isEmptyStomach;
    }
    if (this.startDate) {
      json[ObjectKeys.startDate] = this.startDate;
    }
    if (this.durationText) {
      json[ObjectKeys.durationText] = this.durationText;
    }
    if (this.durationUnit) {
      json[ObjectKeys.durationUnit] = this.durationUnit;
    }
    if (this.durationValue) {
      json[ObjectKeys.durationValue] = this.durationValue;
    }
    if (this.frequencyText) {
      json[ObjectKeys.frequencyText] = this.frequencyText;
    }
    if (this.frequencyUnit) {
      json[ObjectKeys.frequencyUnit] = this.frequencyUnit;
    }
    if (this.frequencyValue) {
      json[ObjectKeys.frequencyValue] = this.frequencyValue;
    }
    if (this.intakeTimeGapFromFoodUnit) {
      json[
        ObjectKeys.intakeTimeGapFromFoodUnit
      ] = this.intakeTimeGapFromFoodUnit;
    }
    if (this.intakeTimeGapFromFoodValue) {
      json[
        ObjectKeys.intakeTimeGapFromFoodValue
      ] = this.intakeTimeGapFromFoodValue;
    }
    if (this.status) {
      json[ObjectKeys.status] = this.status;
    }
    if (this.instructions) {
      json[ObjectKeys.instructions] = this.instructions;
    }
    if (this.databaseMedicationId) {
      json[ObjectKeys.databaseMedicationId] = this.databaseMedicationId;
    }
    if (this.databaseOwner) {
      json[ObjectKeys.databaseOwner] = this.databaseOwner;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.medicationId]) {
      this.medicationId = json[ObjectKeys.medicationId];
    }
    if (json[ObjectKeys.orderingNumber]) {
      this.orderingNumber = json[ObjectKeys.orderingNumber];
    }
    if (json[ObjectKeys.dosageForm]) {
      this.dosageForm = json[ObjectKeys.dosageForm];
    }
    if (json[ObjectKeys.route]) {
      this.route = json[ObjectKeys.route];
    }
    if (json[ObjectKeys.composition]) {
      this.composition = json[ObjectKeys.composition];
    }
    if (json[ObjectKeys.nameKey]) {
      this.name = json[ObjectKeys.nameKey];
    }
    if (json[ObjectKeys.isGeneric]) {
      this.isGeneric = json[ObjectKeys.isGeneric];
    }
    if (json[ObjectKeys.strengthValue]) {
      this.strengthValue = json[ObjectKeys.strengthValue];
    }
    if (json[ObjectKeys.strengthUnit]) {
      this.strengthUnit = json[ObjectKeys.strengthUnit];
    }
    if (json[ObjectKeys.startDate]) {
      this.startDate = json[ObjectKeys.startDate];
    }
    if (json[ObjectKeys.durationText]) {
      this.durationText = json[ObjectKeys.durationText];
    }
    if (json[ObjectKeys.durationUnit]) {
      this.durationUnit = json[ObjectKeys.durationUnit];
    }
    if (json[ObjectKeys.durationValue]) {
      this.durationValue = json[ObjectKeys.durationValue];
    }
    if (json[ObjectKeys.frequencyText]) {
      this.frequencyText = json[ObjectKeys.frequencyText];
    }
    if (json[ObjectKeys.frequencyUnit]) {
      this.frequencyUnit = json[ObjectKeys.frequencyUnit];
    }
    if (json[ObjectKeys.frequencyValue]) {
      this.frequencyValue = json[ObjectKeys.frequencyValue];
    }
    if (json[ObjectKeys.masterDosageTrend]) {
      let d: DosageTrend = new DosageTrend();
      d.initFromJSON(json[ObjectKeys.masterDosageTrend]);
      this.masterDosageTrend = d;
    }
    if (json[ObjectKeys.isAfterMeals]) {
      this.isAfterMeals = json[ObjectKeys.isAfterMeals];
    }
    if (json[ObjectKeys.isBedtime]) {
      this.isBedtime = json[ObjectKeys.isBedtime];
    }
    if (json[ObjectKeys.isBeforeMeals]) {
      this.isBeforeMeals = json[ObjectKeys.isBeforeMeals];
    }
    if (json[ObjectKeys.isEmptyStomach]) {
      this.isEmptyStomach = json[ObjectKeys.isEmptyStomach];
    }
    if (json[ObjectKeys.intakeTimeGapFromFoodUnit]) {
      this.intakeTimeGapFromFoodUnit =
        json[ObjectKeys.intakeTimeGapFromFoodUnit];
    }
    if (json[ObjectKeys.intakeTimeGapFromFoodValue]) {
      this.intakeTimeGapFromFoodValue =
        json[ObjectKeys.intakeTimeGapFromFoodValue];
    }
    if (json[ObjectKeys.status]) {
      this.status = json[ObjectKeys.status];
    }
    if (json[ObjectKeys.instructions]) {
      this.instructions = json[ObjectKeys.instructions];
    }
    if (json[ObjectKeys.databaseMedicationId]) {
      this.databaseMedicationId = json[ObjectKeys.databaseMedicationId];
    }
    if (json[ObjectKeys.databaseOwner]) {
      this.databaseOwner = json[ObjectKeys.databaseOwner];
    }
  }

  // Medication Functions for display

  getSpecificTimingDisplayText(language?: string) {
    let finalSpecificTimingText = "";
    if (
      this.isAfterMeals ||
      this.isBeforeMeals ||
      this.isEmptyStomach ||
      this.isBedtime
    ) {
      let labelVal = "label";
      if (language) {
        labelVal = "label_" + language;
      }
      finalSpecificTimingText += "(";
      if (this.isEmptyStomach) {
        finalSpecificTimingText +=
          MedicationSpecificTimingConstants.EMPTY_STOMACH[labelVal] + ", ";
      }
      if (this.isBedtime) {
        finalSpecificTimingText +=
          MedicationSpecificTimingConstants.AT_BEDTIME[labelVal] + ", ";
      }
      if (this.isBeforeMeals) {
        finalSpecificTimingText +=
          MedicationSpecificTimingConstants.BEFORE_MEALS[labelVal] + ", ";
      }
      if (this.isAfterMeals) {
        finalSpecificTimingText +=
          MedicationSpecificTimingConstants.AFTER_MEALS[labelVal] + ", ";
      }
      finalSpecificTimingText = finalSpecificTimingText.slice(
        0,
        finalSpecificTimingText.length - 2
      );
      // finalSpecificTimingText.trim();
      finalSpecificTimingText = finalSpecificTimingText + ")";
    } else {
      finalSpecificTimingText = "-";
    }
    return finalSpecificTimingText;
  }

  getDosageDisplayText(language?:string) {
    let potentialUnitLabel="";
    if(this.masterDosageTrend.dosageUnit){
      if (
        language &&
        MedicationDosageUnitConstants.LANGUAGE_LABEL[
          this.masterDosageTrend.dosageUnit
        ] &&
        MedicationDosageUnitConstants.LANGUAGE_LABEL[
          this.masterDosageTrend.dosageUnit
        ][language]
      ) {
        potentialUnitLabel =
          MedicationDosageUnitConstants.LANGUAGE_LABEL[
            this.masterDosageTrend.dosageUnit
          ][language];
      } else {
        potentialUnitLabel = MedicationConstants.DOSAGE_UNITS.find(item => {
          return item.value == this.masterDosageTrend.dosageUnit;
        }).label;
      } 
    }
    let finalDosageText = "";
    if (this.frequencyText && this.frequencyText.length > 0) {
      let displayTimingValue: boolean =
        MedicationConstants.FREQUENCY_TEXT_VALUE_OPTIONS_MAP[this.frequencyText]
          .displayTiming;
      if (!displayTimingValue) {
        if (
          this.masterDosageTrend.dosageVal &&
          this.masterDosageTrend.dosageVal > 0
        ) {
          finalDosageText += this.masterDosageTrend.dosageVal + " ";
        }
        if (
          this.masterDosageTrend.dosageUnit &&
          this.masterDosageTrend.dosageUnit.length > 0
        ) {
          finalDosageText += potentialUnitLabel;
        }
      } else if (
        displayTimingValue &&
        (this.masterDosageTrend.timing1 ||
          this.masterDosageTrend.timing2 ||
          this.masterDosageTrend.timing3)
      ) {
        finalDosageText +=
          this.masterDosageTrend.timing1 > 0
            ? this.masterDosageTrend.timing1 + ""
            : "0";
        finalDosageText += " - ";
        finalDosageText +=
          this.masterDosageTrend.timing2 > 0
            ? this.masterDosageTrend.timing2 + ""
            : "0";
        finalDosageText += " - ";
        finalDosageText +=
          this.masterDosageTrend.timing3 > 0
            ? this.masterDosageTrend.timing3 + ""
            : "0";
      } else if (
        displayTimingValue &&
        (this.masterDosageTrend.dosageVal &&
          this.masterDosageTrend.dosageVal > 0) &&
        (this.masterDosageTrend.dosageUnit &&
          this.masterDosageTrend.dosageUnit.length > 0)
      ) {
        if (
          this.masterDosageTrend.dosageVal &&
          this.masterDosageTrend.dosageVal > 0
        ) {
          finalDosageText += this.masterDosageTrend.dosageVal + " ";
        }
        if (
          this.masterDosageTrend.dosageUnit &&
          this.masterDosageTrend.dosageUnit.length > 0
        ) {
          finalDosageText += potentialUnitLabel;
        }
      } else {
        finalDosageText = "---";
      }
    } else if (
      this.masterDosageTrend != null &&
      (this.masterDosageTrend.dosageVal &&
        this.masterDosageTrend.dosageVal > 0) &&
      (this.masterDosageTrend.dosageUnit &&
        this.masterDosageTrend.dosageUnit.length > 0)
    ) {
      if (
        this.masterDosageTrend.dosageVal &&
        this.masterDosageTrend.dosageVal > 0
      ) {
        finalDosageText += this.masterDosageTrend.dosageVal + " ";
      }
      if (
        this.masterDosageTrend.dosageUnit &&
        this.masterDosageTrend.dosageUnit.length > 0
      ) {
        finalDosageText += potentialUnitLabel;
      }
    } else {
      finalDosageText = "-";
    }
    return finalDosageText;
  }

  getDosageUnitDisplayText(language?: string) {
    let finalDosageUnitText = "";
    if (this.frequencyText && this.frequencyText.length > 0) {
      let displayTimingValue: boolean =
        MedicationConstants.FREQUENCY_TEXT_VALUE_OPTIONS_MAP[this.frequencyText]
          .displayTiming;
      if (
        displayTimingValue &&
        (this.masterDosageTrend.timing1 ||
          this.masterDosageTrend.timing2 ||
          this.masterDosageTrend.timing3) &&
        this.masterDosageTrend.dosageUnit &&
        this.masterDosageTrend.dosageUnit.length > 0
      ) {
        let potentialUnitLabel;
        if (
          language &&
          MedicationDosageUnitConstants.LANGUAGE_LABEL[
            this.masterDosageTrend.dosageUnit
          ] &&
          MedicationDosageUnitConstants.LANGUAGE_LABEL[
            this.masterDosageTrend.dosageUnit
          ][language]
        ) {
          potentialUnitLabel =
            MedicationDosageUnitConstants.LANGUAGE_LABEL[
              this.masterDosageTrend.dosageUnit
            ][language];
        } else {
          potentialUnitLabel = MedicationConstants.DOSAGE_UNITS.find(item => {
            return item.value == this.masterDosageTrend.dosageUnit;
          }).label;
        }
        if (potentialUnitLabel) {
          finalDosageUnitText += "(" + potentialUnitLabel + ")";
        }
      } else {
        finalDosageUnitText = "-";
      }
    } else {
      finalDosageUnitText = "-";
    }
    return finalDosageUnitText;
  }

  static getMedicationFormDisplayText(form: string, language?: string) {
    let potentialMedicationFormLabel;
    if (
      language &&
      MedicationDosageFormConstants.LANGUAGE_LABEL[form] &&
      MedicationDosageFormConstants.LANGUAGE_LABEL[form][language]
    ) {
      potentialMedicationFormLabel =
        MedicationDosageFormConstants.LANGUAGE_LABEL[form][language];
    } else {
      potentialMedicationFormLabel = MedicationConstants.DOSAGE_FORM_TYPES.find(
        unit => {
          return unit.value == form;
        }
      ).label;
    }
    if (
      potentialMedicationFormLabel &&
      potentialMedicationFormLabel.length > 0
    ) {
      return potentialMedicationFormLabel;
    } else {
      return form;
    }
  }

  static getRouteDisplayText(route: string, language?: string) {
    if (route != null && route.length > 0) {
      let potentialRouteLabel;
      if (
        language &&
        MedicationRouteConstants.LANGUAGE_LABEL[route] &&
        MedicationRouteConstants.LANGUAGE_LABEL[route][language]
      ) {
        potentialRouteLabel =
          MedicationRouteConstants.LANGUAGE_LABEL[route][language];
      } else {
        potentialRouteLabel = MedicationConstants.ROUTE_TYPES.find(unit => {
          return unit.value == route;
        }).label;
      }
      if (potentialRouteLabel && potentialRouteLabel.length > 0) {
        return potentialRouteLabel;
      } else {
        return route;
      }
    } else {
      return "";
    }
  }

  getMedicationFormDisplayText() {
    return Medication.getMedicationFormDisplayText(this.dosageForm);
  }

  getRouteDisplayText() {
    return Medication.getRouteDisplayText(this.route);
  }

  getMedicationFormAndRouteDisplayText(language?: string) {
    let finalMedicationFormAndRouteText = "";
    if (
      this.dosageForm &&
      this.dosageForm.length > 0 &&
      this.route &&
      this.route.length > 0
    ) {
      let medicationFormLabel = Medication.getMedicationFormDisplayText(
        this.dosageForm,
        language
      );
      let routeLabel = Medication.getRouteDisplayText(this.route, language);

      finalMedicationFormAndRouteText = medicationFormLabel + ", " + routeLabel;
    } else if (
      this.dosageForm &&
      this.dosageForm.length > 0 &&
      !(this.route && this.route.length > 0)
    ) {
      let medicationFormLabel = Medication.getMedicationFormDisplayText(
        this.dosageForm
      );
      finalMedicationFormAndRouteText = medicationFormLabel;
    } else if (
      !(this.dosageForm && this.dosageForm.length > 0) &&
      (this.route && this.route.length > 0)
    ) {
      let tempVal = Medication.getRouteDisplayText(this.route);
      finalMedicationFormAndRouteText = tempVal;
    } else {
      finalMedicationFormAndRouteText = "-";
    }
    return finalMedicationFormAndRouteText;
  }

  getDurationDisplayText(language?: string) {
    let finalDurationText = "";
    if (
      this.durationValue &&
      this.durationValue > 0 &&
      this.durationUnit &&
      this.durationUnit.length > 0
    ) {
      let potentialDurationUnitLabel;
      if (
        language &&
        MedicationDurationConstants.LANGUAGE_DURATION_UNITS[
          this.durationUnit
        ] &&
        MedicationDurationConstants.LANGUAGE_DURATION_UNITS[this.durationUnit][
          language
        ]
      ) {
        potentialDurationUnitLabel =
          MedicationDurationConstants.LANGUAGE_DURATION_UNITS[
            this.durationUnit
          ][language];
      } else {
        potentialDurationUnitLabel = MedicationConstants.DURATION_UNITS.find(
          unit => {
            return unit.value == this.durationUnit;
          }
        ).label;
      }
      if (potentialDurationUnitLabel) {
        finalDurationText =
          this.durationValue + " " + potentialDurationUnitLabel;
      } else {
        console.log("Some Error with Duration Unit Label");
        finalDurationText += "---";
      }
    } else {
      finalDurationText += "-";
    }
    return finalDurationText;
  }

  // Medication Functions for Form & Other Logics

  getDosageUnitShortLabel() {
    if (
      this.masterDosageTrend.dosageUnit &&
      this.masterDosageTrend.dosageUnit.length > 0
    ) {
      let matchingObject = MedicationConstants.DOSAGE_UNITS.find(searchItem => {
        return searchItem.value == this.masterDosageTrend.dosageUnit;
      });
      if (matchingObject) {
        return matchingObject.shortlabel;
      } else {
        return " ";
      }
    }
  }

  getPossibleRoutes() {
    let isFirstRouteDefault = true;
    let filteredRouteArray: { value: string; label: string }[] = [];
    switch (this.dosageForm) {
      case MedicationDosageFormConstants.TABLET.value:
        filteredRouteArray = [
          MedicationRouteConstants.ORALLY,
          MedicationRouteConstants.SUBLINGUALLY,
          MedicationRouteConstants.BUCCALLY,
          MedicationRouteConstants.RECTALLY,
          MedicationRouteConstants.VAGINALLY
        ];
        break;
      case MedicationDosageFormConstants.CAPSULE.value:
        filteredRouteArray = [
          MedicationRouteConstants.ORALLY,
          MedicationRouteConstants.SUBLINGUALLY,
          MedicationRouteConstants.BUCCALLY,
          MedicationRouteConstants.RECTALLY,
          MedicationRouteConstants.VAGINALLY
        ];
        break;
      case MedicationDosageFormConstants.INJECTION.value:
        filteredRouteArray = [
          MedicationRouteConstants.INTRAVENOUSLY,
          MedicationRouteConstants.INTRAMUSCULARLY,
          MedicationRouteConstants.SUBCUTANEOUSLY,
          MedicationRouteConstants.INTRADERMALLY,
          MedicationRouteConstants.INTRATHECALLY
        ];
        break;
      case MedicationDosageFormConstants.IV_FLUID.value:
        filteredRouteArray = [
          MedicationRouteConstants.INTRAVENOUSLY,
          MedicationRouteConstants.INTRAMUSCULARLY,
          MedicationRouteConstants.SUBCUTANEOUSLY,
          MedicationRouteConstants.INTRADERMALLY,
          MedicationRouteConstants.INTRATHECALLY
        ];
        break;
      case MedicationDosageFormConstants.SYRUP.value:
        filteredRouteArray = [MedicationRouteConstants.ORALLY];
        break;
      case MedicationDosageFormConstants.DRY_SYRUP.value:
        filteredRouteArray = [MedicationRouteConstants.ORALLY];
        break;
      case MedicationDosageFormConstants.SUSPENSION.value:
        filteredRouteArray = [MedicationRouteConstants.ORALLY];
        break;
      case MedicationDosageFormConstants.OINTMENT.value:
        filteredRouteArray = [MedicationRouteConstants.TOPICALLY];
        break;
      case MedicationDosageFormConstants.CREAM.value:
        filteredRouteArray = [MedicationRouteConstants.TOPICALLY];
        break;
      case MedicationDosageFormConstants.INHALER.value:
        filteredRouteArray = [
          MedicationRouteConstants.INHALED,
          MedicationRouteConstants.NOSTRILS
        ];
        break;
      case MedicationDosageFormConstants.BANDAGE.value:
        filteredRouteArray = [MedicationRouteConstants.LOCAL];
        break;
      case MedicationDosageFormConstants.GRANULES.value:
        filteredRouteArray = [MedicationRouteConstants.ORALLY];
        break;
      case MedicationDosageFormConstants.POWDER.value:
        filteredRouteArray = [
          MedicationRouteConstants.ORALLY,
          MedicationRouteConstants.TOPICALLY
        ];
        break;
      case MedicationDosageFormConstants.RESPULES.value:
        filteredRouteArray = [MedicationRouteConstants.NEBULIZER];
        break;
      case MedicationDosageFormConstants.SOLUTION.value:
        isFirstRouteDefault = false;
        filteredRouteArray = [
          MedicationRouteConstants.ORALLY,
          MedicationRouteConstants.INHALED,
          MedicationRouteConstants.NEBULIZER,
          MedicationRouteConstants.GARGLE
        ];
        break;
      case MedicationDosageFormConstants.PASTE.value:
        isFirstRouteDefault = false;
        filteredRouteArray = [MedicationRouteConstants.TOPICALLY];
        break;
      case MedicationDosageFormConstants.DROPS.value:
        isFirstRouteDefault = false;
        filteredRouteArray = [
          MedicationRouteConstants.ORALLY,
          MedicationRouteConstants.NOSTRILS,
          MedicationRouteConstants.IN_BOTH_EYES,
          MedicationRouteConstants.IN_RIGHT_EYE,
          MedicationRouteConstants.IN_LEFT_EYE,
          MedicationRouteConstants.IN_BOTH_EARS,
          MedicationRouteConstants.IN_RIGHT_EAR,
          MedicationRouteConstants.IN_LEFT_EAR
        ];
        break;
      default:
        isFirstRouteDefault = false;
        filteredRouteArray = MedicationConstants.ROUTE_TYPES;
        break;
    }
    return { isFirstRouteDefault, filteredRouteArray };
  }
  setValuesWithDurationShorthand(duration: string) {
    let durationMap = MedicationConstants.DURATION_OPTIONS_MAP[duration];
    if (durationMap && durationMap.value && durationMap.unitValue) {
      this.durationValue = durationMap.value;
      this.durationUnit = durationMap.unitValue;
      return duration;
    } else {
      return "";
    }
  }
  setTimingFromPossibleOptions(i: number) {
    let timing1 = this.possibleTimingOptions[i]["timing1"];
    let timing2 = this.possibleTimingOptions[i]["timing2"];
    let timing3 = this.possibleTimingOptions[i]["timing3"];

    this.setTiming(timing1, timing2, timing3);
  }

  setTiming(timing1: number, timing2: number, timing3: number) {
    let dosageValMultiplier = 1;
    if (
      this.masterDosageTrend.dosageVal &&
      this.masterDosageTrend.dosageVal > 0
    ) {
      dosageValMultiplier = this.masterDosageTrend.dosageVal;
    }
    this.masterDosageTrend.timing1 = timing1 * dosageValMultiplier;
    this.masterDosageTrend.timing2 = timing2 * dosageValMultiplier;
    this.masterDosageTrend.timing3 = timing3 * dosageValMultiplier;
  }

  setSpecificTiming(valString: string) {
    switch (valString) {
      case MedicationSpecificTimingConstants.EMPTY_STOMACH.value:
        this.isEmptyStomach = !this.isEmptyStomach;
        break;
      case MedicationSpecificTimingConstants.AT_BEDTIME.value:
        this.isBedtime = !this.isBedtime;
        break;
      case MedicationSpecificTimingConstants.BEFORE_MEALS.value:
        this.isBeforeMeals = !this.isBeforeMeals;
        break;
      case MedicationSpecificTimingConstants.AFTER_MEALS.value:
        this.isAfterMeals = !this.isAfterMeals;
        break;
    }
  }
  // Resetters
  resetDurationSelection() {
    this.durationValue = undefined;
    this.durationUnit = "daily";
    // this.durationInMillis = undefined;
  }
  resetSpecificTimingSelection() {
    this.isEmptyStomach = false;
    this.isBedtime = false;
    this.isAfterMeals = false;
    this.isBeforeMeals = false;
  }
  resetFrequencySelection() {
    this.frequencyValue = undefined;

    // this.frequencyType = undefined;
  }
  resetTimingDosages() {
    this.masterDosageTrend.timing1 = undefined;
    this.masterDosageTrend.timing2 = undefined;
    this.masterDosageTrend.timing3 = undefined;
  }
  resetFrequencyDependentUIVariables() {
    this.displayTiming = true;
    this.displaySpecificTiming = true;
    this.possibleTimingOptions = [];
    this.resetSpecificTimingSelection();
  }
  // Set + Get
  setDefaultDoseValueAndGetFilteredDoseUnits() {
    // let defaultDoseValue: number;
    let filteredDoseUnitArray = [];
    switch (this.dosageForm) {
      case MedicationDosageFormConstants.TABLET.value:
        this.masterDosageTrend.dosageVal = 1;
        filteredDoseUnitArray = [MedicationDosageUnitConstants.TABLET];
        break;
      case MedicationDosageFormConstants.CAPSULE.value:
        filteredDoseUnitArray = [MedicationDosageUnitConstants.CAPSULE];
        this.masterDosageTrend.dosageVal = 1;
        break;
      case MedicationDosageFormConstants.INJECTION.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.CC,
          MedicationDosageUnitConstants.UNIT,
          MedicationDosageUnitConstants.AMPULE,
          MedicationDosageUnitConstants.G
        ];
        this.masterDosageTrend.dosageVal = undefined;
        break;
      case MedicationDosageFormConstants.IV_FLUID.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.CC,
          MedicationDosageUnitConstants.UNIT,
          MedicationDosageUnitConstants.AMPULE,
          MedicationDosageUnitConstants.G
        ];
        this.masterDosageTrend.dosageVal = undefined;
        break;
      case MedicationDosageFormConstants.SYRUP.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.CAP,
          MedicationDosageUnitConstants.TEA_SPOON,
          MedicationDosageUnitConstants.TABLE_SPOON
        ];
        this.masterDosageTrend.dosageVal = 5;
        break;
      case MedicationDosageFormConstants.DRY_SYRUP.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.CAP,
          MedicationDosageUnitConstants.TEA_SPOON,
          MedicationDosageUnitConstants.TABLE_SPOON
        ];
        break;
      case MedicationDosageFormConstants.SUSPENSION.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.CAP,
          MedicationDosageUnitConstants.TEA_SPOON,
          MedicationDosageUnitConstants.TABLE_SPOON
        ];
        break;
      // Ointment/Cream Dose Value & Unit doesn't exist
      case MedicationDosageFormConstants.INHALER.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.PUFF,
          MedicationDosageUnitConstants.ACTUATION
        ];
        this.masterDosageTrend.dosageVal = 1;
        break;
      case MedicationDosageFormConstants.BANDAGE.value:
        filteredDoseUnitArray = [MedicationDosageUnitConstants.BANDAGE];
        this.masterDosageTrend.dosageVal = 1;
        break;
      case MedicationDosageFormConstants.GRANULES.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.SACHET,
          MedicationDosageUnitConstants.PACK
        ];
        this.masterDosageTrend.dosageVal = 1;
        break;
      case MedicationDosageFormConstants.POWDER.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.SACHET,
          MedicationDosageUnitConstants.PACK
        ];
        this.masterDosageTrend.dosageVal = 1;
        break;
      case MedicationDosageFormConstants.RESPULES.value:
        filteredDoseUnitArray = [MedicationDosageUnitConstants.AMPULE];
        this.masterDosageTrend.dosageVal = 1;
        break;
      case MedicationDosageFormConstants.SOLUTION.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.CC
        ];
        this.masterDosageTrend.dosageVal = undefined;
        break;
      case MedicationDosageFormConstants.PASTE.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.PACK,
          MedicationDosageUnitConstants.UNIT
        ];
        this.masterDosageTrend.dosageVal = undefined;
        break;
      case MedicationDosageFormConstants.DROPS.value:
        filteredDoseUnitArray = [
          MedicationDosageUnitConstants.ML,
          MedicationDosageUnitConstants.DROPS
        ];
        this.masterDosageTrend.dosageVal = 1;
        break;
      default:
        filteredDoseUnitArray = MedicationDosageUnitConstants.ALL;
        this.masterDosageTrend.dosageVal = undefined;
        break;
    }
    return filteredDoseUnitArray;
  }

  // Boolean Checks

  durationIsNotEmpty() {
    return (
      this.durationValue &&
      this.durationUnit &&
      this.durationUnit.length > 0 &&
      this.durationValue > 0
    );
  }

  anyTimingIsSelected() {
    return (
      this.masterDosageTrend.timing1 ||
      this.masterDosageTrend.timing2 ||
      this.masterDosageTrend.timing3
    );
  }

  dosageFormIsNotEmpty() {
    return this.dosageForm && this.dosageForm.length > 0;
  }

  dosageUnitIsNotEmpty() {
    return (
      this.masterDosageTrend.dosageUnit &&
      this.masterDosageTrend.dosageUnit.length > 0
    );
  }

  nameCompositionIsNotEmpty() {
    //This is to check if medication has appropriate name or not
    return (
      (this.composition && this.composition.length > 0) ||
      (this.name && this.name.length > 0)
    );
  }

  frequencyTextIsNotEmpty() {
    return this.frequencyText && this.frequencyText.length > 0;
  }

  possibleTimingOptionsIsNotEmpty() {
    return this.possibleTimingOptions && this.possibleTimingOptions.length > 0;
  }

  isDosageUnitInputRequired() {
    if (this.dosageFormIsNotEmpty) {
      switch (this.dosageForm) {
        case MedicationDosageFormConstants.TABLET.value:
          return false;
          break;
        case MedicationDosageFormConstants.CAPSULE.value:
          return false;
          break;
        // case MedicationDosageFormConstants.OINTMENT.value:
        //   return false;
        //   break;
        // case MedicationDosageFormConstants.CREAM.value:
        //   return false;
        //   break;
        default:
          return true;
      }
    } else {
      return false;
    }
  }

  getFrequencyTextForDisplay(language?: string) {
    let val = this.frequencyText;
    if (
      language != null &&
      MedicationFrequencyTextConstants.LANGUAGE_MASTER_MAP[
        this.frequencyText
      ] &&
      MedicationFrequencyTextConstants.LANGUAGE_MASTER_MAP[this.frequencyText][
        language
      ]
    ) {
      val =
        MedicationFrequencyTextConstants.LANGUAGE_MASTER_MAP[
          this.frequencyText
        ][language];
    }
    return val;
  }

  /**
   * Getter medicationId
   * @return {string}
   */
  public get medicationId(): string {
    return this._medicationId;
  }

  /**
   * Getter spokenCommands
   * @return {Array<string> }
   */
  public get spokenCommands(): Array<string> {
    return this._spokenCommands;
  }

  /**
   * Getter orderingNumber
   * @return {number}
   */
  public get orderingNumber(): number {
    return this._orderingNumber;
  }

  /**
   * Getter dosageForm
   * @return {string}
   */
  public get dosageForm(): string {
    return this._dosageForm;
  }

  /**
   * Getter route
   * @return {string}
   */
  public get route(): string {
    return this._route;
  }

  /**
   * Getter composition
   * @return {string}
   */
  public get composition(): string {
    return this._composition;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter isGeneric
   * @return {string}
   */
  public get isGeneric(): string {
    return this._isGeneric;
  }

  /**
   * Getter strengthValue
   * @return {number}
   */
  public get strengthValue(): number {
    return this._strengthValue;
  }

  /**
   * Getter strengthUnit
   * @return {string}
   */
  public get strengthUnit(): string {
    return this._strengthUnit;
  }

  /**
   * Getter masterDosageTrend
   * @return {DosageTrend}
   */
  public get masterDosageTrend(): DosageTrend {
    return this._masterDosageTrend;
  }

  /**
   * Getter isAfterMeals
   * @return {boolean}
   */
  public get isAfterMeals(): boolean {
    return this._isAfterMeals;
  }

  /**
   * Getter isBeforeMeals
   * @return {boolean}
   */
  public get isBeforeMeals(): boolean {
    return this._isBeforeMeals;
  }

  /**
   * Getter isEmptyStomach
   * @return {boolean}
   */
  public get isEmptyStomach(): boolean {
    return this._isEmptyStomach;
  }

  /**
   * Getter isBedtime
   * @return {boolean}
   */
  public get isBedtime(): boolean {
    return this._isBedtime;
  }

  /**
   * Getter startDate
   * @return {string}
   */
  public get startDate(): string {
    return this._startDate;
  }

  /**
   * Getter durationText
   * @return {string}
   */
  public get durationText(): string {
    return this._durationText;
  }

  /**
   * Getter durationValue
   * @return {number}
   */
  public get durationValue(): number {
    return this._durationValue;
  }

  /**
   * Getter durationUnit
   * @return {string}
   */
  public get durationUnit(): string {
    return this._durationUnit;
  }

  /**
   * Getter frequencyText
   * @return {string}
   */
  public get frequencyText(): string {
    return this._frequencyText;
  }

  /**
   * Getter frequencyValue
   * @return {string}
   */
  public get frequencyValue(): string {
    return this._frequencyValue;
  }

  /**
   * Getter frequencyUnit
   * @return {string}
   */
  public get frequencyUnit(): string {
    return this._frequencyUnit;
  }

  /**
   * Getter intakeTimeGapFromFoodValue
   * @return {number}
   */
  public get intakeTimeGapFromFoodValue(): number {
    return this._intakeTimeGapFromFoodValue;
  }

  /**
   * Getter intakeTimeGapFromFoodUnit
   * @return {string}
   */
  public get intakeTimeGapFromFoodUnit(): string {
    return this._intakeTimeGapFromFoodUnit;
  }

  /**
   * Getter status
   * @return {number}
   */
  public get status(): number {
    return this._status;
  }

  /**
   * Getter instructions
   * @return {string}
   */
  public get instructions(): string {
    return this._instructions;
  }

  /**
   * Getter displayTiming
   * @return {boolean }
   */
  public get displayTiming(): boolean {
    return this._displayTiming;
  }

  /**
   * Getter displaySpecificTiming
   * @return {boolean }
   */
  public get displaySpecificTiming(): boolean {
    return this._displaySpecificTiming;
  }

  /**
   * Getter possibleTimingOptions
   * @return {{}[] }
   */
  public get possibleTimingOptions(): {}[] {
    return this._possibleTimingOptions;
  }

  /**
   * Getter databaseMedicationId
   * @return {string}
   */
  public get databaseMedicationId(): string {
    return this._databaseMedicationId;
  }

  /**
   * Getter databaseOwner
   * @return {string}
   */
  public get databaseOwner(): string {
    return this._databaseOwner;
  }

  /**
   * Setter medicationId
   * @param {string} value
   */
  public set medicationId(value: string) {
    this._medicationId = value;
  }

  /**
   * Setter spokenCommands
   * @param {Array<string> } value
   */
  public set spokenCommands(value: Array<string>) {
    this._spokenCommands = value;
  }

  /**
   * Setter orderingNumber
   * @param {number} value
   */
  public set orderingNumber(value: number) {
    this._orderingNumber = value;
  }

  /**
   * Setter dosageForm
   * @param {string} value
   */
  public set dosageForm(value: string) {
    this._dosageForm = value;
  }

  /**
   * Setter route
   * @param {string} value
   */
  public set route(value: string) {
    this._route = value;
  }

  /**
   * Setter composition
   * @param {string} value
   */
  public set composition(value: string) {
    this._composition = value;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter isGeneric
   * @param {string} value
   */
  public set isGeneric(value: string) {
    this._isGeneric = value;
  }

  /**
   * Setter strengthValue
   * @param {number} value
   */
  public set strengthValue(value: number) {
    this._strengthValue = value;
  }

  /**
   * Setter strengthUnit
   * @param {string} value
   */
  public set strengthUnit(value: string) {
    this._strengthUnit = value;
  }

  /**
   * Setter masterDosageTrend
   * @param {DosageTrend} value
   */
  public set masterDosageTrend(value: DosageTrend) {
    this._masterDosageTrend = value;
  }

  /**
   * Setter isAfterMeals
   * @param {boolean} value
   */
  public set isAfterMeals(value: boolean) {
    this._isAfterMeals = value;
  }

  /**
   * Setter isBeforeMeals
   * @param {boolean} value
   */
  public set isBeforeMeals(value: boolean) {
    this._isBeforeMeals = value;
  }

  /**
   * Setter isEmptyStomach
   * @param {boolean} value
   */
  public set isEmptyStomach(value: boolean) {
    this._isEmptyStomach = value;
  }

  /**
   * Setter isBedtime
   * @param {boolean} value
   */
  public set isBedtime(value: boolean) {
    this._isBedtime = value;
  }

  /**
   * Setter startDate
   * @param {string} value
   */
  public set startDate(value: string) {
    this._startDate = value;
  }

  /**
   * Setter durationText
   * @param {string} value
   */
  public set durationText(value: string) {
    this._durationText = value;
  }

  /**
   * Setter durationValue
   * @param {number} value
   */
  public set durationValue(value: number) {
    this._durationValue = value;
  }

  /**
   * Setter durationUnit
   * @param {string} value
   */
  public set durationUnit(value: string) {
    this._durationUnit = value;
  }

  /**
   * Setter frequencyText
   * @param {string} value
   */
  public set frequencyText(value: string) {
    this._frequencyText = value;
    this.resetFrequencyDependentUIVariables();
    if (this._frequencyText && this._frequencyText.length > 0) {
      let frequencyDefaultMap =
        MedicationConstants.FREQUENCY_TEXT_VALUE_OPTIONS_MAP[
          this.frequencyText
        ];
      if (frequencyDefaultMap) {
        this.frequencyValue = frequencyDefaultMap.value
          ? frequencyDefaultMap.value
          : undefined;
        this.frequencyUnit = frequencyDefaultMap.type
          ? frequencyDefaultMap.type
          : undefined;
        this.displayTiming = frequencyDefaultMap.displayTiming;
        this.displaySpecificTiming = frequencyDefaultMap.displaySpecificTiming;
        this.possibleTimingOptions = frequencyDefaultMap.possibleTimingOptions
          ? frequencyDefaultMap.possibleTimingOptions
          : [];
        if (frequencyDefaultMap.selectedTimingOption) {
          this.setTiming(
            frequencyDefaultMap.selectedTimingOption.timing1,
            frequencyDefaultMap.selectedTimingOption.timing2,
            frequencyDefaultMap.selectedTimingOption.timing3
          );
        } else {
          this.resetTimingDosages();
        }
      } else {
        // Something new in frequency map that we do not recognise
        console.log("Got something new in frequency map");
      }
    } else if (this._frequencyText && this._frequencyText.length == 0) {
      this.resetFrequencySelection();
      this.resetTimingDosages();
    } else {
      this.resetFrequencySelection();
      this.resetTimingDosages();
    }
  }

  /**
   * Setter frequencyValue
   * @param {string} value
   */
  public set frequencyValue(value: string) {
    this._frequencyValue = value;
  }

  /**
   * Setter frequencyUnit
   * @param {string} value
   */
  public set frequencyUnit(value: string) {
    this._frequencyUnit = value;
  }

  /**
   * Setter intakeTimeGapFromFoodValue
   * @param {number} value
   */
  public set intakeTimeGapFromFoodValue(value: number) {
    this._intakeTimeGapFromFoodValue = value;
  }

  /**
   * Setter intakeTimeGapFromFoodUnit
   * @param {string} value
   */
  public set intakeTimeGapFromFoodUnit(value: string) {
    this._intakeTimeGapFromFoodUnit = value;
  }

  /**
   * Setter status
   * @param {number} value
   */
  public set status(value: number) {
    this._status = value;
  }

  /**
   * Setter instructions
   * @param {string} value
   */
  public set instructions(value: string) {
    this._instructions = value;
  }

  /**
   * Setter displayTiming
   * @param {boolean } value
   */
  public set displayTiming(value: boolean) {
    this._displayTiming = value;
  }

  /**
   * Setter displaySpecificTiming
   * @param {boolean } value
   */
  public set displaySpecificTiming(value: boolean) {
    this._displaySpecificTiming = value;
  }

  /**
   * Setter possibleTimingOptions
   * @param {{}[] } value
   */
  public set possibleTimingOptions(value: {}[]) {
    this._possibleTimingOptions = value;
  }

  /**
   * Setter databaseMedicationId
   * @param {string} value
   */
  public set databaseMedicationId(value: string) {
    this._databaseMedicationId = value;
  }

  /**
   * Setter databaseOwner
   * @param {string} value
   */
  public set databaseOwner(value: string) {
    this._databaseOwner = value;
  }

  public get nameForDisplay(): string {
    // return this._name || this._composition;
    let finalNameForDisplay = "";
    if (this._name && this._name.length > 0) {
      finalNameForDisplay += this._name;
    }
    if (this._composition && this._composition.length > 0) {
      if (this._name && this._name.length > 0) {
        finalNameForDisplay += "\n" + "(";
      }
      finalNameForDisplay += this._composition;
      if (this._name && this._name.length > 0) {
        finalNameForDisplay += ")";
      }
    }
    return finalNameForDisplay;
  }
}
