export class MedicationDurationConstants {
  public static UNITS = [
    {
      label: "hours",
      value: "hourly",
      numberVal: 60 * 60 * 1000
    },
    {
      label: "days",
      value: "daily",
      numberVal: 24 * 60 * 60 * 1000
    },
    {
      label: "weeks",
      value: "weekly",
      numberVal: 7 * 24 * 60 * 60 * 1000
    },
    {
      label: "months",
      value: "monthly",
      numberVal: 30 * 24 * 60 * 60 * 1000
    },
    {
      label: "years",
      value: "yearly",
      numberVal: 365 * 24 * 60 * 60 * 1000
    }
  ];
  public static UNITS_SINGULAR = [
    {
      label: "hour",
      value: "hourly",
      numberVal: 60 * 60 * 1000
    },
    {
      label: "day",
      value: "daily",
      numberVal: 24 * 60 * 60 * 1000
    },
    {
      label: "week",
      value: "weekly",
      numberVal: 7 * 24 * 60 * 60 * 1000
    },
    {
      label: "month",
      value: "monthly",
      numberVal: 30 * 24 * 60 * 60 * 1000
    },
    {
      label: "year",
      value: "yearly",
      numberVal: 365 * 24 * 60 * 60 * 1000
    }
  ];

  public static LANGUAGE_DURATION_UNITS = {
    "hourly":{
      hi:"घंटे"
    },
    "daily":{
      hi:"दिन"
    },
    "weekly":{
      hi:"हफ्ते"
    },
    "monthly":{
      hi:"महीने"
    },
    "yearly":{
      hi:"साल"
    }
  };

  public static MASTER_MAP = {
    "1 day": {
      value: 1,
      unitValue: "daily",
      milis: 1 * MedicationDurationConstants.UNITS[0].numberVal
    },
    "3 days": {
      value: 3,
      unitValue: "daily",
      milis: 3 * MedicationDurationConstants.UNITS[0].numberVal
    },
    // "5 days": {
    //   value: 5,
    //   unitValue: "daily",
    //   milis: 5 * MedicationDurationConstants.UNITS[0].numberVal
    // },
    "1 week": {
      value: 1,
      unitValue: "weekly",
      milis: 1 * MedicationDurationConstants.UNITS[1].numberVal
    }
    // "2 weeks": {
    //   value: 2,
    //   unitValue: "weekly",
    //   milis: 2 * MedicationDurationConstants.UNITS[1].numberVal
    // },
    // "4 weeks": {
    //   value: 4,
    //   unitValue: "weekly",
    //   milis: 4 * MedicationDurationConstants.UNITS[1].numberVal
    // },
    // "3 month": {
    //   value: 3,
    //   unitValue: "monthly",
    //   milis: 3 * MedicationDurationConstants.UNITS[2].numberVal
    // },
    // "6 month": {
    //   value: 6,
    //   unitValue: "monthly",
    //   milis: 6 * MedicationDurationConstants.UNITS[2].numberVal
    // },
    // "1 year": {
    //   value: 1,
    //   unitValue: "yearly",
    //   milis: 1 * MedicationDurationConstants.UNITS[3].numberVal
    // }
  };
}
