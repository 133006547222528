import { Injectable } from "@angular/core";
import * as firebase from "firebase";

@Injectable({
  providedIn: "root"
})
export class GetTokenService {
  constructor() {}

  public token() {
    return new Promise((resolve, reject) => {
      let unsubscribe = firebase.auth().onAuthStateChanged(function(user) {
        unsubscribe();
        if(user){
          user
          .getIdToken(false)
          .then(idToken => {
            resolve(idToken);
          })
          .catch(function(error) {
            console.error(error);
            resolve(null);
          });
        }else{
          resolve("");
        }
      });
    });
  }
}
