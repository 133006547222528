import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as urls from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _http: HttpClient) { }

   /**
   * Fetch patient count
   * @param {string} organisationId
   */
  public fetchPatientCount(organisationId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["patient-count"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch patient follow ups
   * @param {string} organisationId
   * @param {string} date
   */
  public fetchFollowUps(organisationId: string, date:string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      params["followUpDate"] = date;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["follow-up-list"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Fetch patient list whose birthdays are on this date
   * @param {string} organisationId
   * @param {string} date
   */
  public fetchBirthdayPatientList(organisationId: string, date:string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["organisationId"] = organisationId;
      params["date"] = date;
      options["params"] = params;
      let val = this._http
        .get(urls.environment["date-of-birth-list"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public sendCustomSMS = (message:string,organisationId:string, contactNumber:string)=>{
    return new Promise((resolve, reject) => {
      let body = {};
      body["organisationId"] = organisationId;
      body["message"] = message;
      body["contactNumber"] = contactNumber;
      let val = this._http
        .post(urls.environment["custom-sms"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}
