import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injectable
} from "@angular/core";
// import { AppointmentObject } from "../../../models/AppointmentObject";
// import { AppointmentActionsService } from "src/app/services/appointments/appointment-actions.service";
// import { NotifyService } from "src/app/services/notify/notify.service";
// import { QueueObject } from "src/app/models/QueueObject";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Patient } from "../../models/Patient/Patient";
import { Appointment } from "../../models/Scheduling/Appointment";
import { AppointmentStatus } from "../../constants/object-keys";
import { AppointmentOperationsService } from "../../services/opd/appointment-operations.service";
import { NotifyService } from "../../services/notify/notify.service";
import {
  VoiceService,
  VoiceAssistantStates
} from "../../services/voice/voice.service";
import { PatientProfileService } from "../../services/patient/patient-profile.service";
// import { FrontdeskService } from "src/app/services/frontdesk/frontdesk.service";
// import { UserObject } from "src/app/models/UserObject";
// import * as keys from '../../../keys';

@Component({
  selector: "app-patient-reusable-cell",
  templateUrl: "./patient-reusable-cell.component.html",
  styleUrls: ["./patient-reusable-cell.component.css"]
})
export class PatientReusableCellComponent implements OnInit {
  @Input() reusableObject: any;
  @Input() cellType: string; // "appointment, queue, patient, docQueueSearch, regSearchConnected, regSearchNotConnected", "patientList"
  @Output()
  patientEncounterFinished = new EventEmitter<boolean>();
  @Output() requestToRecordVitals = new EventEmitter<boolean>();
  @Output() requestToGoToProfile = new EventEmitter<boolean>();

  // For DocQueue Search
  @Output() requestToAddToQueue = new EventEmitter<boolean>();
  @Output() requestToScheduleVisit = new EventEmitter<boolean>();
  @Output() requestToCancelEncounter = new EventEmitter<boolean>();
  @Output() requestToRescheduleEncounter = new EventEmitter<boolean>();
  @Output() requestToRemovePatientFromList = new EventEmitter<boolean>();

  @Output() onCellClickAction = new EventEmitter<boolean>();
  loaderDisplay: boolean = false;

  @Output() requestToConnectAction = new EventEmitter<boolean>();

  // Display Variables, 2-way bindings
  patientName: string;
  patientShortBio: string;
  cellLine3: string;

  // Boolean Variables, to control the visibility of actions
  connectAction: boolean = false;
  goToProfileAction: boolean = false;
  addToQueueAction: boolean = false;
  scheduleVisitAction: boolean = false;
  finishPatientEncounterAction: boolean = false;
  recordVitalsAction: boolean = false;
  cancelEncounterAction: boolean = false;
  rescheduleEncounterAction: boolean = false;
  removePatientFromListAction: boolean = false;
  voiceAssistantInvokeAction: boolean = true;

  mouseIsInsideTheCell: boolean = false;

  // For Patient Reg. Search in Connected Patients - Open their profile, add to queue, schedule visit
  // For Patient Reg. Search for Not Connected Patients - Add them to our network. :)

  // Constructor
  constructor(
    private appointmentOperationsService: AppointmentOperationsService,
    private _patientProfileService: PatientProfileService,
    private _voiceService: VoiceService,
    private notifyService: NotifyService // public ngbModal: NgbModal, // public frontdeskService: FrontdeskService
  ) {}

  ngOnInit() {
    if (this.isAppointmentType()) this.setupCellForAppointment();
    else if (this.isQueueType()) this.setupCellForQueue();
    else if (this.isPatientType()) this.setupCellForPatient();
    else if (this.isPatientType()) this.setupCellForPatient();
    else if (this.isDocQueueSearchType()) this.setupCellForDocQueueSearch();
    else if (this.isRegSearchConnected()) this.setupCellForRegSearchConnected();
    else if (this.isRegSearchNotConnected())
      this.setupCellForRegSearchNotConnected();
    else if (this.isPatientList()) this.setupCellForPatientList();
  }

  cellClickAction() {
    this.onCellClickAction.emit(true);
  }

  clickOnReqestToConnectButton() {}

  clickOnGoToProfileButton() {
    // Navigate to the patient profile
    this.requestToGoToProfile.emit(true);
  }

  clickOnQueueActionButton() {
    if (this.isAppointmentType()) {
      this.moveToQueue();
    } else if (this.isDocQueueSearchType()) {
      this.requestToAddToQueue.emit(true);
    }
  }

  clickOnScheduleVisit() {
    this.requestToScheduleVisit.emit(true);
  }

  clickOnCancelPatientEncounter() {
    this.requestToCancelEncounter.emit(true);
  }
  clickOnReschedulePatientEncounter() {
    this.requestToRescheduleEncounter.emit(true);
  }
  clickOnRequestToRemovePatientFromList() {
    this.requestToRemovePatientFromList.emit(true);
  }

  moveToQueue() {
    let appointmentObject: Appointment = <Appointment>this.reusableObject;
    if (appointmentObject) {
      this.loaderDisplay = true;
      this.appointmentOperationsService
        .arrive(appointmentObject)
        .then(response => {
          // console.log(response);
          let clonedResponse: any = JSON.parse(JSON.stringify(response));
          this.loaderDisplay = false;
          if (clonedResponse.body.success) {
            this.notifyService.showSuccessMessage(
              appointmentObject.patientName,
              "Added to queue"
            );
          } else {
            this.notifyService.showSuccessMessage(
              "",
              "Experiencing connectivity issues with the server"
            );
          }
        });
    } else {
      this.notifyService.showErrorMessage(
        "",
        "Couldn't find appointment. Contact Support Team with Error Code 114"
      );
    }
  }

  qualifiesForGreenCell(): boolean {
    if (this.isAppointmentType()) {
      return this.isQueueCompleted();
    } else {
      return false;
    }
  }
  setupCellForPatient() {
    let patient: Patient = <Patient>this.reusableObject;
    if (patient) {
      this.patientName = patient.name;
      this.patientShortBio = patient.getShortBio();
      this.scheduleVisitAction = true;
    }
  }

  setupCellForPatientList() {
    let patient: Patient = <Patient>this.reusableObject;
    if (patient) {
      this.patientName = patient.name;
      this.patientShortBio = patient.getShortBio();
      this.removePatientFromListAction = true;
      this.voiceAssistantInvokeAction = false; // hide the mic button
    }
  }
  setupCellForRegSearchConnected() {
    let patient: Patient = <Patient>this.reusableObject;
    if (patient) {
      this.patientName = patient.name;
      this.patientShortBio = patient.getShortBio();
      this.scheduleVisitAction = true;
      this.goToProfileAction = true;
      this.voiceAssistantInvokeAction = false; // hide the mic button
    }
  }
  setupCellForRegSearchNotConnected() {
    let patient: Patient = <Patient>this.reusableObject;
    if (patient) {
      this.patientName = patient.name;
      this.patientShortBio = patient.getShortBio();
      this.connectAction = true;
      this.voiceAssistantInvokeAction = false; // hide the mic button
    }
  }
  setupCellForQueue() {
    let queueObject: Appointment = <Appointment>this.reusableObject;
    if (queueObject) {
      this.patientName = queueObject.patientName;
      this.patientShortBio = queueObject.sex_age;
      // this.cellLine3 = queueObject.timeslot;
      if (this.isQueueCompleted()) {
        this.finishPatientEncounterAction = false;
        this.recordVitalsAction = false;
      } else {
        this.finishPatientEncounterAction = true;
        this.recordVitalsAction = true;
        this.cancelEncounterAction = true;
      }
    }
  }

  setupCellForAppointment() {
    let apppointmentObject: Appointment = <Appointment>this.reusableObject;
    if (apppointmentObject) {
      this.patientName = apppointmentObject.patientName;
      this.patientShortBio = apppointmentObject.sex_age;
      this.cellLine3 = apppointmentObject.timeslot;
      switch (apppointmentObject.status) {
        case AppointmentStatus.APPOINTMENT_CONFIRMED: {
          this.addToQueueAction = true;
          this.finishPatientEncounterAction = false;
          this.recordVitalsAction = false;
          this.cancelEncounterAction = true;
          this.rescheduleEncounterAction = true;
          break;
        }
        case AppointmentStatus.APPOINTMENT_ARRIVED: {
          this.addToQueueAction = false;
          this.finishPatientEncounterAction = true;
          this.recordVitalsAction = true;
          this.cancelEncounterAction = true;
          this.rescheduleEncounterAction = true;
          break;
        }
        case AppointmentStatus.APPOINTMENT_COMPLETED:
        case AppointmentStatus.APPOINTMENT_CANCELLED:
        default: {
          this.recordVitalsAction = false;
          this.addToQueueAction = false;
          this.finishPatientEncounterAction = false;
          this.cancelEncounterAction = false;
          this.rescheduleEncounterAction = false;
          break;
        }
      }
    }
  }

  setupCellForDocQueueSearch() {
    let userObject: Patient = <Patient>this.reusableObject;
    if (userObject) {
      this.patientName = userObject.name;
      this.patientShortBio = userObject.sex_age;
      this.voiceAssistantInvokeAction = false;
    }
  }

  processRequestToRecordVitals() {
    this.requestToRecordVitals.emit(true);
  }

  finishPatientEncounter() {
    let queueObject: Appointment = <Appointment>this.reusableObject;
    if (queueObject) {
      this.loaderDisplay = true;
      this.appointmentOperationsService.complete(queueObject).then(response => {
        let clonedResponse: any = JSON.parse(JSON.stringify(response));
        this.loaderDisplay = false;
        // console.log(response);
        if (clonedResponse.body.success) {
          this.patientEncounterFinished.emit(true);
          this.notifyService.showSuccessMessage("Patient removed from queue!");
        }
      });
    }
    // Code to finish patient encounter will come here
  }

  isQueueCompleted(): boolean {
    let appointmentObject: Appointment = <Appointment>this.reusableObject;
    if (appointmentObject) {
      return (
        appointmentObject.status == AppointmentStatus.APPOINTMENT_COMPLETED
      );
    } else {
      return false;
    }
  }

  isRegSearchNotConnected(): boolean {
    return this.cellType == "regSearchNotConnected";
  }

  isRegSearchConnected(): boolean {
    return this.cellType == "regSearchConnected";
  }

  isDocQueueSearchType(): boolean {
    return this.cellType == "docQueueSearch";
  }

  isAppointmentType(): boolean {
    return this.cellType == "appointment";
  }
  isQueueType(): boolean {
    return this.cellType == "queue";
  }
  isPatientType(): boolean {
    return this.cellType == "patient";
  }
  isPatientList(): boolean {
    return this.cellType == "patientList";
  }

  activateVoiceAssistant() {
    // Depending on the type of cell, set the context. For example
    // If this is a patient, set that as current patient for any actions
    // If this is an appointment, set the current patient, & current appointment for any actions
    // If this is in queue, determine possible actions, etc.
    // Ok this is getting long now. Usually I would document this. But let's go with the unusual for now.
    this._voiceService.startWithState(VoiceAssistantStates.toggle); //Will add the context storing according to the use case later
    if (this.reusableObject instanceof Patient) {
      this._voiceService.setCurrentPatient(this.reusableObject);
    } else if (this.reusableObject instanceof Appointment) {
      this._patientProfileService
        .fetch(this.reusableObject.patientId)
        .then(response => {
          let clonedResponse: any = JSON.parse(JSON.stringify(response));
          if (clonedResponse.body.success) {
            let patient = new Patient();
            patient.initFromJSON(clonedResponse.body.user_profile);
            this._voiceService.setCurrentPatient(patient);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
    // this.notifyService.showWarningMessage("Juicy Bhai, Activate Mic Here");
  }
}
